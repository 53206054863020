<template>
  <div class="material">
    <!-- <h3 class="mb-2">물질명 INDEX 마스터</h3> -->
    <v-form ref="meterialIndex" lazy-validation>
      <div class="filter-box filter-box--row">
        <ul class="filter-list_free">
          <li>
            <h4>자재코드</h4>
            <v-text-field outlined dense placeholder="" v-model="param.code" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>원료약어</h4>
            <v-text-field outlined dense placeholder="" v-model="param.rmav" @keyup.enter="search"></v-text-field>
          </li>
          <li style="width: 400px">
            <h4>화학물질명</h4>
            <v-text-field outlined dense placeholder="" v-model="param.chemical_name" @keyup.enter="search"></v-text-field>
          </li>
          <li style="width: 400px">
            <h4>관용명 및 이명</h4>
            <v-text-field outlined dense placeholder="" v-model="param.common_name" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>CAS NO</h4>
            <v-text-field outlined dense placeholder="" v-model="param.cas_no" @keyup.enter="search"></v-text-field>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- <div class="meterialIndex wrapperSpace px-2 sch pt-4">
        <v-col cols="12" sm="2">
          <h4>자재코드</h4>
          <v-text-field
            outlined
            dense
            placeholder="자재코드를 입력해 주세요."
            v-model="param.code"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <h4>원료약어</h4>
          <v-text-field
            outlined
            dense
            placeholder="원료약어를 입력해 주세요."
            v-model="param.rmav"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <h4>화학물질명</h4>
          <v-text-field
            outlined
            dense
            placeholder="화학물질명을 입력해 주세요."
            v-model="param.chemical_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <h4>CAS NO</h4>
          <v-text-field
            outlined
            dense
            placeholder="CAS NO를 입력해 주세요."
            v-model="param.cas_no"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <h4>관용명 및 이명</h4>
          <div class="wrapperTop">
            <v-text-field
              outlined
              dense
              placeholder="관용어 및 이명을 입력해 주세요."
              v-model="param.common_name"
            ></v-text-field>
            <v-card-actions>
              <v-btn depressed outlined @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed color="primary" @click="search">검색</v-btn>
            </v-card-actions>
          </div>
        </v-col>
      </div> -->
    </v-form>
    <!-- <h3 class="mt-16 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">
        목록
        <v-btn depressed color="primary" @click="addIndex">등록</v-btn>
      </div>
    </h3>
    <hr class="mb-4" /> -->
    <h3 class="mt-2 mb-2 dp--row">
      <!-- 목록 -->
      <v-btn style="margin-left: auto" depressed class="btn-add" @click="addIndex">등록</v-btn>
    </h3>
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="search" @dbClick="dbClick" />
  </div>
</template>
<script>
import { columns, fields } from '@/assets/grid/materialIndex';
import RealGrid from '@/components/RealGrid.vue';
import { mapState } from 'vuex';
import { subsMasterList } from 'api/subIndex/subIndex';
import _ from 'lodash';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'materialIndex',
      settings: {
        columns,
        fields,
        height: 666,
        errorMessage: '목록을 검색해주세요',
        fixCol: 1,
      },
      param: {
        code: '',
        rmav: '',
        chemical_name: '',
        cas_no: '',
        common_name: '',
        pageSize: 100000,
      },
      currentPage: 1,
    };
  },
  // watch: {
  //   param: {
  //     deep: true,
  //     handler: function () {
  //       this.currentPage = 1;
  //     },
  //   },
  // },
  computed: {
    ...mapState('common', ['code']),
  },
  mounted() {
    this.loadData();
  },
  methods: {
    newSample() {
      this.$emit('newSample');
    },
    loadData(v) {
      this.search(v);
    },
    async search(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await subsMasterList({ ...this.param, currentPage: this.currentPage });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.items = items;
        this.$refs.grid.loadData(items);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    reset() {
      this.param = {
        code: '',
        rmav: '',
        chemical_name: '',
        cas_no: '',
        common_name: '',
        pageSize: 100000,
      };
    },
    addIndex() {
      this.$emit('materialIndexAdd');
    },
    dbClick(data) {
      this.$emit('dbClick', data);
    },
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss">
.meterialIndex {
  .v-input__slot {
    width: 100% !important;
  }
}
</style>
