<template>
  <div class="address partnerMasterDetail partner-master-detail">
    <SetPopup ref="confirm" />
    <Address ref="address" @select="onAddress" p:userId="userId" />
    <SetDialog ref="postModal">
      <PTAddressPost :data="editAddressData" @closeModal="this.closePost" @approvePost="this.approvePost" :key="signupPostKey" ref="signupPost"></PTAddressPost>
    </SetDialog>

    <template>
      <v-form lazy-validation ref="partnerMasterDetail">
        <div class="filter-box--row mt-6" style="padding-left: 30px; padding-right: 0px; height: 100%; gap: 0px">
          <div v-if="!partnerMasterEdit" class="filter-box" :style="'text-align: center; border: 1px solid #ddd; padding: 10px 30px; width: 280px;height: ' + calHeight() + 'px'">
            <h3 style="text-align: center; margin-top: 20px; display: flex; justify-content: center; align-items: center">
              <span style="font-weight: bold; font-size: 20px">{{ param.partner.pt_nm }}</span>
              <svg @click="partnerMasterEditing" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#07a60a" d="M3.548 20.938h16.9a.5.5 0 0 0 0-1h-16.9a.5.5 0 0 0 0 1M9.71 17.18a2.6 2.6 0 0 0 1.12-.65l9.54-9.54a1.75 1.75 0 0 0 0-2.47l-.94-.93a1.79 1.79 0 0 0-2.47 0l-9.54 9.53a2.5 2.5 0 0 0-.64 1.12L6.04 17a.74.74 0 0 0 .19.72a.77.77 0 0 0 .53.22Zm.41-1.36a1.47 1.47 0 0 1-.67.39l-.97.26l-1-1l.26-.97a1.5 1.5 0 0 1 .39-.67l.38-.37l1.99 1.99Zm1.09-1.08l-1.99-1.99l6.73-6.73l1.99 1.99Zm8.45-8.45L18.65 7.3l-1.99-1.99l1.01-1.02a.75.75 0 0 1 1.06 0l.93.94a.754.754 0 0 1 0 1.06" /></svg>
            </h3>
            <p style="color: #a1a1a1">{{ param.partner.pt_cd }}</p>
            <hr style="border: 1px solid #efefef" />
            <div style="text-align: left; padding: 20px">
              <h4 style="margin: 0px; text-align: left; margin-top: 50px; color: rgb(102 102 102)">대표 주소지</h4>
              <p class="p-class">{{ param.partner.postcode }}</p>
              <p class="p-class">{{ param.partner.address1 }}</p>
              <p class="p-class">{{ param.partner.address2 }}</p>
              <h4 style="margin: 0px; text-align: left; margin-top: 50px; color: rgb(102 102 102)">고객사 담당자</h4>
              <p class="p-class">{{ param.partner.rep_name }}</p>
              <h4 style="margin: 0px; text-align: left; margin-top: 50px; color: rgb(102 102 102)">대표 전화번호</h4>
              <p class="p-class">{{ param.partner.phone }}</p>
              <h4 style="margin: 0px; text-align: left; margin-top: 50px; color: rgb(102 102 102)">E-MAIL</h4>
              <p class="p-class" style="text-transform: uppercase">{{ param.partner.email }}</p>
            </div>
            <div class="input-with-icon" style="width: 100%; padding-top: 60px">
              <div class="wrapper-space-radiogroup" style="width: 100%; display: flex; justify-content: space-between">
                <div class="custom-radio-label" :class="{ active: param.partner.open_yn == 'Y' }" style="background-color: #fff; color: #4caf50; border-color: #4caf50; padding: 5px 20px">운영</div>
                <div class="custom-radio-label" :class="{ active: param.partner.open_yn == 'N' }" style="padding: 5px 20px; color: #ccc">미운영</div>
              </div>
            </div>
          </div>
          <div v-if="partnerMasterEdit" class="filter-box" :style="'text-align: center; border: 1px solid #ddd; padding: 10px 10px; width: 280px; height: height: ' + calHeight() + 'px'">
            <div style="text-align: right">
              <svg @click="partnerMasterEditing" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="#d0d0d0" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
            </div>
            <h3 style="text-align: center; margin-top: 20px">
              <span>{{ param.partner.pt_nm }}</span>
            </h3>
            <p style="color: #a1a1a1">{{ param.partner.pt_cd }}</p>
            <hr style="border: 1px solid #efefef" />
            <h4 style="margin: 30px 10px 0px 20px; text-align: left; color: rgb(102 102 102)">대표주소</h4>
            <ul class="filter-list_address" style="width: 100%; padding: 20px; margin: 0px !important">
              <li class="__dp--collums">
                <!-- <h4>우편번호</h4> -->
                <div class="input-with-icon" style="display: flex; align-items: flex-start">
                  <v-text-field style="width: 90px; margin-right: 5px" ref="postcode" type="text" outlined dense disabled filled v-model="param.partner.postcode" :rules="[this.validSet.empty(param.partner.postcode, '주소를 검색 입력해주세요')]" placeholder="우편번호" />
                  <v-btn style="width: 90px; border: 1px solid #4caf50 !important; color: #4caf50 !important; border-radius: 8px !important" depressed class="ml-1 btn-address-search" @click="searchAddress">주소검색</v-btn>
                  <svg v-show="address1Valid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="address1Valid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="address1Valid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li class="__dp--collums" style="">
                <!-- <h4>대표주소</h4> -->
                <!-- <div class="dp--row" style="width: 100%; align-items: flex-start"> -->
                <div class="input-with-icon">
                  <v-text-field ref="address1" type="text" outlined dense disabled filled v-model="param.partner.address1" :rules="[this.validSet.empty(param.partner.address1, '대표주소를 입력해주세요')]" placeholder="대표주소" />
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
              <li>
                <!-- <h4 class="">상세주소</h4> -->
                <div class="input-with-icon">
                  <v-text-field ref="address2" placeholder="상세주소" type="text" outlined dense v-model="param.partner.address2" />
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
              <li>
                <!-- <h4>고객사 담당자</h4> -->
                <h4 style="margin: 50px 10px 0px 0px; text-align: left; color: rgb(102 102 102)">고객사 담당자</h4>
                <div class="input-with-icon">
                  <v-text-field ref="rep_name" outlined dense v-model="param.partner.rep_name" placeholder="고객사 담당자" :rules="[this.validSet.empty(param.partner.rep_name, '고객사 담당자를 입력해 주세요.')]" @enter="validateRepNameValid" @blur="validateRepNameValid" @input="validateRepNameValid" />
                  <svg v-show="repNameValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="repNameValid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="repNameValid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li>
                <h4 style="margin: 50px 10px 0px 0px; text-align: left; color: rgb(102 102 102)">대표 전화번호</h4>
                <div class="input-with-icon">
                  <!-- <h4>대표 전화번호</h4> -->
                  <v-text-field ref="phone" outlined dense v-model="param.partner.phone" placeholder="대표 전화번호(- 를 포함 입력)" :rules="[this.validSet.phoneRules(true, param.partner.phone)]" minlength="11" maxlength="13" @enter="validatePhoneValid" @blur="validatePhoneValid" @input="validatePhoneValid" />
                  <svg v-show="phoneValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="phoneValid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="phoneValid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li>
                <!-- <h4>E-mail</h4> -->
                <h4 style="margin: 50px 10px 0px 0px; text-align: left; color: rgb(102 102 102)">E-MAIL</h4>
                <div class="input-with-icon">
                  <v-text-field ref="email" outlined dense v-model="param.partner.email" :rules="[this.validSet.email]" placeholder="E-mail"></v-text-field>
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
              <li></li>
            </ul>
            <div class="input-with-icon" style="width: 100%">
              <v-radio-group v-model="param.partner.open_yn" :rules="[this.validSet.emptyRadio(param.partner.open_yn, '운영여부를 선택해주세요.')]">
                <div class="wrapper-space-radiogroup">
                  <div class="wrapper" style="width: 48%">
                    <v-radio key="openyn_1" :value="'Y'" class="custom-radio">
                      <template v-slot:label>
                        <div class="custom-radio-label" :class="{ active: param.partner.open_yn == 'Y' }">운영</div>
                      </template>
                    </v-radio>
                  </div>
                  <div class="wrapper" style="width: 48%">
                    <v-radio key="openyn_2" :value="'N'" class="custom-radio">
                      <template v-slot:label>
                        <div class="custom-radio-label" :class="{ active: param.partner.open_yn == 'N' }">미운영</div>
                      </template>
                    </v-radio>
                  </div>
                </div>
              </v-radio-group>
            </div>
          </div>
          <div class="filter-box" :style="'position:relative; border: 1px solid #fff; padding: 0px;display: flex;align-items: center;flex-direction: column;background-color: rgb(250 255 251); height: 100%; flex: 1'">
            <ul class="filter-list_address" style="grid-template-columns: none; place-content: unset; margin: 30px 20px 20px 20px">
              <li class="dp--collums">
                <h4 style="display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 6px 0px; color: #767676; font-size: 1.2em">
                  배송지 관리
                  <div>
                    <v-btn depressed class="mr-1 btn-load" id="_one_speed_add" style="height: 22px; font-size: 0.8em !important; border-radius: 5px !important; padding: 3px 3px; color: #08800a !important; border: 1px solid #479748 !important; background: #fff !important" @click="filterAction"><span class="text" style="padding-right: 0px"></span> 필터</v-btn>
                    <v-btn depressed class="mr-1 btn-load" id="_one_speed_add" style="height: 22px; font-size: 0.8em !important; border-radius: 5px !important; padding: 3px 8px; color: #08800a !important; border: 1px solid #479748 !important; background: #fff !important" @click="defaultPost"><span class="text" style="padding-right: 0px"></span> 기본배송지 지정/해제</v-btn>
                    <v-btn depressed class="mr-1 btn-load" id="_one_speed_add" style="height: 22px; font-size: 0.8em !important; border-radius: 5px !important; padding: 3px 8px; border: 1px solid #479748 !important; background: #479748 !important" @click="openPost"><span class="text" style="padding-right: 5px; font-size: 16px">+</span> 배송지추가</v-btn>
                  </div>
                </h4>
                <!-- <RealGrid :domName="grid" ref="grid" :settings="settings" @notSelectiionNotice="notSelectiionNotice" @selectionRow="selectionRow" @removeData="removeData" @openAddressModal="handleOpenAddressModal" @onAddressCopyEdit="onAddressCopyEdit" :nonePage="true" /> -->
                <RealGrid :domName="grid" ref="grid" :settings="settings" @btnClick="onBtnClickEvent" @notSelectiionNotice="notSelectiionNotice" @selectionRow="selectionRow" @removeData="removeData" :nonePage="true" />
                <div v-show="true" style="display: flex; justify-content: center; width: 100%; border-bottom: 1px solid #d0d0d0; border-right: 1px solid #d0d0d0; border-left: 1px solid #d0d0d0; height: 25px; position: relative; background-color: #fff">
                  <svg @click="toggleArrowAddress" style="position: absolute; top: -5px" :style="rotationStyle" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="#545454" d="M15.88 9.29L12 13.17L8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0" /></svg>
                </div>
              </li>
              <li class="dp--collums" style="margin-top: 40px">
                <h4 style="padding: 6px 0px; color: #767676; font-size: 1.2em">회원</h4>
                <RealGrid :domName="gridMamber" ref="gridMamber" :settings="settings2" :nonePage="true" />
                <div v-show="true" style="display: flex; justify-content: center; width: 100%; border-bottom: 1px solid #d0d0d0; border-right: 1px solid #d0d0d0; border-left: 1px solid #d0d0d0; height: 25px; position: relative; background-color: #fff">
                  <svg @click="toggleArrowMembers" style="position: absolute; top: -5px" :style="rotationStyle2" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="#545454" d="M15.88 9.29L12 13.17L8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0" /></svg>
                </div>
              </li>
              <li style="margin-top: 40px">
                <h4 style="padding: 6px 0px; color: #767676; font-size: 1.2em">비고</h4>
                <v-textarea outlined rows="6" row-height="40" style="" class="filter-textarea textarea-clean-border" dense placeholder="" v-model="param.partner.note"></v-textarea>
              </li>
            </ul>
            <div class="wrapper mb-2" style="width: 100%; right: 50px">
              <div class="btn-box">
                <v-card-actions v-if="tester">
                  <v-btn depressed @click="testData" class="btn-cancle">테스트 정보 자동입력</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed @click="cancle" class="btn-cancle">취소</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed class="btn-sample-request" @click="request_promise">저장</v-btn>
                </v-card-actions>
              </div>
            </div>
          </div>
        </div>
      </v-form>
    </template>
  </div>
</template>

<script>
import SetPopup from '@/components/SetPopup.vue';
import { mapMutations } from 'vuex';
import * as partnerAddress from '@/assets/grid/partnerMasterAddress';
import * as partnerMembers from '@/assets/grid/partnerMembers';
import Address from '@/components/Address.vue';
import SetDialog from '@/components/SetDialog';
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import { getPartnerMaster, updatePartnerMaster } from 'api/partner/partner';
import { insertAddressPT, updateAddressPT, deleteAddressPT } from 'api/partnerAddress/partnerAddressPT';
import _ from 'lodash';
import moment from 'moment';
import PTAddressPost from '@/components/PTAddressPost.vue';
// import { EventBus } from '../../../plugins/eventBus';
export default {
  props: ['data'],
  data() {
    return {
      addressArrowVisible: false,
      membersArrowVisible: false,
      toggleStateAddress: false,
      toggleStateMembers: false,
      rotationDegrees: 0,
      rotationDegrees2: 0,
      partnerMasterEdit: false,
      signupPostKey: 0,
      isFetching: false,
      isDataLoaded: false,
      isSelecting: false,
      grid: 'partnerAddressList',
      gridMamber: 'partnerMemberList',
      selectedRowData: {},
      editAddressData: {
        address1: '',
        address2: '',
        defaultYn: '0',
        fullAddress: '',
        idx: '',
        mobile: '',
        name: '',
        pickname: '',
        postcode: '',
        pt_cd: '',
        remove: '',
        rowIndex: '',
        editabled: '',
      },
      filteredData: [], // 필터링된 데이터를 저장할 배열
      isFiltered: false, // 필터가 적용된 상태를 추적할 불린 값
      settings: {
        ...partnerAddress,
        columns: _.map(
          _.filter(_.cloneDeep(partnerAddress.columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            if (v.fieldName == 'remove') {
              v.width = '60';
            }
            return v;
          },
        ),
        height: 140,
        radio: false, // true -> radio, false -> checkbox
        firstClick: false, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
        fitStyle: 'fill',
        hideCheckBar: true,
        noneNo: true,
        blankGrid: true,
        filterAction: false,
      },
      settings2: {
        ...partnerMembers,
        columns: _.map(_.cloneDeep(partnerMembers.columns), function (v) {
          if (v.key != 'idx') {
            // v.editable = true;
          }
          return v;
        }),
        height: 140,
        radio: false, // true -> radio, false -> checkbox
        firstClick: false, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
        fitStyle: 'fill',
        hideCheckBar: true,
        noneNo: true,
        blankGrid: true,
        filterAction: false,
      },
      validSet,
      checkRows: false,
      param: {
        partner: {
          pt_cd: '',
          pt_nm: '',
          rep_name: '',
          open_yn: 'Y',
          postcode: '',
          address1: '',
          address2: '',
          phone: '',
          email: '',
          note: '',
        },
        posts: [],
        members: [],
      },
      postItems: [],
      removePostItems: [],
      address: [
        {
          key: 'default',
          text: '기본배송지',
        },
        {
          key: 'other',
          text: '다른 배송지',
        },
      ],
      tmpResult: [],
      picker: false,
      tester: false,
      companyNameValid: null,
      address1Valid: null,
      repNameValid: null,
      phoneValid: null,
      visibleValid: null,
    };
  },
  // created() {
  // props로 전달받은 data를 사용하여 param을 초기화
  // this.param.open_yn = Number(this.data.open_yn);
  // },
  created() {
    // EventBus.$on('openAddressModal', this.handleOpenAddressModal);
    // window.addEventListener('edit-click', this.onAddressEditClick);
    // window.addEventListener('copy-click', this.onAddressCopyClick);
  },
  beforeDestroy() {
    // EventBus.$off('openAddressModal', this.handleOpenAddressModal);
    // window.removeEventListener('edit-click', this.onAddressEditClick);
    // window.removeEventListener('copy-click', this.onAddressCopyClick);
  },
  watch: {
    data: {
      immediate: true,
      handler(oldRow, newRow) {
        this.partnerMasterEdit = false; // 선택된 항목이 변경될 때 편집 상태 초기화
        this.toggleStateAddress = false;
        this.toggleStateMembers = false;
        this.rotationDegrees = 0;
        this.rotationDegrees2 = 0;
        this.signupPostKey = 0;
        this.isFetching = false;
        this.isDataLoaded = false;
        this.isSelecting = false;
        this.settings.height = 140;
        this.settings2.height = 140;
      },
    },
  },
  computed: {
    rotationStyle() {
      return {
        transition: 'transform 0.3s ease',
        transform: `rotate(${this.rotationDegrees}deg)`,
      };
    },
    rotationStyle2() {
      return {
        transition: 'transform 0.3s ease',
        transform: `rotate(${this.rotationDegrees2}deg)`,
      };
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('menu', ['SET_MENU', 'ADD_MENU_TAB', 'ADD_MENU_TAB_BY_CODE', 'REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('modal', ['SET_USER_INFO', 'SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    validateCompanyName() {
      this.companyNameValid = this.validSet.empty(this.param.partner.pt_nm) ? true : false;
    },
    validateAddress1Valid() {
      this.address1Valid = this.validSet.empty(this.param.partner.address1) ? true : false;
    },
    validateRepNameValid() {
      this.repNameValid = this.validSet.empty(this.param.partner.rep_name) ? true : false;
    },
    validatePhoneValid() {
      let phValid = this.validSet.phoneRules(true, this.param.partner.phone, '');
      this.phoneValid = phValid === true ? true : false;
    },
    partnerMasterEditing() {
      this.partnerMasterEdit = !this.partnerMasterEdit;
    },
    calHeight() {
      return window.innerHeight - 165;
    },
    onBtnClickEvent(v) {
      this.editAddressData = { ...this.editAddressData, ...v };
      // if (this.addressEditType) {
      //   this.editAddressData.edit = this.addressEditType;
      // } else {
      //   this.editAddressData.copy = true;
      // }
      // EventBus.$emit('openAddressModal', this.editAddressData);
      // this.handleOpenAddressModal(this.editAddressData);
      // this.editAddressData.type = payload.type;
      this.signupPostKey = Date.now();
      this.openPost();
    },
    // onAddressEditClick(event) {
    //   const rowIndex = event.detail;

    //   if (rowIndex !== null) {
    //     this.addressEditType = true;
    //     // const selectedRow = { ...this.$refs.grid.getJsonRowByIdx(rowIndex), rowIndex: rowIndex };
    //     // const selectedRow = { ...this.getJsonRowByIdx(rowIndex), rowIndex: rowIndex, edit: true };
    //     // EventBus.$emit('openAddressModal', { type: 'edit', data: selectedRow });
    //   }
    // },
    // onAddressCopyClick(event) {
    //   const rowIndex = event.detail;

    //   if (rowIndex !== null) {
    //     this.addressEditType = false;
    //     // const selectedRow = { ...this.$refs.grid.getJsonRowByIdx(rowIndex), rowIndex: rowIndex, idx: '', defaultYn: '0' };
    //     // const selectedRow = { ...this.getJsonRowByIdx(rowIndex), rowIndex: rowIndex, idx: '', defaultYn: '0', copy: true };
    //     // const newRow = { ...selectedRow, idx: '', defaultYn: '0' };
    //     // EventBus.$emit('openAddressModal', { type: 'copy', data: selectedRow });
    //   }
    // },
    // handleOpenAddressModal(payload) {
    //   this.editAddressData = payload;
    //   // this.editAddressData.type = payload.type;
    //   this.signupPostKey = Date.now();
    //   this.openPost();
    //   // this.SET_MODAL({
    //   //   width: 650,
    //   //   closable: true,
    //   //   customApprove: true,
    //   // });
    //   // this.$refs.postModal.openModal();
    // },

    filterAction() {
      this.settings.filterAction = !this.settings.filterAction;
      this.$refs.grid.setFilterPanel(this.settings.filterAction);

      // 필터 패널에서 특정 필터 항목 숨기기
      setTimeout(() => {
        const filterPanelRows = document.querySelectorAll('.rg-filter-panel-row');
        filterPanelRows.forEach(row => {
          const filterCellsToHide = [0, 6, 7];
          filterCellsToHide.forEach(index => {
            const filterCell = row.querySelectorAll('td .rg-filter-panel-renderer')[index];
            if (filterCell) {
              filterCell.classList.add('hidden-filter');
            }
          });
        });
      }, 200);

      // 필터링된 데이터를 저장하고 상태 업데이트
      // if (this.settings.filterAction) {
      //   const filteredData = this.$refs.grid.getJsonRows();
      //   this.handleFilterChanged(filteredData);
      // } else {
      //   this.filteredData = [];
      //   this.isFiltered = false;
      // }
    },
    // handleFilterChanged(filteredData) {
    //   this.filteredData = filteredData;
    //   this.isFiltered = filteredData.length > 0;
    // },
    getJsonRowByIdx(idx) {
      if (this.isFiltered) {
        return this.filteredData[idx];
      }
      return this.param.posts[idx];
    },
    async loadData() {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const res = await getPartnerMaster(this.data.pt_cd);
        this.isDataLoaded = true;
        const body = res.data;
        if (_.isEmpty(body.errorCode)) {
          if (body.data) {
            this.param.partner = _.cloneDeep(body.data.partner);
            this.param.members = _.cloneDeep(body.data.members);
            this.param.posts = _.cloneDeep(body.data.posts);
            if (this.$refs.gridMamber) {
              this.$refs.gridMamber.loadData(this.param.members);
              if (this.param.members.length > 3) {
                this.membersArrowVisible = true;
              } else {
                this.membersArrowVisible = false;
              }
            }
            if (this.$refs.grid) {
              const updatedRows = this.param.posts.map(row => ({
                ...row,
                fullAddress: `${row.address1} ${row.address2}`.trim(),
              }));
              this.$refs.grid.loadData(updatedRows);
              // if (this.param.posts.length > 3) {
              //   this.addressArrowVisible = true;
              // } else {
              //   this.addressArrowVisible = false;
              // }
            }
          } else {
            this.openPopup('고객마스터를 위한 채번을 하지 못했습니다.');
          }
        }
      } catch (error) {
        console.error('Error fetching partner code: PartnerMasterDetail ', error);
      } finally {
        this.isFetching = false;
      }
    },
    async request_promise() {
      const validated = this.valid();
      if (!validated) {
        this.openPopup('필수 항목을 입력해 주세요');
        return false;
      }
      try {
        const data = {
          ...this.param,
        };

        const postList = this.$refs.grid.getJsonRows();
        updatePartnerMaster(data.partner)
          .then(res => {
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              this.openConfirm(body.errorMessage);
              return false;
            }
            if (postList.length > 0) {
              postList.forEach(post => {
                if (post.pt_cd == '') {
                  post.pt_cd = this.data.pt_cd;
                  insertAddressPT(post)
                    .then()
                    .catch(err => console.log(err));
                } else {
                  updateAddressPT(post)
                    .then()
                    .catch(err => console.log(err));
                }
              });
            }
            if (this.removePostItems.length > 0) {
              this.removePostItems.forEach(post => {
                deleteAddressPT(post.idx)
                  .then()
                  .catch(err => console.log(err));
              });
            }
            this.openPopup('정상 처리하였습니다', false, () => {
              this.REMOVE_SELECT_MENU('partnerDetail');
            });
          })
          .catch(err => {
            this.setModal(err);
          });
      } catch (error) {
        console.error('Error during request:', error);
      }
    },
    defaultPost() {
      this.$refs.grid.getSelection();
    },
    notSelectiionNotice() {
      this.openPopup('선택된 배송지가 없습니다.');
    },
    selectionRow(itemIndex, row) {
      let defaultStatus = row.defaultYn == '1' ? '기본배송지를 해제하시겠습니까?' : '기본배송지로 변경하시겠습니까?';
      this.openPopup(defaultStatus, true, () => {
        row.defaultYn = row.defaultYn == 1 ? 0 : 1;
        this.$refs.grid.setSelectionValue(itemIndex, row);
      });
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable: closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    closePopup() {
      this.$refs.confirm.closePopup();
    },
    cancle() {
      this.openPopup('취소 하시겠습니까?', true, () => {
        this.REMOVE_SELECT_MENU('partnerDetail');
        this.SET_SELECT_MENU_TAB_BY_CODE('PTMGMT');
      });
    },
    searchAddress() {
      const that = this;
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.partner.address1 = data.address;
          this.param.partner.address2 = data.address2;
          this.param.partner.postcode = data.zonecode;
        },
        onclose: function () {
          that.validateAddress1Valid();
        },
      }).open();
    },
    onAddress(data) {
      this.param.partner.postcode = data.zonecode;
      this.param.partner.address = data.address;
      this.param.partner.address2 = data.address2;
    },
    openPost() {
      this.SET_MODAL({
        // height: 600,
        width: 650,
        closable: true,
        customApprove: true,
      });
      this.$refs.postModal.openModal();
    },
    closeModal() {
      this.$router.push({ name: 'login' });
    },
    closePost() {
      this.$refs.postModal.closeModal();
      this.signupPostKey = moment().valueOf();
      setTimeout(() => {
        this.editAddressData = {
          address1: '',
          address2: '',
          defaultYn: '0',
          editabled: '',
          fullAddress: '',
          idx: '',
          mobile: '',
          name: '',
          pickname: '',
          postcode: '',
          pt_cd: '',
          remove: '',
          rowIndex: '',
        }; // 초기화
        // this.param.partner.address1 = '';
        // this.param.partner.address2 = '';
        // this.param.partner.postcode = '';
        // this.param.partner.name = '';
        // this.editableType = ''; // 여기에서 초기화
      }, 700);
    },
    removeData(data) {
      if (data) {
        this.removePostItems.push(data);
      }
    },
    approvePost(post) {
      if (post) {
        if (this.$refs.grid) {
          if (post.editabled != undefined && post.editabled) {
            this.$refs.grid.addRow(post);
          } else {
            const newRow = {
              defaultYn: 0,
              remove: '',
              idx: '', // add 상태에서는 필요 없는 필드.
              pt_cd: '',
              name: post.name || '',
              pickname: post.pickname || '',
              mobile: post.mobile || '',
              postcode: post.postcode || '',
              address1: post.address1 || '',
              address2: post.address2 || '',
            };
            this.postItems.push(newRow);
            this.$refs.grid.addRow(newRow);
          }
          this.postEditType = ''; // 여기에서 초기화
        } else {
          console.error('Grid or DataProvider is null');
        }
      }
    },
    // onAddressCopyEdit(type, row) {
    //   this.editAddressData.data = _.cloneDeep(row);
    //   this.editAddressData.type = type;
    //   this.postEditType = type; // postEditType 설정
    //   // this.param.partner.address1 = row.address1 || '';
    //   // this.param.partner.address2 = row.address2 || '';
    //   // this.param.partner.postcode = row.postcode || '';
    //   // this.param.partner.name = row.name || '';
    //   this.SET_MODAL({
    //     width: 650,
    //     closable: true,
    //     customApprove: true,
    //   });
    //   this.$refs.postModal.openModal();

    //   setTimeout(() => {}, 500);
    // },
    setModal(msg, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable: closable,
        text: msg,
      });
      this.$refs.confirm.openPopup(cb);
    },
    valid() {
      return this.$refs.partnerMasterDetail.validate();
    },
    reset() {
      this.param = {};
    },
    toggleArrowAddress() {
      this.rotationDegrees += 180; // 매 클릭마다 180도 추가
      this.toggleStateAddress = !this.toggleStateAddress;
      if (this.toggleStateAddress) {
        this.settings.height += 95;
      } else {
        this.settings.height -= 95;
      }
    },
    toggleArrowMembers() {
      this.rotationDegrees2 += 180; // 매 클릭마다 180도 추가
      this.toggleStateAddress2 = !this.toggleStateAddress2;
      if (this.toggleStateAddress2) {
        this.settings2.height += 95;
      } else {
        this.settings2.height -= 95;
      }
    },
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    // await this.loadData();
  },
  components: {
    Address,
    PTAddressPost,
    SetPopup,
    RealGrid,
    SetDialog,
  },
};
</script>
<style lang="scss">
.text-red {
  color: red;
  font-size: 16px;
}
.aLink {
  color: blue !important;
  text-decoration: underline;
}
.address.partner-master-detail {
  margin-top: -40px;

  .rg-header-focus,
  .rg-rowbarcontainer .rg-table tr td.rg-rowindicator-focus {
    background: #4c8b23 !important;
  }
  .rg-grid {
    border: 1px solid #d0d0d0 !important;
  }
  .rg-header {
    background: #6c6c6c !important;
    color: #fff !important;
  }
  .v-input__slot {
    width: 100%;
  }
  .row {
    height: 100px;
  }
  .v-text-field--outlined.v-input--is-focused fieldset {
    border: 2px solid rgb(4, 176, 39) !important;
  }
  .v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid rgb(255, 135, 135) !important;
  }
  .textarea-clean-border {
    background-color: #fff !important;
  }
  .textarea-clean-border.v-text-field--outlined.v-input--is-focused fieldset {
    border: 1px solid #fff !important;
  }
  .v-text-field--outlined fieldset {
    // border: 1px solid #18a143 !important;
  }
  .hidden-filter {
    display: none !important;
  }
}
.v-input--checkbox {
  .v-input__slot {
    top: -21px;
  }
}
.newPartner {
  .v-input--selection-controls__input {
    margin-right: 0px;
  }
  .v-input--radio-group {
    margin-top: 0px;
  }
}
.add-btn-filter-list {
  display: flex !important;
  width: 100% !important;
  li {
    position: relative;
    width: 100% !important;
  }
}
.justify-content-between {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
}
.add-btn {
  justify-content: space-between !important;
}
#one_speed_add {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  padding: 8px 20px !important;
  border-radius: 5px !important;
  border: 1px solid #01aa01 !important;
  background-color: #01aa01 !important;
  font-size: 1rem !important;
  span.text {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 5px;
  }
}
.text-filed-note {
  height: 150px;
}
.custom-radio {
  display: none;
}
.wrapper-space-radiogroup .wrapper {
  width: 48%;
  display: inline-block;
  margin-right: 3px;
}
.wrapper-space-radiogroup .wrapper .v-input--selection-controls__input {
  display: none !important;
}
.wrapper-space-radiogroup .custom-radio-label {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}
.wrapper-space-radiogroup .custom-radio-label.active {
  background-color: white;
  color: #4caf50;
  border-color: #4caf50;
}
.p-class {
  margin: 0px !important;
  padding: 0px !important;
  color: #929292 !important;
}
</style>
