<template>
  <div>
    <SetPopup ref="confirm" />
    <!-- <h3 class="mb-2">제조 기록지</h3> -->
    <v-form ref="form">
      <div class="filter-box filter-box--row">
        <ul class="filter-list">
          <li>
            <h4>Lot No</h4>
            <v-text-field outlined dense placeholder="" :rules="[this.validSet.commonCodeHipen]" v-model="param.lotNo" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>Code Grade</h4>
            <v-text-field outlined dense placeholder="" v-model="param.code" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청업체명</h4>
            <div class="wrapperTop">
              <v-text-field outlined dense placeholder="" v-model="param.request_company" :rules="[this.validSet.company]" @keyup.enter="search"></v-text-field>
            </div>
          </li>
          <li>
            <h4>요청자</h4>
            <div class="wrapperTop">
              <v-text-field outlined dense placeholder="" v-model="param.request_name" :rules="[this.validSet.name]" @keyup.enter="search"></v-text-field>
            </div>
          </li>
          <li>
            <h4>출하예정일</h4>
            <div class="dp--row">
              <v-menu v-model="picker1" :close-on-content-click="false" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="param.from_out_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="picker1 = false" v-model="param.from_out_due_date"></v-date-picker>
              </v-menu>
              <i class="ml-1 mr-1">~</i>
              <v-menu v-model="picker2" :close-on-content-click="false" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="param.to_out_due_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="picker2 = false" v-model="param.to_out_due_date"></v-date-picker>
              </v-menu>
            </div>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>

      <!-- <div class="wrapperSpace">
        <div style="width: 100%">
          <v-row class="px-5 sch pt-3">
            <v-col cols="12" sm="4">
              <h4>Code Grade</h4>
              <v-text-field
                outlined
                dense
                placeholder="Code Grade를 입력해주세요"
                v-model="param.code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <h4>Lot No</h4>
              <v-text-field
                outlined
                dense
                placeholder="Lot No를 입력해주세요"
                :rules="[this.validSet.commonCodeHipen]"
                v-model="param.lotNo"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <h4>요청자</h4>
              <div class="wrapperTop">
                <v-text-field
                  outlined
                  dense
                  placeholder="요청자를 입력해주세요"
                  :rules="[this.validSet.name]"
                  v-model="param.request_name"
                ></v-text-field>
                <v-card-actions>
                  <v-btn depressed outlined @click="reset">초기화</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed color="primary" @click="search">검색</v-btn>
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </div>
      </div> -->
    </v-form>
    <h3 class="mt-2 mb-2 dp--row">
      <!-- 목록 -->
      <v-btn depressed class="btn-save" @click="excelDownload" style="width: 155px; margin-left: auto">제조기록지 다운로드</v-btn>
    </h3>
    <!-- <div class="wrapperSpace pr-2 mt-16 mb-2">
      <h4>목록</h4>
      <v-btn
        depressed
        color="primary"
        @click="excelDownload"
        style="width: 155px"
        >제조기록지 다운로드</v-btn
      >
    </div> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" />
  </div>
</template>
<script>
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import SetPopup from '@/components/SetPopup.vue';
import _ from 'lodash';
import { produceReportlist, produceReportUpdate, produceReportDetail } from 'api/sample/sample';
import { columns, fields, rows } from '@/assets/grid/report';
import { getReportFile } from 'api/file';
import { mapMutations } from 'vuex';
import { getNum } from '@/assets/grid/gridUtill';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false, // 데이터 로드 완료 상태 추가
      id: '',
      picker1: false,
      picker2: false,
      validSet,
      grid: 'report',
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        radio: true,
      },
      param: {
        lotNo: '',
        code: '',
        qty: '',
        out_due_date: '',
        request_company: '',
        request_name: '',
        code_title: '',
        delivery_type: '',
        analysis: '',
        delivery_etc: '',
        produce_etc: '',
        request_etc: '',
        detail: [],
        packing: '',
        request_code: '',
        sum_qty: '',
        sum_vol: '',
        sum_vol2: '',
        total: '',
        pageSize: 100000,
        from_out_due_date: '',
        to_out_due_date: '',
      },
      input: {
        lotNo: '',
        code: '',
        qty: '',
        out_due_date: '',
        request_company: '',
        request_name: '',
        code_title: '',
        delivery_type: '',
        analysis: '',
        delivery_etc: '',
        produce_etc: '',
        request_etc: '',
        detail: [],
        packing: '',
        request_code: '',
        sum_qty: '',
        sum_vol: '',
        sum_vol2: '',
        total: '',
        pageSize: 100000,
        from_out_due_date: '',
        to_out_due_date: '',
      },
      items: [],
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_USER_INFO']),
    valid() {
      return this.$refs.form.validate();
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        approveName: closable ? '초기화' : '확인',
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    reset() {
      this.param = {
        lotNo: '',
        request_name: '',
        pageSize: 100000,
        code: '',
        from_out_due_date: '',
        to_out_due_date: '',
        request_company: '',
      };
    },
    loadData() {
      this.search();
    },
    async search(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await produceReportlist({
          currentPage: this.currentPage,
          ...this.param,
        });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items || [];
        this.items = items;
        const page = response.data.params;
        let filteredItems = items;

        // if (this.param.from_out_due_date) {
        //   const fromDate = new Date(this.param.from_out_due_date);
        //   filteredItems = filteredItems.filter(item => new Date(item.out_due_date) >= fromDate);
        // }
        // if (this.param.to_out_due_date) {
        //   const toDate = new Date(this.param.to_out_due_date);
        //   filteredItems = filteredItems.filter(item => new Date(item.out_due_date) <= toDate);
        // }
        // if (this.param.request_company) {
        // const request_company = this.param.request_company;
        //   filteredItems = filteredItems.filter(item => {
        //     if (item.request_company === undefined) {
        //       return false;
        //     }
        //     return item.request_company.includes(request_company);
        //   });
        // }
        this.$refs.grid.loadData(filteredItems, ['delivery_date', 'delivery_due_date', 'request_date', 'produce_due_date', 'produce_date', 'out_date', 'out_due_date']);
        this.$refs.grid.setPage(page);
        if (items.length === 0) {
          this.settings.errorMessage = '진행중인 사항이 없습니다';
        }
      } catch (err) {
        this.error = err.message;
        this.openPopup('오류발생 : 관리자에게 문의하세요');
      } finally {
        this.isFetching = false;
      }
    },
    excelDownload() {
      const idx = this.$refs.grid.getCheckedRowIdxRadio();
      if (idx !== undefined && !isNaN(idx)) {
        const row = this.items[idx];
        if (row.lotNo) {
          this.input.lotNo = row.lotNo;
          this.input.code = row.code;
          this.input.qty = row.qty;
          this.input.out_due_date = row.out_due_date;
          this.input.request_company = row.request_company;
          this.input.request_name = row.request_name;
          produceReportDetail(this.input)
            .then(res => {
              const response = res.data.data;
              if (response) {
                this.input.code_title = response.code_title;
                this.input.detail = response.items;
                this.input.delivery_type = response.delivery_type;
                this.input.request_code = response.request_code;
                this.input.packing = response.packing;
                this.input.analysis = response.analysis ? response.analysis : '특이사항 없음';
                this.input.delivery_etc = response.delivery_etc ? response.delivery_etc : '특이사항 없음';
                this.input.produce_etc = response.produce_etc ? response.produce_etc : '특이사항 없음';
                this.input.request_etc = response.request_etc ? response.request_etc : '특이사항 없음';
                this.input.sum_qty = response.sum_qty;
                this.input.sum_vol = response.sum_vol;
                this.input.sum_vol2 = response.sum_vol2;
                response.items.push({
                  spec: 'SUM',
                  vol: response.sum_vol,
                  vol2: response.sum_vol2,
                  qty: response.sum_qty,
                });
                _.each(response.items, v => {
                  v['vol'] = Number(getNum(v['vol'])).toFixed(2);
                  v['vol2'] = Number(getNum(v['vol2'])).toFixed(2);
                  v['qty'] = Number(getNum(v['qty'])).toFixed(2);
                  v['low'] = Number(getNum(v['low'])).toFixed(2);
                  v['hig'] = Number(getNum(v['hig'])).toFixed(2);
                  v['rqty'] = Number(getNum(v['rqty'])).toFixed(2);
                });
                this.input.total = response.total ? response.total : response.qty * 1000;
                this.input.memberId = this.id;
                produceReportUpdate(this.input) // excel_path를 디비에 저장
                  .then(res => {
                    // eslint-disable-next-line no-unused-vars
                    const response = res.data;
                    if (row.excel_path) {
                      // excel 등록했었으면 path가 있음
                      getReportFile(row.excel_path);
                    } else {
                      // excel_path 가 없어도 다운 받아야함, excel_path가 필요함
                      produceReportlist({
                        currentPage: this.currentPage,
                        ...this.param,
                      }).then(res => {
                        const response = res.data;
                        const items = response.data.items;
                        this.items = items;

                        let filteredItems = items.filter(item => item.lotNo === row.lotNo);
                        getReportFile(filteredItems[0].excel_path);
                      });
                    }
                  });
              }
            })
            .catch(() => {
              console.log('오류발생 : 관리자에게 문의 하세요 ');
            });
        }
      } else {
        this.openPopup('제조기록지 다운로드할 행을 선택해주세요');
      }
    },
    dbClick(data) {
      this.$emit('dbClick', _.filter(this.items, v => v.lotNo === data.lotNo)[0]);
    },
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.loadData();
    // this.search();
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss"></style>
