<template>
  <div>
    <SetPopup ref="confirm" />
    <h4 class="mb-2 dp--row">
      <!-- 품질검사 결과입력 -->
      <!-- <div class="btn-box">
        <v-btn depressed class="btn-add" @click="specAdd">추가</v-btn>
        <v-btn depressed class="btn-delete" @click="specDel">삭제</v-btn>
      </div> -->
    </h4>
    <!-- <div class="wrapperSpace">
      <h4 class="mt-4 mb-2">품질검사 결과입력</h4>
      <div class="btn-box">
        <v-btn depressed @click="specAdd">추가</v-btn>
        <v-btn depressed color="primary" @click="specDel">삭제</v-btn>
      </div>
    </div> -->
    <RealGrid domName="resultInputDetail" ref="resultInputDetail" :settings="settings" :nonePage="true" />
    <div class="wrapper mt-2 mb-2">
      <div class="btn-box">
        <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
        <v-btn depressed class="btn-save" @click="save">저장</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
import { resultsList, resultsUpdate } from 'api/sample/sample';
import { columns, fields, rows } from '@/assets/grid/resultInputDetail';
import { mapMutations } from 'vuex';
import { makeARow } from '@/assets/grid/gridUtill';
export default {
  props: ['data'],
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      id: '',
      out_date_check: false,
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        errorMessage: '결과가 없습니다',
        noneNo: true,
      },
    };
  },
  methods: {
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_USER_INFO']),
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    reset() {
      this.loadData();
    },
    save() {
      const rows = this.$refs.resultInputDetail.getJsonRows();
      _.each(rows, v => {
        v.lotNo = this.data.lotNo;
        v.code = this.data.code;
        v.memberId = this.id;
      });
      resultsUpdate(rows)
        .then(() => {
          this.REMOVE_SELECT_MENU('resultInputDetail');
          this.SET_SELECT_MENU_TAB_BY_CODE('RIMGMT');
        })
        .catch(() => {});
    },
    async loadData() {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const res = await resultsList({
          lotNo: this.data.lotNo,
          code: this.data.code,
        });
        this.isDataLoaded = false;

        const item = res.data.data.items;
        _.each(item, v => {
          // v.sub = (v.hig - v.vol).toFixed(2);
          v.sub = (v.hig - v.vol).toFixed(3); // 소수점 자리 2 > 3 변경
          if (v.sub.indexOf('.') === -1 || parseFloat(v.sub) === parseInt(v.sub)) {
            // 소수점 없거나 소수점 이하가 0인 경우
            v.sub = parseInt(v.sub); // 정수 부분만 추출
          }
        });
        this.$refs.resultInputDetail.loadData(item);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    specAdd() {
      const row = this.$refs.resultInputDetail.getJsonRows();
      const add = makeARow(this.settings.fields);
      this.$refs.resultInputDetail.loadData(row.concat(add));
    },
    specDel() {
      const row = this.$refs.resultInputDetail.getJsonRows();
      const idx = this.$refs.resultInputDetail.getCheckedRowIdx();
      if (idx.length < 1) {
        this.openPopup('삭제할 행을 선택해주세요', true);
      } else {
        this.openPopup('삭제하시겠습니까', true, () => {
          const ret = _.filter(row, (v, index) => {
            return !idx.includes(index);
          });
          this.$refs.resultInputDetail.loadData(ret);
        });
      }
    },
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    if (this.data.out_date !== undefined) {
      // 출하완료일이 있으면 입력막기
      this.$refs.resultInputDetail.resultSetColumnsEditable();
      this.$refs.resultInputDetail.setRowStyleCallback();
    }
    this.loadData();
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss"></style>
