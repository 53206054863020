<template>
  <div class="address newPartner new-partner-vue" style="padding: 0px 150px">
    <SetPopup ref="confirm" />
    <Address ref="address" @select="onAddress" p:userId="userId" />
    <SetDialog ref="postModal">
      <PTAddressPost :data="editAddressData" @closeModal="this.closePost" @approvePost="this.approvePost" :key="signupPostKey" ref="signupPost"></PTAddressPost>
    </SetDialog>

    <template>
      <v-form lazy-validation ref="newPartner">
        <div class="filter-box--row">
          <div class="stepper-container">
            <div class="line"></div>
            <div class="step">
              <div class="step-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path fill="#00592D" d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12s12-5.383 12-12S22.617 4 16 4m0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10S6 21.535 6 16S10.465 6 16 6m0 7a3 3 0 1 0 .002 6.002A3 3 0 0 0 16 13" />
                </svg>
              </div>
              <div class="step-text step1">Step 1</div>
            </div>
            <div class="step">
              <div v-show="!secondHeaderActive" class="step-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path fill="#C6C6C6" d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12s12-5.383 12-12S22.617 4 16 4m0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10S6 21.535 6 16S10.465 6 16 6m0 7a3 3 0 1 0 .002 6.002A3 3 0 0 0 16 13" />
                </svg>
              </div>
              <div v-show="secondHeaderActive" class="step-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path fill="#00592D" d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12s12-5.383 12-12S22.617 4 16 4m0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10S6 21.535 6 16S10.465 6 16 6m0 7a3 3 0 1 0 .002 6.002A3 3 0 0 0 16 13" />
                </svg>
              </div>
              <div class="step-text" :class="secondHeaderActive ? 'stepActive' : 'stepNoActive'">Step 2</div>
            </div>
          </div>
        </div>
        <div class="filter-box--row mt-6">
          <div class="filter-box" style="border: 1px solid #c8c8c8; padding: 0px; height: 550px">
            <h4 style="background-color: #00592d; padding: 5px; color: #fff">고객사 관련 정보</h4>
            <ul class="filter-list02" style="grid-template-columns: none; place-content: unset; margin: 30px 20px 20px 20px; width: 90%">
              <li>
                <h4 style="display: flex; justify-content: space-between">고객사코드<span style="padding-right: 40px; font-size: 0.8em; color: #8c8c8c">*자동 채번 됩니다.</span></h4>
                <div class="input-with-icon">
                  <v-text-field ref="pt_cd" outlined dense v-model="param.pt_cd" readonly disabled style="color: #c6c6c6 !important"></v-text-field>
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
            </ul>
            <h4 style="margin: 30px 10px 0px 20px">필수정보</h4>
            <ul class="filter-list_address" style="margin: 5px 20px 20px 20px; width: 90%">
              <li>
                <div class="input-with-icon">
                  <v-text-field ref="pt_nm" outlined dense v-model="param.pt_nm" :rules="[this.validSet.empty(param.pt_nm, ''), this.validSet.name]" placeholder="고객사명" @enter="validateCompanyName" @blur="validateCompanyName" @input="validateCompanyName" />
                  <svg v-show="companyNameValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="companyNameValid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="companyNameValid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li class="__dp--collums">
                <!-- <h4>우편번호</h4> -->
                <!-- <div class="dp--row" style="width: 100%; align-items: flex-start"> -->
                <div class="input-with-icon" style="display: flex; width: 100%; align-items: flex-start">
                  <v-text-field ref="postcode" type="text" outlined dense disabled filled v-model="param.postcode" :rules="[this.validSet.empty(param.postcode, '')]" placeholder="우편번호" />
                  <v-btn depressed class="ml-1 btn-address-search" @click="searchAddress" style="border: 1px solid #43852f !important; border-radius: 5px !important">주소검색</v-btn>
                  <svg v-show="address1Valid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="address1Valid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="address1Valid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li class="__dp--collums">
                <!-- <h4>대표주소</h4> -->
                <!-- <div class="dp--row" style="width: 100%; align-items: flex-start"> -->
                <div class="input-with-icon">
                  <!-- <v-text-field ref="address1" type="text" outlined dense disabled filled v-model="param.address1" :rules="[this.validSet.empty(param.address1, '')]" placeholder="대표주소(최초 등록 시 기본배송지로 저장되며, 추후 배송지 관리에서 변경 가능)" @enter="validateAddress1Valid" @blur="validateAddress1Valid" @input="validateAddress1Valid" /> -->
                  <v-text-field ref="address1" type="text" outlined dense disabled filled v-model="param.address1" :rules="[this.validSet.empty(param.address1, '')]" placeholder="대표주소(최초 등록 시 기본배송지로 저장되며, 추후 배송지 관리에서 변경 가능)" />
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <!-- <svg v-show="address1Valid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="address1Valid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg> -->
                </div>
                <!-- </div> -->
              </li>
              <li>
                <!-- <h4 class="">상세주소</h4> -->
                <div class="input-with-icon">
                  <v-text-field ref="address2" placeholder="상세주소" type="text" outlined dense v-model="param.address2" />
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
              <li>
                <div class="input-with-icon" style="width: 100%">
                  <v-radio-group v-model="param.open_yn" :rules="[this.validSet.emptyRadio(param.open_yn, '')]">
                    <div class="wrapper-space-radiogroup" style="width: 100%">
                      <div class="wrapper" style="margin-right: 5px">
                        <v-radio key="openyn_1" :value="'Y'" class="custom-radio">
                          <template v-slot:label>
                            <div class="custom-radio-label" :class="{ active: param.open_yn == 'Y' }">운영</div>
                          </template>
                        </v-radio>
                      </div>
                      <div class="wrapper">
                        <v-radio key="openyn_2" :value="'N'" class="custom-radio">
                          <template v-slot:label>
                            <div class="custom-radio-label" :class="{ active: param.open_yn == 'N' }">미운영</div>
                          </template>
                        </v-radio>
                      </div>
                    </div>
                  </v-radio-group>
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
              <!-- <li>
                <v-radio-group v-model="param.open_yn" :rules="[this.validSet.empty(param.open_yn, '운영여부를 선택해주세요.')]">
                  <div class="wrapperSpace">
                    <div class="wrapper">
                      <v-radio key="openyn_1" value="1"></v-radio>
                      <div>운영</div>
                    </div>
                    <div class="wrapper">
                      <v-radio key="openyn_2" value="0"></v-radio>
                      <div>미운영</div>
                    </div>
                  </div>
                </v-radio-group>
              </li> -->
              <li>
                <!-- <h4>고객사 담당자</h4> -->
                <div class="input-with-icon">
                  <v-text-field ref="rep_name" required outlined dense v-model="param.rep_name" placeholder="고객사 담당자" :rules="[this.validSet.empty(param.rep_name, '')]" @enter="validateRepNameValid" @blur="validateRepNameValid" @input="validateRepNameValid" />
                  <svg v-show="repNameValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="repNameValid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="repNameValid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li>
                <!-- <h4>대표 전화번호</h4> -->
                <div class="input-with-icon">
                  <v-text-field ref="phone" required outlined dense v-model="param.phone" placeholder="대표 전화번호(- 를 포함 입력)" :rules="[this.validSet.phoneRules(true, param.phone, '')]" minlength="11" maxlength="13" @enter="validatePhoneValid" @blur="validatePhoneValid" @input="validatePhoneValid" />
                  <svg v-show="phoneValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="phoneValid === true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="#1bbb58" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                  <svg v-show="phoneValid === false" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"><path fill="red" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>
                </div>
              </li>
              <li>
                <!-- <h4>E-mail</h4> -->
                <div class="input-with-icon">
                  <v-text-field ref="email" outlined dense v-model="param.email" :rules="[this.validSet.email]" placeholder="E-mail"></v-text-field>
                  <svg v-show="visibleValid == null" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 15"><path fill="white" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd" /></svg>
                </div>
              </li>
            </ul>
          </div>
          <div class="filter-box second-filter-box" style="padding: 0px; height: 550px">
            <h4 :class="activeHeaderClass" style="padding: 5px">비고.배송지 정보</h4>
            <ul class="filter-list_address" style="grid-template-columns: none; place-content: unset; margin: 30px 20px 20px 20px; width: 90%">
              <li>
                <h4 :class="secondHeaderActive ? 'stepActiveH4' : 'stepNoActiveH4'">비고</h4>
                <v-textarea outlined rows="10" style="" class="filter-textarea textarea-clean-border" dense placeholder="" v-model="param.note"></v-textarea>
              </li>
              <li class="dp--collums" style="margin-top: 30px">
                <h4 style="display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 6px 0px" :class="secondHeaderActive ? 'stepActiveH4' : 'stepNoActiveH4'">
                  배송지 추가 등록
                  <v-btn depressed class="mr-1 btn-load" id="_one_speed_add" style="height: 22px; font-size: 0.8em !important; border-radius: 5px !important; padding: 3px 8px" :style="addressAddButtonStyle" @click="openPost"><span class="text" style="padding-right: 5px; font-size: 16px">+</span> 배송지추가</v-btn>
                  <!-- <v-btn depressed class="mr-1 btn-load" id="_one_speed_add" style="height: 22px; font-size: 0.8em !important; border-radius: 5px !important" @click="openPost"><span class="text" style="padding-right: 5px">+</span> 배송지 추가</v-btn> -->
                </h4>
                <RealGrid :domName="grid" ref="grid" :settings="settings" />
                <h5 style="color: #9a9a9a; font-size: 0.8em !important">*필수 입력사항이 아니며, 추후 고객 마스터 상세에서 추가 가능.</h5>
              </li>
            </ul>
          </div>
        </div>
      </v-form>
    </template>
    <div class="wrapper mb-2">
      <div class="btn-box">
        <v-card-actions v-if="tester">
          <v-btn depressed @click="testData" class="btn-cancle">테스트 정보 자동입력</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed @click="cancle" class="btn-cancle">취소</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed class="btn-sample-request" @click="request_promise" :disabled="!secondHeaderActive">저장</v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<script>
import { scrollToTop } from '@/utils/common_utils';
import { columns, fields, rows } from '@/assets/grid/partnerMasterAddAddress';
import SetPopup from '@/components/SetPopup.vue';
import Address from '@/components/Address.vue';
import SetDialog from '@/components/SetDialog';
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import { getPartnerMasterCode, insertPartnerMaster } from 'api/partner/partner';
import { insertAddressPT } from 'api/partnerAddress/partnerAddressPT';
import _ from 'lodash';
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';
import PTAddressPost from '@/components/PTAddressPost.vue';
export default {
  watch: {
    'param.same': function (v) {
      if (v) {
        this.param.pick_name = this.param.request_name;
      }
    },
    'param.same2': function (v) {
      if (v) {
        this.param.pick_company = this.param.request_company;
      }
    },
    'param.default': function (v) {
      if (v === 0) {
        this.param.address = this.defaultAddress.address;
        this.param.address2 = this.defaultAddress.address2;
        this.param.postcode = this.defaultAddress.postcode;
      } else {
        this.param.address = '';
        this.param.address2 = '';
        this.param.postcode = '';
      }
    },
  },
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      signupPostKey: 0,
      grid: 'newPartner',
      editAddressData: {},
      settings: {
        columns: _.map(
          _.filter(_.cloneDeep(columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            if (v.fieldName == 'remove') {
              v.width = '50';
            }
            return v;
          },
        ),
        fields,
        rows,
        height: 200,
        radio: false, // true -> radio, false -> checkbox
        firstClick: true, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
        fitStyle: null,
        hideCheckBar: true,
        noneNo: true,
        blankGrid: true,
      },
      validSet,
      checkRows: false,
      codeSet: {},
      file: '',
      currentFileName: '',
      userId: '',
      param: {
        pt_cd: '',
        pt_nm: '',
        rep_name: '',
        open_yn: 'Y',
        postcode: '',
        address1: '',
        address2: '',
        phone: '',
        email: '',
        note: '',
        posts: [],
      },
      postItems: [],
      post: {
        name: '',
        pickname: '',
        modile: '',
        postcode: '',
        address1: '',
        address2: '',
      },
      isSelecting: false,
      address: [
        {
          key: 'default',
          text: '기본배송지',
        },
        {
          key: 'other',
          text: '다른 배송지',
        },
      ],
      tmpResult: [],
      picker: false,
      tester: false,
      companyNameValid: null,
      address1Valid: null,
      repNameValid: null,
      phoneValid: null,
      visibleValid: null,
      secondHeader: false,
      secondHeaderActive: false,
    };
  },
  created() {
    // if (this.data && this.data.open_yn !== undefined) {
    // props로 전달받은 data를 사용하여 param을 초기화
    //   this.param.open_yn = Number(this.data.open_yn);
    // }
  },
  computed: {
    ...mapState('common', ['code']),
    ...mapState('member', ['defaultAddress', 'userInfo']),
    otherAddress() {
      return !this.param.default;
    },
    activeHeaderClass() {
      return {
        secondHeader: !this.secondHeaderActive, // 조건부 클래스
        secondHeaderActive: this.secondHeaderActive, // 조건부 클래스
      };
    },
    activeStepperClass() {
      return {
        stepActive: !this.secondHeaderActive,
        stepNoActive: this.secondHeaderActive,
      };
    },
    addressAddButtonStyle() {
      return {
        border: '1px solid ' + this.secondHeaderActive ? ' #479748 !important' : ' rgb(201 201 201) !important',
        backgroundColor: this.secondHeaderActive ? ' #479748 !important' : ' rgb(201 201 201) !important',
      };
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    ...mapMutations('menu', ['SET_MENU', 'ADD_MENU_TAB', 'ADD_MENU_TAB_BY_CODE', 'REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    validateCompanyName() {
      this.companyNameValid = this.validSet.empty(this.param.pt_nm) ? true : false;
      this.updateHeaderState();
    },
    validateAddress1Valid() {
      this.address1Valid = this.validSet.empty(this.param.address1) ? true : false;
      this.updateHeaderState();
    },
    validateRepNameValid() {
      this.repNameValid = this.validSet.empty(this.param.rep_name) ? true : false;
      this.updateHeaderState();
    },
    validatePhoneValid() {
      let phValid = this.validSet.phoneRules(true, this.param.phone, '');
      this.phoneValid = phValid === true ? true : false;
      this.updateHeaderState();
    },
    updateHeaderState() {
      this.secondHeaderActive = this.valid() === true; // 유효성 검사 결과에 따라 헤더 상태 변경
    },
    checkRow(v) {
      if (v.clickData) {
        this.checkRows = true; // 입력 화면 및 저장버튼 보여주기.
      }
    },
    async getPartnerCode() {
      if (this.isFetching) return;
      this.isFetching = true;
      try {
        const res = await getPartnerMasterCode();
        this.isDataLoaded = true;
        const body = res.data;
        if (_.isEmpty(body.errorCode)) {
          if (body.data.pt_cd) {
            this.param.pt_cd = body.data.pt_cd;
            this.param.open_yn = 'Y';
          } else {
            this.openPopup('고객마스터를 위한 채번을 하지 못했습니다.');
          }
        }
      } catch (error) {
        console.error('Error fetching partner code:', error);
      } finally {
        this.isFetching = false;
      }
    },
    async request_promise() {
      const validated = this.valid();
      if (!validated) {
        this.openPopup('필수 항목을 입력해 주세요');
        return false;
      }
      try {
        const data = {
          ...this.param,
        };
        this.openConfirm('고객을 등록하시겠습니까?', true, () => {
          insertPartnerMaster(data)
            .then(res => {
              const body = res.data;
              if (!_.isEmpty(body.errorCode)) {
                this.openConfirm(body.errorMessage);
                return false;
              }
              let ptCd = body.data.pt_cd;
              const defaultPost = {};
              defaultPost.pt_cd = ptCd;
              defaultPost.defaultYn = 1;
              defaultPost.mobile = body.data.phone;
              defaultPost.name = body.data.pt_nm;
              defaultPost.pickname = body.data.rep_name;
              defaultPost.address1 = body.data.address1;
              defaultPost.address2 = body.data.address2;
              defaultPost.postcode = body.data.postcode;

              insertAddressPT(defaultPost);
              const postList = this.$refs.grid.getJsonRows();
              if (postList.length > 0) {
                postList.forEach(post => {
                  post.pt_cd = ptCd;
                  insertAddressPT(post)
                    .then()
                    .catch(err => console.log(err));
                });
              }
              this.openPopup('정상 처리하였습니다', false, () => {
                this.partnerMasterDetail(body.data);
                this.REMOVE_SELECT_MENU('partnerAdd');
                // this.reset();
                // 현재 탭을 닫고.
                // 상세페이지 탭으로 이동
              });
            })
            .catch(err => {
              this.setModal(err);
            });
        });
      } catch (error) {
        console.error('Error during request:', error);
      }
    },
    partnerMasterDetail(v) {
      this.$emit('partnerMasterDetail', v);
    },
    approvePost(post) {
      if (post) {
        const newRow = {
          defaultYn: 0,
          remove: '',
          idx: '', // add 상태에서는 필요 없는 필드.
          pt_cd: '',
          name: post.name || '',
          pickname: post.pickname || '',
          mobile: post.mobile || '',
          postcode: post.postcode || '',
          address1: post.address1 || '',
          address2: post.address2 || '',
        };
        if (this.$refs.grid && this.$refs.grid.dp) {
          this.postItems.push(newRow);
          this.$refs.grid.addRow(newRow);
        } else {
          console.error('Grid or DataProvider is null');
        }
      }
    },
    valid() {
      return this.$refs.newPartner.validate();
    },
    // newPartner() {
    //   this.$emit('newPartner');
    // },
    addressBook() {
      this.$refs.address.open();
    },
    cancle() {
      this.openConfirm('고객 등록을 취소하고 창을 닫으시겠습니까?', true, () => {
        this.REMOVE_SELECT_MENU('partnerAdd');
        this.SET_SELECT_MENU_TAB_BY_CODE('PTMGMT');
      });
    },
    async reset() {
      this.param = {
        open_yn: 'Y',
        pt_cd: '',
        pt_nm: '',
        rep_name: '',
        postcode: '',
        address1: '',
        address2: '',
        phone: '',
        email: '',
        note: '',
        posts: [],
      };
    },
    searchAddress() {
      const that = this;
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.address1 = data.address;
          this.param.address2 = data.address2;
          this.param.postcode = data.zonecode;
          // that.validateAddress1Valid();
        },
        onclose: function () {
          that.validateAddress1Valid();
        },
      }).open();
    },
    onAddress(data) {
      this.param.postcode = data.zonecode;
      this.param.address = data.address;
      this.param.address2 = data.address2;
    },
    openPost() {
      if (!this.secondHeaderActive) {
        return false;
      }
      this.SET_MODAL({
        // height: 600,
        width: 650,
        closable: true,
        customApprove: true,
      });
      this.$refs.postModal.openModal();
    },
    closeModal() {
      this.$router.push({ name: 'login' });
    },
    closePost() {
      this.$refs.postModal.closeModal();
      this.signupPostKey = moment().valueOf();
    },
    onApprove() {},
    openConfirm(message, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text: message,
        closable: closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable: closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    setModal(msg, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable: closable,
        text: msg,
      });
      this.$refs.confirm.openPopup(cb);
    },
    setModalAutoClose(msg, closable, cb, autoclose) {
      this.SET_POPUP({
        title: '조성 데이터가 누락되었습니다.',
        // height: 150,
        width: 350,
        closable: closable,
        text: msg,
      });
      this.$refs.confirm.openPopupAutoClose(cb, autoclose, 1800);
      scrollToTop();
    },
    testData() {
      let nm = this.generateTestNumber();
      this.param = {
        pt_cd: this.param.pt_cd,
        pt_nm: `업체명`,
        rep_name: `담당자`,
        address1: `충남 천안시 서북구 1공단2길 7 ${nm}`,
        address2: `상세주소 ${nm}`,
        postcode: '12345',
        phone: '010-1234-5678',
        open_yn: 'Y',
        email: `test_${nm}@test.com`,
        note: `비고_${nm}`,
      };
    },
    generateTestNumber() {
      let d = new Date();
      return d.getMonth() + 1 + '_' + d.getDate() + '_' + d.getHours() + '_' + d.getMinutes();
    },
  },
  components: {
    Address,
    PTAddressPost,
    SetPopup,
    RealGrid,
    SetDialog,
  },
  mounted() {
    // this.reset();
    this.tester = this.userInfo.memberId == 'kconfine@gmail.com' ? true : false;
    this.getPartnerCode();
    this.$refs.grid.loadData([]);
  },
};
</script>
<style lang="scss">
.aLink {
  color: blue !important;
  text-decoration: underline;
}
.address {
  .v-input__slot {
    width: 100%;
  }
  .row {
    height: 100px;
  }
}
.v-input--checkbox {
  .v-input__slot {
    top: -21px;
  }
}
.new-partner-vue {
  .rg-header-focus,
  .rg-rowbarcontainer .rg-table tr td.rg-rowindicator-focus {
    background: #4c8b23 !important;
  }
  .rg-grid {
    border: 1px solid #d0d0d0 !important;
  }
  .rg-header {
    background: #6c6c6c !important;
    color: #fff !important;
  }
  .v-input--selection-controls__input {
    margin-right: 0px;
  }
  .v-input--radio-group {
    margin-top: 0px;
  }
}
.add-btn-filter-list {
  display: flex !important;
  width: 100% !important;
  li {
    position: relative;
    width: 100% !important;
  }
}
.justify-content-between {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
}
.add-btn {
  justify-content: space-between !important;
}
#one_speed_add {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  padding: 8px 20px !important;
  border-radius: 5px !important;
  border: 1px solid #01aa01 !important;
  background-color: #01aa01 !important;
  font-size: 1rem !important;
  span.text {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 5px;
  }
}
.text-filed-note {
  height: 150px;
}
.delete-post-icon {
  cursor: pointer;
}
.delete-area {
  display: flex;
  justify-content: space-around !important;
}
.default-post {
  color: #cf0000;
  font-size: 11px;
  border-radius: 4px;
  border: 1px solid #cf0000;
  padding: 2px 4px;
  height: 20px;
}
.custom-radio {
  display: none;
}
.new-partner-vue {
  .stepper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 55%;
    margin: 0 auto;
  }
  .line {
    // width: 100%;
    height: 1px;
    background-color: #c6c6c6;
    position: absolute;
    top: 13px;
    left: 25px;
    right: 25px;
    transform: translateY(-50%);
  }
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background-color: transparent;
  }
  .step-icon svg:first-child {
    fill: #00592d !important;
  }
  .step-icon svg:last-child {
    fill: #c6c6c6 !important;
  }
  .step-text {
    font-size: 12px;
    font-weight: 600;
  }
  .step-text.step1,
  .stepActive {
    color: #00592d !important;
  }
  .step-text.step2,
  .stepNoActive {
    color: #d0d0d0 !important;
  }
  .stepActiveH4 {
    color: #5a5a5a !important;
  }
  .stepNoActiveH4 {
    color: #d0d0d0 !important;
  }
  .step-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .step-icon svg {
    position: relative;
  }

  .wrapper-space-radiogroup .wrapper {
    width: 49%;
    display: inline-block;
  }
  .wrapper-space-radiogroup .wrapper .v-input--selection-controls__input {
    display: none !important;
  }
  .wrapper-space-radiogroup .custom-radio-label {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
  .wrapper-space-radiogroup .custom-radio-label.active {
    background-color: white;
    color: #5a9549;
    border-color: #5a9549;
  }
  .v-text-field--outlined.v-input--is-focused fieldset {
  }
  .v-text-field--outlined.v-input--is-focused fieldset {
    border: 2px solid rgb(4, 176, 39) !important;
  }
  .v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid rgb(255, 135, 135) !important;
  }
  .textarea-clean-border.v-text-field--outlined.v-input--is-focused fieldset {
    border: 1px solid #fff !important;
  }
  .v-text-field--outlined fieldset {
    // border: 1px solid #18a143 !important;
  }
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled > .v-input__control > .v-input__slot fieldset,
.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
  border-radius: 5px !important;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  border-radius: 5px;
}
.filter-box .filter-list_address li {
  padding-bottom: 1px;
}
.input-with-icon {
  display: flex;
  align-items: center;
}
.input-with-icon v-text-field {
  flex-grow: 1;
}
.input-with-icon v-icon,
.input-with-icon svg {
  margin-left: 10px;
}
.input-with-icon {
  .v-messages.theme--light.error--text {
    display: none !important;
  }
}
.v-input--selection-controls.v-input {
  width: 100%;
}
.second-filter-box {
  border: 1px solid #c8c8c8 !important;
}
.second-filter-box-active {
  border: 1px solid #00592d !important;
}
.secondHeader {
  background-color: rgb(201, 201, 201) !important;
  color: #fff !important;
}
.secondHeaderActive {
  background-color: #00592d !important;
  color: #fff !important;
}
</style>
