import { ValueType } from 'realgrid';
import store from '@/store/index';
// import validSet from '@/assets/valid';
import _ from 'lodash';
export const fields = [
  {
    fieldName: 'roles',
  },
  {
    fieldName: 'member_name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'email',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'company',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'phone',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'employee_code',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'work',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'memo',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'save',
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'roles',
    fieldName: 'roles',
    sortable: false,
    lookupDisplay: true,
    values: _.filter(store.state.select.roleType, v => v !== '전체'),
    labels: _.filter(store.state.select.roleType, v => v !== '전체'),
    editor: {
      type: 'dropdown',
      textReadOnly: true, //*** 사용자가 키보드로 입력할 수 없게 할때
      domainOnly: true, //*** dropdown 목록에 있는 값만 허용하고자 할때
      dropDownWhenClick: true, //셀을 클릭해도 dropdown 목록이 펼쳐지길 원할때
    },
    header: {
      text: '계정구분',
      styleName: 'orange-column',
    },
    editButtonVisibility: 'always',
  },
  {
    name: 'member_name',
    fieldName: 'member_name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: '이름',
    editable: true,
    // displayCallback: (grid, index, value) => {
    //   const test = validSet.name(value);
    //   if (test === true) {
    //     return value;
    //   } else {
    //     alert(test);
    //     return '';
    //   }
    // },
  },
  {
    name: 'email',
    fieldName: 'email',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '이메일 주소',
      showTooltip: false,
    },
    editable: false,
    width: 250,
  },
  {
    name: 'company',
    fieldName: 'company',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '기업명',
      showTooltip: false,
    },
    editable: true,
    width: 200,
    // displayCallback: (grid, index, value) => {
    //   const test = validSet.company(value);
    //   if (test === true) {
    //     return value;
    //   } else {
    //     alert(test);
    //     return '';
    //   }
    // },
  },
  {
    name: 'phone',
    fieldName: 'phone',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '휴대폰 번호',
      showTooltip: false,
    },
    editable: true,
    editor: {
      mask: {
        editMask: '000-0000-0000',
      },
    },
    // displayCallback: function (grid, index, value) {
    //   value.replaceAll('-', '');
    //   var tmp = '';
    //   tmp += value.substr(0, 3);
    //   tmp += '-';
    //   tmp += value.substr(4, 9);
    //   return tmp;
    // },
  },
  {
    name: 'employee_code',
    fieldName: 'employee_code',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '사번',
      showTooltip: false,
    },
    editable: true,
    // displayCallback: (grid, index, value) => {
    //   const test = validSet.employNumber(value);
    //   if (test === true) {
    //     return value;
    //   } else {
    //     alert(test);
    //     return '';
    //   }
    // },
  },
  {
    name: 'work',
    fieldName: 'work',
    sortable: false,
    lookupDisplay: true,
    values: _.filter(store.state.select.workType, v => v !== '전체'),
    labels: _.filter(store.state.select.workType, v => v !== '전체'),
    editor: {
      type: 'dropdown',
      textReadOnly: true, //*** 사용자가 키보드로 입력할 수 없게 할때
      domainOnly: true, //*** dropdown 목록에 있는 값만 허용하고자 할때
      dropDownWhenClick: true, //셀을 클릭해도 dropdown 목록이 펼쳐지길 원할때
    },
    header: {
      text: '재직',
      styleName: 'orange-column',
    },
    editButtonVisibility: 'always',
  },
  {
    name: 'memo',
    fieldName: 'memo',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '비고',
      showTooltip: false,
    },
    editable: true,
  },
  {
    name: 'save',
    fieldName: 'save',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '저장',
    },
    editable: false,
    renderer: {
      type: 'button',
    },
  },
];
export const rows = [
  {
    no: 1,
    name: 'Kessie',
    type: '123',
    email: 'Vijendra N. Raj',
    coNumber: 'mus.Donec.dignissim@Praesent.edu',
    coName: '123',
    phone: '123',
    work: 'Arcu Et Pede Incorporated',
    memo: '17',
  },
];
