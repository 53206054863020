import http from '../http';
import { version } from '../version';
const prefix = `${version}/solvent`;

export async function solventMasterList(param) {
  return http.post(prefix + `/solventMasterList`, param);
}

export async function solventMasterDetail(param) {
  return http.post(prefix + `/solventMasterDetail?idx=${param}`);
}

export async function insertSolventMaster(param) {
  return http.post(prefix + `/insertSolventMaster`, param);
}

export async function insertActualCompositionList(param) {
  return http.post(prefix + `/insertActualCompositionList`, param);
}

export async function updateSolventMaster(param) {
  return http.post(prefix + `/updateSolventMaster`, param);
}

export async function getActualCompositionDetailList(param) {
  return http.post(prefix + `/getActualCompositionDetailList?idx=${param}`);
}

export async function updateActualComposition(param) {
  return http.post(prefix + `/updateActComposition`, param);
}
