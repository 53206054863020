import http from '../http';
import { version } from '../version';
const prefix = `${version}/partner`;

export async function getPartnerMasterCode() {
  return http.get(prefix + `/getPartnerMasterCode`);
}
export async function insertPartnerMaster(param) {
  return http.post(prefix + `/insertPartnerMaster`, param);
}

export async function updatePartnerMaster(param) {
  return http.put(prefix + `/updatePartnerMaster`, param);
}

export async function deletePartnerMaster(param) {
  return http.post(prefix + `/deletePartnerMaster`, param);
}

export async function getPartnerMaster(param) {
  return http.get(prefix + `/getPartnerMaster?pt_cd=${param}`);
}

export async function partnerMasterList(param) {
  return http.post(prefix + `/partnerMasterList`, param);
}
