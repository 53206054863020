<template>
  <div>
    <SetPopup ref="confirm" />
    <h3 class="mb-2">샘플 마스터 등록</h3>
    <v-form lazy-validation ref="form">
      <div class="filter-box filter-box--row">
        <ul class="filter-list04">
          <li>
            <h4>*마스터 복사</h4>
            <div class="wrapper">
              <v-text-field v-model="input.code_grade" outlined dense placeholder="" :rules="[this.validSet.empty(input.code_grade, 'Code Grade를 입력해주세요')]"></v-text-field>
              <v-btn style="height: 40px" depressed color="primary" class="ml-2" @click="search">불러오기</v-btn>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="wrapperSpace">
        <v-col cols="12" sm="4">
          <h4>*마스터 복사</h4>
          <div class="wrapper">
            <v-text-field
              v-model="input.code_grade"
              outlined
              dense
              placeholder="Code Grade를 입력해 주세요."
              :rules="[
                this.validSet.empty(
                  input.code_grade,
                  'Code Grade를 입력해주세요'
                ),
              ]"
            ></v-text-field>
            <v-btn
              style="height: 40px"
              depressed
              color="primary"
              class="ml-2"
              @click="search"
              >불러오기</v-btn
            >
          </div>
        </v-col>
      </div> -->
    </v-form>
    <h3 class="mt-4 mb-2 dp--row">기본 정보</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">기본 정보</div>
    </h3>
    <hr class="mb-4" /> -->
    <div class="filter-box filter-box--row">
      <ul class="filter-list">
        <li>
          <h4>w/w</h4>
          <div class="wrapper">
            <v-select v-model="param.ww" outlined dense no-outline hide-details :items="options"></v-select>
          </div>
        </li>
        <li style="width: 400px">
          <h4>품명</h4>
          <div class="wrapper">
            <v-text-field v-model="param.code_title" outlined dense placeholder=""></v-text-field>
          </div>
        </li>
        <li>
          <h4>양산자재 코드</h4>
          <v-text-field v-model="param.mass_code" outlined dense placeholder="" :rules="[this.validSet.commonCodeHipen]"></v-text-field>
        </li>
        <li>
          <h4>제품자재 코드</h4>
          <v-text-field v-model="param.product_code" outlined dense placeholder="" :rules="[this.validSet.commonCodeHipen]"></v-text-field>
        </li>
      </ul>
    </div>
    <!-- <v-row class="pl-3">
      <v-col cols="12" sm="4">
        <h4>품명</h4>
        <div class="wrapper">
          <v-text-field
            v-model="param.code_title"
            outlined
            dense
            placeholder="품명을 입력해 주세요."
          ></v-text-field>
        </div>
      </v-col>
    </v-row> -->
    <h3 class="mt-4 mb-2 dp--row">조성</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">조성</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid class="sample_grid" domName="settings_sample_add_div1" ref="sample_grid_div1" :settings="settings_div1" :nonePage="true" @changeData="addSpec('sample_grid_div1')" />
    <RealGrid class="sample_grid" domName="settings_sample_add_div2" ref="sample_grid_div2" :settings="settings_div2" :nonePage="true" />
    <RealGrid class="sample_grid" domName="settings_sample_add_div3" ref="sample_grid_div3" :settings="settings_div3_noSum" :nonePage="true" />
    <h3 class="mt-4 mb-2 dp--row">실제조성</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">실제조성</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid class="real_grid" domName="settings_real_add_div1" ref="real_grid_div1" :settings="settings_div1" :nonePage="true" @changeData="changeDataReal" />
    <RealGrid class="real_grid" domName="settings_real_add_div2" ref="real_grid_div2" :settings="settings_div2" :nonePage="true" @changeData="changeDataReal" />
    <RealGrid class="real_grid" domName="settings_real_add_div3" ref="real_grid_div3" :settings="settings_div3" :nonePage="true" @changeData="changeDataReal" />
    <h3 class="mt-4 mb-2 dp--row">제조조성</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">제조조성</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid class="make_grid" domName="settings_make_add_div1" ref="make_grid_div1" :settings="settings_div1" :nonePage="true" @changeData="changeDataMake" />
    <RealGrid class="make_grid" domName="settings_make_add_div2" ref="make_grid_div2" :settings="settings_div2" :nonePage="true" @changeData="changeDataMake" />
    <RealGrid class="make_grid" domName="settings_make_add_div3" ref="make_grid_div3" :settings="settings_div3" :nonePage="true" @changeData="changeDataMake" />
    <h3 class="mt-4 mb-2 dp--row">
      세부 스펙
      <div class="btn-box">
        <button class="btn-reset" @click="specAdd">추가</button>
        <button class="btn-search" @click="specDel">삭제</button>
      </div>
    </h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">
        세부 스펙
        <div>
          <v-btn depressed @click="specAdd">추가</v-btn>
          <v-btn depressed color="primary" @click="specDel">삭제</v-btn>
        </div>
      </div>
    </h3> 
    <hr class="mb-4" />-->
    <RealGrid domName="settings_spec" ref="spec_grid" :settings="settings_spec" :nonePage="true" />
    <div class="wrapper mt-4">
      <div class="btn-box">
        <button class="btn-reset" @click="reset">초기화</button>
        <button class="btn-search" @click="save">저장</button>
      </div>
      <!-- <v-card-actions>
        <v-btn depressed outlined @click="reset">초기화</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn depressed color="primary" @click="save">저장</v-btn>
      </v-card-actions> -->
    </div>
  </div>
</template>
<script>
import { insertSampleMaster, sampleMasterDetail } from 'api/sample/sample';
import { makeSum, makeARow, makeSampleSet, setNewSum } from '@/assets/grid/gridUtill';
import SetPopup from '@/components/SetPopup.vue';
import _ from 'lodash';
import * as sample1 from '@/assets/grid/sampleRequestDiv1';
import * as sample2 from '@/assets/grid/sampleRequestDiv2';
import * as sample3 from '@/assets/grid/sampleRequestDiv3';
import * as spec from '@/assets/grid/spec';
import RealGrid from '@/components/RealGrid.vue';
import { mapMutations } from 'vuex';
import validSet from '@/assets/valid';
export default {
  data() {
    return {
      id: '',
      validSet,
      input: {
        code_grade: '',
      },
      param: {
        code_title: '',
        code_grade: '',
        ww: '',
        mass_code: '',
        product_code: '',
      },
      key: {
        solvent1: 'solventVol1',
        solvent2: 'solventVol2',
        solvent3: 'solventVol3',
        solvent4: 'solventVol4',
        solvent5: 'solventVol5',
        solvent6: 'solventVol6',
        solvent7: 'solventVol7',
        solvent8: 'solventVol8',
        solvent9: 'solventVol9',
        solvent10: 'solventVol10',
      },
      grid: 'sampleMasterAdd',
      settings_div1: this.gridSetting(sample1, false),
      settings_div2: this.gridSetting(sample2, false),
      settings_div3: this.gridSetting(sample3, false),
      settings_div3_noSum: this.gridSetting(sample3, true),
      settings_spec: this.gridSetting(spec, false, 700, false),
      options: [
        { text: 'Y', value: 1 },
        { text: 'N', value: 0 },
      ],
    };
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.setGrid();
  },
  methods: {
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_USER_INFO']),
    valid() {
      return this.$refs.form.validate();
    },
    gridSetting(sample, existSum, height, hideCheckBar) {
      return {
        ...sample,
        columns: existSum
          ? _.map(
              _.filter(_.cloneDeep(sample3.columns), v => v.fieldName !== 'sum'),
              function (v) {
                v.editable = true;
                return v;
              },
            )
          : _.map(_.cloneDeep(sample.columns), function (v) {
              v.editable = true;
              return v;
            }),
        hideCheckBar: hideCheckBar === false ? hideCheckBar : true,
        height: height ? height : 150,
        noneNo: true,
      };
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable,
        text,
      });
      this.$refs.confirm.openPopup(cb);
    },
    newSample() {
      this.$emit('newSample');
    },
    setNewSum(ref) {
      let tmp = {};
      for (let i = 1; i <= 3; i++) {
        tmp = { ...tmp, ...this.$refs[ref + i].getJsonRow() };
      }
      const sum = [setNewSum(tmp)];
      this.$refs[ref + 3].loadData(sum);
    },
    changeDataReal() {
      this.setNewSum('real_grid_div');
    },
    changeDataMake() {
      this.setNewSum('make_grid_div');
    },
    addSpec(ref) {
      const row = this.$refs[ref].getJsonRow();
      const key = _.keys(row);
      const beforeSpec = this.$refs.spec_grid.getJsonRows();
      const fixSpec = beforeSpec.slice(-11);
      // const rowtmp = makeARow(spec.fields)[0];
      const rowArr = [];
      let unit = '';
      let vol = '';
      _.each(key, v => {
        if (!v.includes('Vol')) {
          if (v.includes('solvent')) {
            unit = 'vol';
            vol = Number(row[this.key[v]]);
          } else if (v.includes('salt')) {
            unit = 'M.wt%';
          } else if (v.includes('add')) {
            unit = 'wt%';
          }
          const spec = row[v];
          if (!_.isEmpty(spec)) {
            rowArr.push({ spec, unit, vol, low: 0, mid: 0, hig: 0 });
          }
        }
      });
      this.$refs.spec_grid.loadData(rowArr.concat(fixSpec));
    },
    setGrid() {
      const gridKey = ['sample_grid_div', 'real_grid_div', 'make_grid_div'];
      const sampleKey = [sample1.fields, sample2.fields, sample3.fields];
      this.setGridExec(gridKey, sampleKey);
      this.$refs.spec_grid.loadData(spec.initRow);
    },
    setGridExec(g, s) {
      _.each(g, v => {
        for (let i = 1; i <= 3; i++) {
          this.$refs[v + i].loadData(makeARow(s[i - 1]));
        }
      });
    },
    setGridExecSearch(g, s) {
      _.each(g, v => {
        for (let i = 1; i <= 3; i++) {
          this.$refs[v + i].loadData([s[v]]);
        }
      });
    },
    search() {
      if (this.valid()) {
        sampleMasterDetail(this.input.code_grade)
          .then(res => {
            const code = this.input.code_grade;
            const response = res.data.data;
            const CodeDB = response.CodeDB;
            const CodeDB_A = response.CodeDB_A;
            const CodeDB_B = response.CodeDB_B;
            const CodeDB_Dt = response.CodeDB_Dt;
            const gridKey = ['sample_grid_div', 'real_grid_div', 'make_grid_div'];
            const sampleKey = {
              sample_grid_div: { ...CodeDB, code },
              real_grid_div: { ...makeSum(CodeDB_A), code },
              make_grid_div: { ...makeSum(CodeDB_B), code },
            };
            this.setGridExecSearch(gridKey, sampleKey);
            this.$refs.spec_grid.loadData(CodeDB_Dt);
            this.param.code_grade = code;
            this.param.mass_code = CodeDB.mass_code;
            this.param.product_code = CodeDB.product_code;
          })
          .catch(res => {
            console.error(res);
          });
      }
    },
    reset() {
      this.param = {
        name: '',
        code_grade: '',
        ww: '',
        mass_code: '',
        product_code: '',
      };
      this.input = {
        code_grade: '',
      };
      this.setGrid();
    },
    specAdd() {
      const row = this.$refs.spec_grid.getJsonRows();
      const add = makeARow(spec.fields);
      this.$refs.spec_grid.loadData(row.concat(add));
    },
    specDel() {
      const row = this.$refs.spec_grid.getJsonRows();
      const idx = this.$refs.spec_grid.getCheckedRowIdx();
      if (idx.length < 1) {
        this.openPopup('삭제할 행을 선택해주세요');
      } else {
        this.openPopup('삭제하시겠습니까', true, () => {
          const ret = _.filter(row, (v, index) => {
            return !idx.includes(index);
          });
          this.$refs.spec_grid.loadData(ret);
        });
      }
    },
    save() {
      const sum1 = this.$refs.real_grid_div3.getJsonRow().sum;
      const sum2 = this.$refs.make_grid_div3.getJsonRow().sum;
      if (sum1 > 100 || sum2 > 100) {
        this.openPopup('SUM 정보를 확인해 주세요');
      } else {
        // const code = this.input.code_grade;
        const sample = {
          ...this.$refs.sample_grid_div1.getJsonRow(),
          ...this.$refs.sample_grid_div2.getJsonRow(),
          ...this.$refs.sample_grid_div3.getJsonRow(),
          code_title: this.param.code_title,
          mass_code: this.param.mass_code,
          product_code: this.param.product_code,
          memberId: this.id,
        };
        const sampleA = {
          ...this.$refs.real_grid_div1.getJsonRow(),
          ...this.$refs.real_grid_div2.getJsonRow(),
          ...this.$refs.real_grid_div3.getJsonRow(),
          memberId: this.id,
        };
        const sampleB = {
          ...this.$refs.make_grid_div1.getJsonRow(),
          ...this.$refs.make_grid_div2.getJsonRow(),
          ...this.$refs.make_grid_div3.getJsonRow(),
          memberId: this.id,
        };
        const dt = this.$refs.spec_grid.getJsonAllRow();
        const sampleDetail = makeSampleSet(dt, null, this.id);
        this.openPopup('저장 하시겠습니까?', true, () => {
          insertSampleMaster({
            sample,
            sampleA,
            sampleB,
            sampleDetail,
          })
            .then(() => {
              this.openPopup('저장되었습니다', false);

              this.REMOVE_SELECT_MENU('sampleAdd');
              this.SET_SELECT_MENU_TAB_BY_CODE('SPMGMT');
              this.$emit('save');
            })
            .catch(() => {});
        });
      }
    },
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss" scope>
.sample_grid {
  margin-bottom: 10px !important;
  .rg-header {
    background-color: #edf1d6;
  }
}
.real_grid {
  margin-bottom: 10px !important;
  .rg-header {
    background-color: #e1eedd;
  }
}
.make_grid {
  margin-bottom: 10px !important;
  .rg-header {
    background-color: #9dc08b;
  }
}
</style>
