import _ from 'lodash';
import { makeCell } from './gridUtill';
let fields = [];
let columns = [];
const data = [
  {
    field: 'lotNo',
    alias: 'Lot No',
  },
  {
    field: 'code',
    alias: 'Code Grade',
  },
  {
    field: 'ww',
    alias: 'w/w',
  },
  {
    field: 'code_title',
    alias: '품명',
  },
  {
    field: 'request_company',
    alias: '요청업체명',
  },
  {
    field: 'request_name',
    alias: '요청자',
  },
  {
    field: 'delivery_date',
    alias: '납기일',
  },
  {
    field: 'qty',
    alias: '제품수량(kg)',
  },
  {
    field: 'stat',
    alias: '접수 상태',
  },
  {
    field: 'producestat',
    alias: '제조 진행사항',
  },
  {
    field: 'out_due_date',
    alias: '출하 예정일',
  },
];

_.each(data, function (o) {
  o.indexExclusive = true;
  if (o.width) {
    makeCell(1, [o], fields, columns);
  } else {
    makeCell(1, [o], fields, columns, o.width);
  }
});
const hideCheckBar = true;
const errorMessage = '항목이 존재하지 않습니다';
export { fields, columns, errorMessage, hideCheckBar };
