<template>
  <div class="addressPTPost-div">
    <h3 class="mb-3 h-style">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="none" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 6H5m2 3H5m2 3H5m2 3H5m2 3H5m11-1a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2M7 3h11a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m8 7a2 2 0 1 1-4 0a2 2 0 0 1 4 0" /></svg>
      <span>배송지 등록</span>
    </h3>
    <SetPopup ref="postConfirm" />
    <v-form ref="ptAddressPostFrm" lazy-validation>
      <div class="post-contents">
        <ul class="post__box">
          <li class="post__list border-bottom-none">
            <SignupInput class="error-text-none" placeholder="" label="배송지명*" type="text" v-model="param.name" required :rules="[this.validSet.empty(param.name, '')]" :maxlength="30" />
          </li>
          <li class="post__list border-bottom-none">
            <SignupInput class="error-text-none" placeholder="" label="수령자*" type="text" v-model="param.pickname" required :rules="[this.validSet.empty(param.pickname, '')]" :maxlength="30" />
          </li>
          <li class="post__list" style="padding-bottom: 5px !important">
            <SignupInput class="error-text-none" placeholder="" label="핸드폰 번호*" type="text" v-model="param.mobile" required :rules="[this.validSet.phoneRules(true, param.mobile)]" minlength="11" maxlength="13" />
          </li>
          <li class="post__list border-bottom-none btn-address" style="padding-top: 5px !important">
            <SignupInput class="error-text-none" placeholder="" label="주소*" type="text" v-model="param.postcode" sideBtn="true" btnText="주소검색" :click="daumPostCode" required :rules="[this.validSet.empty(param.postcode, '')]" :disabled="true" />
          </li>
          <li class="post__list border-bottom-none">
            <SignupInput class="error-text-none" placeholder="" label="대표주소*" type="text" v-model="param.address1" autocomplete="off" :rules="[this.validSet.empty(param.address1, '')]" />
          </li>
          <li class="post__list border-bottom-none">
            <SignupInput class="error-text-none" placeholder="" label="상세주소" type="text" v-model="param.address2" autocomplete="off" />
          </li>
        </ul>
      </div>
    </v-form>
    <div class="wrapper wrapper-post-btn">
      <div class="btn-box mt-2">
        <div class="post-btn">
          <v-btn class="btn-cancle" elevation="0" @click="closeModal">취소</v-btn>
        </div>
        <div class="post-btn">
          <v-btn class="btn-confim" elevation="0" @click="onApprove">저장</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validSet from '@/assets/valid';
import SignupInput from '@/views/member/SignUpInput.vue';
import SetPopup from '@/components/SetPopup.vue';
import { mapMutations } from 'vuex';
// import { EventBus } from '../plugins/eventBus';

export default {
  props: ['data', 'isInitializing'],
  name: 'PTAddressPost',
  data() {
    return {
      validSet,
      closeStatus: null,
      param: {
        defaultYn: 0,
        pickname: '',
        mobile: '',
        postcode: '',
        name: '',
        address1: '',
        address2: '',
        rowIndex: '',
        // editableType: '',
        // edit: '',
        editabled: '',
        remove: '',
      },
      errors: {
        name: false,
        pickname: false,
        mobile: false,
        postcode: false,
        address1: false,
      },
    };
  },
  components: {
    SignupInput,
    SetPopup,
  },
  computed: {},
  created() {
    // EventBus.$on('editAddress', this.loadAddressData);
  },
  beforeDestroy() {
    // EventBus.$off('editAddress', this.loadAddressData);
  },
  watch: {
    data: {
      handler(newData) {
        this.loadData(newData);
      },
      deep: true, // 객체 내부까지 감시
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    valid() {
      return this.$refs.ptAddressPostFrm.validate();
    },
    // loadAddressData(payload) {
    //   this.param = { ...this.param, ...payload.data };
    //   this.editableType = payload.type;
    // },
    onApprove() {
      if (this.valid()) {
        // const message = this.editableType === 'edit' ? '배송지가 수정 되었습니다' : '배송지가 저장 되었습니다';
        const message = this.param.editabled ? '배송지가 수정 되었습니다' : '배송지가 저장 되었습니다';
        this.SET_POPUP({
          title: '알림',
          text: message,
          width: 300,
        });
        this.$emit('approvePost', this.param);
        this.closeStatus = true;
        // this.$refs.postConfirm.openPopup(() => {
        //   // this.$emit('approvePost', { ...this.param, editableType: this.editableType });
        //   // this.reset(); // 상태 초기화
        //   // this.editableType = ''; // 상태 초기화
        //   this.closeModal();
        // });
        this.closeModal();
      }
    },
    reset() {
      this.param = {
        defaultYn: 0,
        pickname: '',
        mobile: '',
        postcode: '',
        name: '',
        address1: '',
        address2: '',
        rowIndex: '',
        // editableType: '',
        // edit: '',
        editabled: '',
        remove: '',
      };
      // this.editableType = ''; // 상태 초기화
    },
    closeModal() {
      this.reset(); // reset 메서드 호출
      this.$emit('closeModal');
    },
    daumPostCode() {
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.postcode = data.zonecode;
          this.param.address1 = data.roadAddress;
        },
      }).open();
    },
    loadData(v) {
      if (this.isInitializing) {
        return;
      }
      if (v == undefined) {
        this.reset();
      }
      if (v) {
        if (v.editabled == undefined && v.remove == undefined) {
          setTimeout(() => {
            this.param = { ...this.param, ...v };
          }, 300);
        } else {
          this.param = { ...this.param, ...v };
        }
      }
    },
  },
  mounted() {
    // 테스트 용 데이터
    // this.id = this.$store.state.member.userInfo.memberId;
    // if (this.id == 'kconfine@gmail.com') {
    //   this.param = {
    //     defaultYn: 0,
    //     pickname: '수령자',
    //     mobile: '010-1234-5678',
    //     postcode: '01020',
    //     name: ' 선행개발팀',
    //     address1: '대전 유성구 문지로 188',
    //     address2: 'LG 에너지 솔루션 180-08',
    //   };
    // }
    this.loadData(this.data);
  },
};
</script>
<style lang="scss">
.addressPTPost-div {
  .h-style {
    display: flex;
    text-align: left;
    align-items: center;
    border-bottom: 1px solid #d0d0d0;
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 10px;
    padding-left: 10px;

    span {
      padding-left: 5px;
    }
  }
  .error-text-none .v-text-field__details {
    display: none !important;
  }
  .error-text-none .v-text-field--outlined fieldset {
    border-radius: 10px;
  }
  .v-text-field--outlined.v-input--is-focused fieldset {
    border: 2px solid rgb(4, 176, 39) !important;
  }
  .v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid rgb(255, 135, 135) !important;
  }
  .cardWrapperLeft {
    display: flex;
  }
  .cardWrapper {
    display: flex;
    justify-content: center;
  }
  .col {
    flex-direction: column;
  }
  .addDetail {
    width: 400px;
    margin-top: 17px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  .addDetailOther {
    width: 400px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  table {
    border: 1px solid;
    border-collapse: collapse;
    width: 60%;
    margin-top: 15px;
  }
  th,
  td {
    border: 1px solid;
  }
  button {
    border-radius: 6px !important;
  }
  .btn-address .btn-save {
    color: #08800a !important;
    border: 1px solid #08800a !important;
    background: #fff !important;
  }
  .v-input--radio-group--row .v-messages.theme--light {
    display: none !important;
  }
  .addressSelect {
    display: flex;
    position: relative;
    align-items: center;
    height: 56px;
  }
  .post-contents .post__box .post__list {
    padding: 0px !important;
  }
  .border-bottom-none {
    border: 1px solid #fff !important;
  }
  .wrapper-post-btn {
    border-top: 1px solid #d0d0d0;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-top: 10px;

    .btn-box {
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0px;

      .post-btn {
        width: 49%;
        button {
          min-width: 100%;
          span {
            text-transform: none !important;
          }
        }
      }
    }
  }
}
</style>
