import { ValueType } from 'realgrid';

export const fields = [
  {
    fieldName: 'name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pickname',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'mobile',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'postcode',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address1',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address2',
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'name',
    fieldName: 'name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '배송지명',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 90,
  },
  {
    name: 'pickname',
    fieldName: 'pickname',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '수령자',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 80,
  },
  {
    name: 'mobile',
    fieldName: 'mobile',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '핸드폰번호',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 100,
  },
  {
    name: 'postcode',
    fieldName: 'postcode',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '우편번호',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 60,
  },
  {
    name: 'address1',
    fieldName: 'address1',
    type: 'data',
    styles: {
      textAlignment: 'near',
    },
    header: {
      text: '주소',
      showTooltip: false,
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell) {
        const address1 = grid.getValue(cell.index.itemIndex, 'address1');
        const address2 = grid.getValue(cell.index.itemIndex, 'address2');
        return `<div>${address1} ${address2}</div>`;
      },
    },
    editable: false,
    sortable: false,
    width: 300,
  },
];
export const rows = [
  {
    name: '',
    pickname: '',
    mobile: '',
    postcode: '',
    address1: '',
    address2: '',
  },
];
