<template>
  <div>
    <!-- <h3 class="mb-2">회원 마스터 관리</h3> -->
    <v-form ref="userMaster" lazy-validation>
      <div class="filter-box filter-box--row">
        <ul class="filter-list">
          <li>
            <h4>기업명</h4>
            <v-text-field outlined dense placeholder="" v-model="param.company" :rules="[this.validSet.company]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>이름</h4>
            <v-text-field outlined dense placeholder="" v-model="param.memberName" :rules="[this.validSet.name]" @keyup.enter="search"></v-text-field>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- <div class="userMaster wrapperSpace sch">
        <div style="width: 50%">
          <v-row class="ml-0 px-2 py-3 pt-6">
            <v-col cols="12" sm="6">
              <h4>이름</h4>
              <v-text-field
                outlined
                dense
                placeholder="이름을 입력해 주세요."
                v-model="param.memberName"
                :rules="[this.validSet.name]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <h4>기업명</h4>
              <div class="wrapperTop">
                <v-text-field
                  outlined
                  dense
                  placeholder="기업명을 입력해 주세요."
                  v-model="param.company"
                  :rules="[this.validSet.company]"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div> 
        <div class="wrapper">
          
        </div> 
      </div>-->
    </v-form>
    <!-- <h3 class="mt-4 mb-2 dp--row">목록</h3> -->
    <!-- <h3 class="mt-16 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">목록</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" />
  </div>
</template>
<script>
import { columns, fields } from '@/assets/grid/userMaster';
import RealGrid from '@/components/RealGrid.vue';
import { memberList } from 'api/member/member';
import _ from 'lodash';
import validSet from '@/assets/valid';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      validSet,
      grid: 'userMaster',
      settings: {
        columns,
        fields,
        height: 650,
        errorMessage: '데이터가 존재하지 않습니다',
        hideCheckBar: true,
      },
      param: {
        memberName: '',
        company: '',
        pageSize: 100000,
      },
      currentPage: 1,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    newSample() {
      this.$emit('newSample');
    },
    valid() {
      return this.$refs.userMaster.validate();
    },
    loadData(v) {
      this.search(v);
    },
    async search(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await memberList({
          ...this.param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = false;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.$refs.grid.loadData(items);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    dbClick(data) {
      this.$emit('dbClick', data);
    },
    reset() {
      this.param = {
        memberName: '',
        company: '',
        pageSize: 100000,
      };
    },
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss"></style>
