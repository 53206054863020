<template>
  <div>
    <SetDialog ref="add">
      <AddAcount @close="close" @save="save" />
    </SetDialog>
    <div class="service login">
      <!-- <h3 class="mb-2">로그인 관리</h3> -->
      <v-form ref="form" lazy-validation>
        <div class="filter-box filter-box--row">
          <ul class="filter-list">
            <li>
              <h4>계정 구분</h4>
              <v-select :items="this.roleType" v-model="input.roles" outlined :id="'account'"></v-select>
            </li>
            <li>
              <h4>로그인 방식</h4>
              <v-select :items="code.L" v-model="input.logintype" outlined :id="'account'"></v-select>
            </li>
            <li>
              <h4>이름</h4>
              <v-text-field outlined dense placeholder="" v-model="input.member_name" :rules="[this.validSet.name]" @keyup.enter="onApprove"></v-text-field>
            </li>
          </ul>
          <div class="btn-box">
            <v-card-actions>
              <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed class="btn-search" @click="onApprove">검색</v-btn>
            </v-card-actions>
          </div>
        </div>
        <!-- <div class="filter-box sch pt-4 pl-2">
          <v-row class="row mb-7">
            <v-col cols="12" sm="3">
              <h4>계정 구분</h4>
              <v-select
                :items="this.roleType"
                v-model="input.roles"
                outlined
                :id="'account'"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <h4>로그인 방식</h4>
              <v-select
                :items="code.L"
                v-model="input.logintype"
                outlined
                :id="'account'"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <h4>이름</h4>
              <v-text-field
                outlined
                dense
                placeholder="이름을 입력해 주세요."
                v-model="input.member_name"
                :rules="[this.validSet.name]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="wrapperEnd mt-4">
                <v-card-actions>
                  <v-btn depressed outlined @click="reset">초기화</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed color="primary" @click="onApprove"
                    >검색</v-btn
                  >
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </div> -->
      </v-form>
      <!-- <h3 class="mt-4 mb-2">
        목록
        <v-btn depressed color="primary" @click="add">신규생성</v-btn>
      </h3> -->
      <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" />
    </div>
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/loginMgn';
import SetDialog from '@/components/SetDialog.vue';
import AddAcount from '@/views/admin/user/AddAcount.vue';
import RealGrid from '@/components/RealGrid.vue';
import { loginLog } from 'api/member/member';
import { mapMutations, mapState } from 'vuex';
import _ from 'lodash';
import validSet from '@/assets/valid';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      input: {
        roles: '전체',
        logintype: '전체',
        member_name: '',
        pageSize: 100000,
      },
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        hideCheckBar: true,
        errorMessage: '조회된 내용이 없습니다',
      },
      grid: 'loginMgn',
      validSet,
    };
  },
  computed: {
    ...mapState('select', ['workType', 'roleType']),
    ...mapState('common', ['code']),
  },
  mounted() {
    this.SET_POPUP({
      title: '알림',
      // height: 150,
      width: 300,
      customApprove: true,
    });
    this.input.roles = this.roleType[0];
    this.loadData();
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    reset() {
      this.input = {
        roles: '전체',
        member_name: '',
        logintype: '전체',
        pageSize: 100000,
      };
    },
    valid() {
      return this.$refs.form.validate();
    },
    loadData(v) {
      this.onApprove(v);
    },
    async onApprove(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const param = _.cloneDeep(this.input);
        switch (param.employeeStatus) {
          case '재직중':
            param.employeeStatus = 1;
            break;
          case '퇴사':
            param.employeeStatus = 2;
            break;
          case '전체':
            param.employeeStatus = '';
            break;
        }
        switch (param.logintype) {
          case 'SITE':
            param.logintype = 1;
            break;
          case 'AD':
            param.logintype = 2;
            break;
          case 'SSO':
            param.logintype = 3;
            break;
          case '전체':
            param.logintype = '';
            break;
        }
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await loginLog({
          ...param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        _.each(items, v => {
          switch (v.logintype) {
            case 1:
              v.logintype = 'SITE';
              break;
            case 2:
              v.logintype = 'AD';
              break;
            case 3:
              v.logintype = 'SSO';
              break;
            case '전체':
              v.logintype = '';
              break;
          }
          switch (v.employee_status) {
            case '1':
              v.employee_status = '재직중';
              break;
            case '2':
              v.employee_status = '퇴사';
              break;
            default:
              v.employee_status = '-';
              break;
          }
        });
        this.$refs.grid.loadData(items);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    add() {
      this.SET_MODAL({
        height: 500,
        width: 750,
        closable: true,
        approveName: '저장',
        customApprove: true,
      });
      this.$refs.add.openModal();
    },
    save(param) {
      this.close();
      this.openPopup('입력된 정보로 아이디를 생성하시겠습니까?');
      this.saveParam = param;
    },
    close() {
      this.check = false;
      this.$refs.add.closeModal();
    },
    openPopup(message, cb) {
      this.SET_POPUP_TEXT(message);
      this.$refs.addPopup.openPopup(cb);
    },
  },

  components: {
    SetDialog,
    AddAcount,
    RealGrid,
  },
};
</script>
<style>
.login .v-input__slot {
  width: 100% !important;
}
</style>
