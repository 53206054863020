import _ from 'lodash';
import { makeCell } from './gridUtill';
let fields = [];
let columns = [];
const data = [
  {
    field: 'lotNo',
    alias: 'Lot No',
  },
  {
    field: 'code',
    alias: 'Code Grade',
  },
  {
    field: 'salestype',
    alias: '분류',
    width: 500,
  },
  {
    field: 'request_date',
    alias: '요청일',
  },
  {
    field: 'produce_due_date',
    alias: '제조예정일',
  },
  {
    field: 'produce_date',
    alias: '제조완료일',
  },
  {
    field: 'out_due_date',
    alias: '출하예정일',
  },
  {
    field: 'out_date',
    alias: '출하완료일',
  },
  {
    field: 'delivery_due_date',
    alias: '납기 예정일',
  },
  {
    field: 'delivery_date',
    alias: '납기일',
  },
  {
    field: 'request_company',
    alias: '요청 업체명',
  },
  {
    field: 'request_name',
    alias: '요청자',
  },
  {
    field: 'phone1',
    alias: '요청자 연락처',
  },
  {
    field: 'pick_name_chg',
    alias: '수령자 변경여부',
  },
  {
    field: 'pickpart',
    alias: '수령업체명',
  },
  {
    field: 'pick_name',
    alias: '수령자',
  },
  {
    field: 'phone2',
    alias: '수령자 연락처',
  },
  {
    field: 'price_type2',
    alias: '유,무상',
  },
  {
    field: 'qty2',
    alias: 'Qty(kg)',
  },
  {
    field: 'packing',
    alias: '포장용기',
  },
  {
    field: 'delivery_type',
    alias: '배송방법',
  },
  {
    field: 'request_code',
    alias: '고객요청자재코드',
  },
  {
    field: 'code_title',
    alias: '품명',
    width: 500,
  },
  {
    field: 'postcode',
    alias: '우편번호',
  },
  {
    field: 'address',
    alias: '주소',
    with: 300,
  },
  {
    field: 'address2',
    alias: '상세주소',
    with: 300,
  },
  {
    field: 'resultYn',
    alias: '결과입력',
  },
];

_.each(data, function (o) {
  o.indexExclusive = true;
  makeCell(1, [o], fields, columns);
});
export { fields, columns };
