<template>
  <div>
    <SetPopup ref="confirm" />
    <v-form ref="partnerMaster" lazy-validation>
      <div class="filter-box filter-box--row" style="margin-bottom: 20px">
        <ul class="filter-list" style="width: 80%">
          <li>
            <h4>고객사코드</h4>
            <v-text-field v-model="param.pt_cd" outlined dense placeholder="" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>고객사명</h4>
            <v-text-field v-model="param.pt_nm" outlined dense placeholder="" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>담당자명</h4>
            <v-text-field v-model="param.rep_name" outlined dense placeholder="" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>운영여부</h4>
            <!-- <v-text-field v-model="param.open_yn" outlined dense placeholder="운영여부" @keyup.enter="search"></v-text-field> -->
            <v-select :items="openYnOptions" v-model="input.open_yn" outlined :id="'open_yn'"></v-select>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="searchBtn">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-form>
    <h3 class="mt-1 mb-1 dp--row">
      <div class="btn-box" style="margin-bottom: 8px">
        <v-btn style="margin-left: auto" depressed class="btn-reset" @click="filterAction">필터</v-btn>
        <v-btn style="margin-left: auto" depressed class="btn-reset" @click="excelDownload">엑셀다운</v-btn>
        <v-btn style="margin-left: auto" depressed class="btn-search" @click="addPartner">고객등록</v-btn>
      </div>
    </h3>
    <RealGrid :oneLineGrid="true" :domName="grid" ref="grid" :settings="settings" @changePage="search" @dbClick="dbClick" />
  </div>
</template>
<script>
import _ from 'lodash';
import { columns, fields, rows } from '@/assets/grid/partnerMaster';
import { partnerMasterList } from 'api/partner/partner';
import { mapMutations } from 'vuex';
import SetPopup from '@/components/SetPopup.vue';
import RealGrid from '@/components/RealGrid.vue';
import { getExcel } from 'api/file';
import validSet from '@/assets/valid';

export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'partnerMaster',
      validSet,
      items: [],
      param: {
        pt_cd: '',
        pt_nm: '',
        rep_name: '',
        // phone: '',
        open_yn: 'Y',
        search_yn: 'Y', // 초기 로딩 시 'N'으로 설정
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
      },
      settings: {
        columns,
        fields,
        rows,
        height: 555,
        errorMessage: '요청된 내역이 없습니다',
        hideCheckBar: false,
        fixCol: 1,
        radio: false,
        fitStyle: 'evenFill',
        noneNo: true,
      },
      openYnOptions: [
        { text: '전체', value: '전체' },
        { text: '운영', value: 'Y' },
        { text: '미운영', value: 'N' },
      ],
      input: {
        open_yn: 'Y',
      },
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    newPartner() {
      this.$emit('newPartner');
    },
    valid() {
      return this.$refs.partnerMaster.validate();
    },
    loadData(v) {
      this.search(v);
    },
    searchBtn(v) {
      this.param.search_yn = 'Y';
      this.param.open_yn = this.input.open_yn == '전체' ? '' : this.input.open_yn;
      this.search(v);
    },
    async search(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await partnerMasterList({
          ...this.param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = true;
        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        // _.each(items, function (v) {
        //   v.work = v.employee_status;
        // });
        this.$refs.grid.loadData(items);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    filterAction() {
      this.settings.filterAction = !this.settings.filterAction;
      this.$refs.grid.setFilterPanel(this.settings.filterAction);
    },
    excelDownload() {
      const data = this.$refs.grid.getCheckedRowExecl(this.settings.columns);
      if (data.length > 0) {
        getExcel(data, '고객관리');
      } else {
        this.openPopup('엑셀 다운로드할 행을 선택해주세요');
      }
    },
    addPartner() {
      this.$emit('partnerAdd');
    },
    dbClick(v) {
      this.$emit('partnerMasterDetail', v);
    },
    reset() {
      this.param = {
        pt_cd: '',
        pt_nm: '',
        rep_name: '',
        phone: '',
        open_yn: '',
        search_yn: '',
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
      };
      this.input.open_yn = '전체';
      this.items = [];
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    resizeGrid() {
      const totalHeight = window.innerHeight; // 현재 윈도우의 내부 높이
      const targetGrid = document.querySelector(`div#${this.grid}`);
      let dynamicHeight = 360;
      if (this.showSearchOptions) {
        dynamicHeight += 70;
      }
      targetGrid.style.height = `${totalHeight - dynamicHeight}px`; // 그리드 컨테이너의 높이 조정
    },
  },
  components: {
    RealGrid,
    SetPopup,
  },
  mounted() {
    this.loadData();
    this.resizeGrid(); // 초기 로드 시 그리드 크기 조절
    window.addEventListener('resize', this.resizeGrid); // 윈도우 크기 변경 시 그리드 크기 조절
  },
};
</script>
<style lang="scss">
.sampleMaster {
  height: 80px;
  padding-left: 10px;
}
</style>
