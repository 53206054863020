<template>
  <div class="address adminsample">
    <SetPopup ref="confirm" />
    <Address ref="address" @select="onAddress" />
    <SetDialog ref="postModalPartner">
      <PartnerSearch :searchRequest="this.searchRequest" @closeModalPartner="this.closePostPartner" @onApprove="this.approvePartner" :key="approvePartnerKey" ref="approvePartner"></PartnerSearch>
    </SetDialog>
    <SetDialog ref="postModalAddressPT">
      <AddressPTSearch :searchRequestPT="this.searchRequestPT" @closeModalAddressPT="this.closeAddressPT" @onApprove="this.approveAddressPT" :key="approveAddressPTKey" ref="approveAddressPT"></AddressPTSearch>
    </SetDialog>

    <div class="filter-box filter-box--row">
      <ul class="filter-list add-btn-filter-list">
        <li>
          <h4>파일 업로드</h4>
          <div class="dp--row justify-content-between">
            <div class="dp--row add-btn">
              <v-text-field placeholder="파일을 선택해주세요" type="text" v-model="file.name" outlined dense disabled filled />
              <input class="d-none" ref="uploader" type="file" @change="onFileChanged" />
              <v-btn depressed class="ml-2 mr-1 btn-choice-file" @click="upload" :loading="isSelecting">파일선택</v-btn>
              <v-btn depressed class="btn-sample" @click="downloadSample">템플릿 다운</v-btn>
            </div>
          </div>
          <v-btn depressed class="mr-1 btn-load" id="one_speed_add" @click="addGridRow"><span class="text">+</span> 빠른 등록</v-btn>
        </li>
      </ul>
    </div>

    <RealGrid :domName="grid" ref="grid" :settings="settings" @click="checkRow" />
    <template v-if="checkRows">
      <v-form lazy-validation ref="newSample">
        <!-- <h3 class="mb-2">추가 정보</h3> -->
        <!-- <hr class="mb-4" /> -->
        <div class="filter-box--row mt-6">
          <div class="filter-box">
            <ul class="filter-list02">
              <li>
                <h4>요청업체명*</h4>
                <!-- <v-text-field ref="request_company" outlined dense v-model="param.request_company" :rules="[this.validSet.empty(param.request_company, '요청자업체명을 입력해주세요'), this.validSet.company]" append-icon="mdi-magnify" @keyup.enter="handleAction" @blur="handleAction" @click:append="handleAction"></v-text-field> -->
                <v-text-field ref="request_company" 
                  outlined dense v-model="param.request_company" 
                  :class="{'invalid-input': isIvd_rq_company}"
                  :rules="[this.validSet.empty(param.request_company, ''), this.validSet.company]" 
                  append-icon="mdi-magnify" 
                  @keyup.enter="handleAction" 
                  @blur="handleAction" 
                  @click:append="handleAction">
              </v-text-field>
              </li>
              <li>
                <h4>요청자*</h4>
                <!-- <v-text-field ref="request_name" outlined dense v-model="param.request_name" placeholder="" :rules="[this.validSet.empty(param.request_name, '요청자 이름을 입력해주세요'), this.validSet.name]"></v-text-field> -->
                <v-text-field ref="request_name" 
                  dense
                  outlined
                  v-model="param.request_name"       
                  placeholder="" 
                  :class="{'invalid-input': isIvd_rq_name}"
                  :rules="[this.validSet.empty(param.request_name, ''), this.validSet.name]"
                  
                  >
                </v-text-field>              </li>
              <li>
                <h4>요청자 연락처*<span style="color: blue; font-weight: bold">(- 포함입력)</span></h4>
                <v-text-field 
                  ref="phone1" 
                  outlined 
                  dense 
                  v-model="param.phone1" 
                  placeholder="- 를 포함 입력" 
                  :class="{'invalid-input': isIvd_rq_phone1}"
                  :rules="[this.validSet.phoneRules(true, param.phone1)]" 
                  minlength="11" maxlength="13"></v-text-field
                  >
              </li>
            </ul>
            <ul class="filter-list04" style="margin-top: 30px">
              <li>
                <h4>수령업체명*</h4>
                <div class="dp--row">
                  <div class="" style="height: 25px; margin-right: 10px">
                    <v-checkbox v-model="param.same2">
                      <template v-slot:label>
                        <h5>요청업체명과 동일</h5>
                      </template></v-checkbox
                    >
                  </div>

                  <v-text-field 
                    ref="pick_company" 
                    outlined 
                    dense 
                    placeholder="" 
                    :class="{'invalid-input': isIvd_rq_pick_company}"
                    :rules="[this.validSet.empty(param.pick_company, ''), this.validSet.company]" 
                    v-model="param.pick_company">
                  </v-text-field>
                </div>
              </li>
              <li>
                <h4>수령자*</h4>
                <div class="dp--row">
                  <div class="" style="height: 25px; margin-right: 10px">
                    <v-checkbox v-model="param.same">
                      <template v-slot:label>
                        <h5>요청자와 동일</h5>
                      </template></v-checkbox
                    >
                  </div>
                  <!-- <v-text-field ref="pick_name" outlined dense placeholder="" v-model="param.pick_name" :rules="[this.validSet.empty(param.pick_name, '수령자 이름을 입력해주세요'), this.validSet.name]"></v-text-field> -->
                  <v-text-field ref="pick_name" outlined dense placeholder="" v-model="param.pick_name" 
                    :class="{'invalid-input': isIvd_rq_pick_name}"
                    :rules="[this.validSet.empty(param.pick_name, ''), this.validSet.name]"></v-text-field>
                </div>
              </li>
            </ul>
            <ul class="filter-list04" style="margin-top: 30px">
              <li>
                <h4>수령자 연락처<span style="color: blue; font-weight: bold">(- 포함입력)</span></h4>
                <div class="dp--row">
                  <!-- <v-text-field ref="phone2" outlined dense v-model="param.phone2" placeholder="- 를 포함 입력" :rules="[this.validSet.phoneRules(false, param.phone2)]" minlength="11" maxlength="13"></v-text-field> -->
                  <v-text-field ref="phone2" outlined dense v-model="param.phone2" placeholder="- 를 포함 입력" minlength="11" maxlength="13"></v-text-field>
                </div>
              </li>
              <li>
                <h4>요청자 이메일</h4>
                <v-text-field 
                  ref="memberId" 
                  outlined 
                  dense 
                  v-model="param.memberId" placeholder="" 
                  :rules="[this.validSet.email]"></v-text-field>
              </li>
            </ul>
            <ul class="filter-list_address">
              <li class="dp--collums" style="margin-top: 30px">
                <h4>주소 (배송지 선택)*</h4>
                <div class="dp--row" style="width: 100%; align-items: flex-start; gap: 0">
                  <!-- <v-text-field ref="refAddress" placeholder="" type="text" outlined dense disabled filled v-model="param.address" :rules="[this.validSet.empty(param.address, '주소를 입력해주세요')]" /> -->
                  <v-text-field ref="refAddress" placeholder="" type="text" 
                    outlined dense disabled filled 
                    v-model="param.address" 
                    :class="{'invalid-input': isIvd_rq_address}"
                    :rules="[this.validSet.empty(param.address, '')]" />
                  <v-btn depressed class="ml-1 btn-address-search" @click="searchAddress">주소검색</v-btn>
                  <v-btn depressed class="ml-1 btn-address-search btn-sample-request" 
                  @click="searchAddressPT">배송지 검색</v-btn>
                </div>
              </li>
              <li style="margin-top: 10px">
                <h4 class="">상세주소</h4> 
                <!-- 필수값 해제 -->
                <!-- <v-text-field ref="refAddress2" placeholder="" type="text" outlined dense v-model="param.address2" :rules="[this.validSet.empty(param.address2, '상세 주소를 입력해주세요')]" /> -->
                <v-text-field ref="refAddress2" placeholder="" type="text" outlined dense v-model="param.address2"/>
              </li>
            </ul>
          </div>
          <div class="filter-box">
            <ul class="filter-list02">
              <li>
                <h4>요청 자재코드</h4>
                <v-text-field ref="request_code" outlined dense placeholder="" v-model="param.request_code"></v-text-field>
              </li>
              <li>
                <h4>Qty(kg)*</h4>
                <!-- <v-text-field ref="qty" outlined dense placeholder="" v-model="param.qty" :rules="[this.validSet.empty(param.qty, 'Qty를 입력해주세요'), this.validSet.numberDot]"></v-text-field> -->
                <v-text-field ref="qty" outlined dense placeholder="" v-model="param.qty" 
                  :class="{'invalid-input': isIvd_rq_qty}"
                  :rules="[this.validSet.empty(param.qty, ''), this.validSet.numberDot]"></v-text-field>
              </li>
              <li>
                <h4>유무상</h4>
                <v-select ref="price_type" :items="code.P" v-model="param.price_type" placeholder="선택해주세요" outlined id="work"></v-select>
              </li>
              <li>
                <h4>납기일*</h4>
                <v-menu v-model="picker" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field ref="delivery_date" 
                    :class="{'invalid-input': isIvd_rq_delivery_date}"
                    v-model="param.delivery_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker ref="delivery_date" no-title @input="menu = false" v-model="param.delivery_date"></v-date-picker>
                </v-menu>
              </li>
              <li>
                <h4>배송방법</h4>
                <v-select ref="delivery_type" :items="code.D" v-model="param.delivery_type" placeholder="선택해주세요" outlined id="work"></v-select>
              </li>
              <li>
                <h4>포장용기</h4>
                <v-select ref="packing" :items="code.C" v-model="param.packing" placeholder="선택해주세요" outlined id="work"></v-select>
              </li>
            </ul>
            <ul class="filter-list_address">
              <li style="margin-top: 30px">
                <h4>분석 요청사항</h4>
                <v-text-field ref="analysis" outlined dense placeholder="" v-model="param.analysis"></v-text-field>
              </li>
              <li style="margin-top: 30px">
                <h4>기타 요청사항</h4>
                <v-textarea ref="etc" outlined rows="4" class="filter-textarea" dense placeholder="" v-model="param.etc"></v-textarea>
              </li>
            </ul>
          </div>
        </div>
      </v-form>
    </template>
    <div class="wrapper mb-2" v-if="checkRows">
      <div class="btn-box">
        <v-card-actions v-if="tester">
          <v-btn depressed @click="testData" class="btn-cancle">테스트 정보 자동입력</v-btn>
        </v-card-actions>
        <v-card-actions class="">
          <v-btn depressed @click="cancle" class="btn-cancle">취소</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed class="btn-sample-request" @click="request_promise">샘플 요청</v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<script>
import { getSample } from 'api/file';
import validSet from '@/assets/valid';
import { columns, fields, rows } from '@/assets/grid/sampleRequest';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
import SetDialog from '@/components/SetDialog';
import Address from '@/components/Address.vue';
import { mapMutations, mapState } from 'vuex';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { insertSample } from 'api/sample/sample';
import { filterExel, blankRow } from '@/assets/grid/gridUtill';
import AddressPTSearch from '@/components/AddressPTSearch.vue';
import PartnerSearch from '@/components/PartnerSearch.vue';
import moment from 'moment';
import { partnerMasterList } from 'api/partner/partner';
import { searchAddressPTList } from 'api/partnerAddress/partnerAddressPT';
import { nameRule, pwRegex, companyRegex, emailRegex, numberRegex, keyRegex, valueRegex, codeRegex, employRegex, decimalRegex } from '@/assets/regex';

export default {
  watch: {
    'param.same': function (v) {
      if (v) {
        this.param.pick_name = this.param.request_name;
      }
    },
    'param.same2': function (v) {
      if (v) {
        this.param.pick_company = this.param.request_company;
      }
    },
  },
  data() {
    return {
      signupPostKey: 8,
      approvePartnerKey: 9,
      approveAddressPTKey: 10,
      picker: false,
      grid: 'adminSample',
      isIvd_rq_company:false,
      isIvd_rq_name:false,
      isIvd_rq_phone1:false,
      isIvd_rq_pick_company:false,
      isIvd_rq_pick_name:false,
      isIvd_rq_address:false,
      isIvd_rq_qty:false,     
      isIvd_rq_delivery_date:false, 
      settings: {
        columns: _.map(
          _.filter(_.cloneDeep(columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            v.editable = true;
            return v;
          },
        ),
        fields,
        rows,
        height: 490,
        radio: false,
        firstClick: true, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
      },
      validSet,
      checkRows: false,
      codeSet: {},
      file: '',
      currentFileName: '',
      param: {
        pt_cd: '',
        default: 0,
        price_type: '',
        packing: '',
        phone1: '',
        phone2: '',
        etc: '',
        same: false,
        address: '',
        address2: '',
        qty: '',
        request_code: '',
        request_name: '',
        request_company: '',
        pick_name: '',
        memberId: '',
        analysis: '',
        delivery_type: '',
        delivery_date: '',
        postcode: '',
        ww: '',
        pick_company: '',
      },
      isSelecting: false,
      address: [
        {
          key: 'default',
          text: '기본배송지',
        },
        {
          key: 'other',
          text: '다른 배송지',
        },
      ],
      tmpResult: [],
      tester: false,
      searchRequest: {},
      searchRequestPT: {},
    };
  },
  computed: {
    ...mapState('common', ['code']),
    ...mapState('member', ['defaultAddress', 'userInfo']),
    otherAddress() {
      return !this.param.default;
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    checkRow(v) {
      if (v.clickData) {
        this.checkRows = true;
      }
    },
    onAddress() {},

    valid() {
      return this.$refs.newSample.validate();
    },
    newSample() {
      this.$emit('newSample');
    },
    upload() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );
      this.$refs.uploader.click();
    },
    
    onFileChanged(e) {
      const input = e.target;
      this.file = input.files[0];
      if (this.file == '') {
        this.tmpResult = [];
        that.read();
        return false;
      }
      if (this.file.name === this.currentFileName) {
        return false;
      }
      const that = this;
      this.currentFileName = this.file.name;
      let reader = new FileReader();
      reader.onload = function () {
        let fileData = reader.result;
        let wb = XLSX.read(fileData, { type: 'binary' });
        wb.SheetNames.forEach(sheetName => {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { range: 1 });
          that.tmpResult = rowObj;
          if (Array.isArray(that.tmpResult) && that.tmpResult.length !== 0) {
            that.read();
          }
        });
      };
      reader.readAsArrayBuffer(input.files[0]);
    },
    read() {
      const rows = _.cloneDeep(this.tmpResult);
      let rowsForGrid = this.makeRowForm(rows);
      this.settings.firstClick = true;
      if (rows.length === 0) {
        this.openPopup('엑셀 파일을 선택해 주세요.');
        return false;
      }
      this.$refs.grid.loadData(rowsForGrid);
    },
    makeRowForm(rows) {
      let rowsForGrid = [];
      _.forEach(rows, row => {
        row = filterExel(row);
        rowsForGrid.push(row);
      });
      return rowsForGrid;
    },
    addressBook() {
      this.$refs.address.open();
    },
    cancle() {
      this.openConfirm('취소하시겠습니까', true, () => {
        this.reset();
      });
    },
    // 빠른 등록 버튼 클릭때마다 적용.
    addGridRow() {
      let blankRows = blankRow({});
      this.$refs.grid.addRow(blankRows);
    },
    async request_promise() {
      let insertAfterRemoveRows = [];
      const row = this.$refs.grid.getCheckedRow();

      if (row.length === 0) {
        this.openPopup('조성항목을 선택해 주세요');
        return false;
      }
      
      if (this.param.request_company === "") {
        this.isIvd_rq_company = true;
      } else {
        this.isIvd_rq_company = false;
      }

      if (this.param.request_name === "" || !nameRule.test(this.param.request_name) ) {
        this.isIvd_rq_name = true;
      } else {
        this.isIvd_rq_name = false;
      }

      const pattern = /^\d{2,3}-\d{3,4}-\d{4}$/; // 수동 입력 시 체크.
      
      if (this.param.phone1 === "" || !pattern.test(this.param.phone1)) {
        this.isIvd_rq_phone1 = true;
      } else {
        this.isIvd_rq_phone1 = false;
      }
      
      if (this.param.pick_company === "") {
        this.isIvd_rq_pick_company = true;
      } else {
        this.isIvd_rq_pick_company = false;
      }

      if (this.param.pick_name === "" || !nameRule.test(this.param.pick_name)) {
        this.isIvd_rq_pick_name = true;
      } else {
        this.isIvd_rq_pick_name = false;
      }

      if (this.param.address === "") {
        this.isIvd_rq_address = true;
      } else {
        this.isIvd_rq_address = false;
      }

      if (this.param.qty === "" || !numberRegex.test(this.param.qty) ) {
        this.isIvd_rq_qty = true;
      } else {
        this.isIvd_rq_qty = false;
      }

      if (this.param.delivery_date === "") {
        this.isIvd_rq_delivery_date = true;
      } else {
        this.isIvd_rq_delivery_date = false;
      }

      const validated = this.valid();
      if (!validated) {
        this.openPopup('필수 항목을 입력해 주세요');
        return false;
      }

      if (this.param.pt_cd === "") {
        this.isIvd_rq_company = true;
        this.openPopup('요청업체를 검색 후 선택해 주세요');
        return false;
      }

      if (row.length > 0 && validated) {
        // 선택한 row 개수 만큼 서버 저장을 처리해야한다.
        for (const v of row) {
          const data = {
            ...this.param,
            ...v.data,
          };

          try {
            const res = await insertSample(data);
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              this.openConfirm(body.errorMessage);
              this.reset();
              return false;
            }
            insertAfterRemoveRows.push(v.idx);
          } catch (error) {
            console.error('Error during request:', error);
          }
        }
        // 서버 요청이 완료된 후 실행할 코드
        this.openPopup(`${insertAfterRemoveRows.length}개를 저장하였습니다.`);
        this.$refs.grid.removeItemsById(insertAfterRemoveRows);

        if (this.$refs.grid.getJsonRows().length == 0) {
          this.reset();
          this.resetFileInput();
          this.$refs.grid.unsetGrid(true);
          this.settings.firstClick = true;
        } else {
          this.$refs.grid.unsetGrid(false);
          this.resetUserData();
        }
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    initGrid() {
      this.settings = {
        columns: _.map(
          _.filter(_.cloneDeep(columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            v.editable = true;
            return v;
          },
        ),
        fields,
        rows,
        height: 490,
        radio: false, // true -> radio, false -> checkbox
        firstClick: true, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
      };
    },
    resetFileInput() {
      this.file = ''; // file.name 초기화
      this.currentFileName = '';
      this.$refs.uploader.value = ''; // 파일 input 초기화
      this.tmpResult = [];
    },
    downloadSample() {
      this.openConfirm('샘플을 다운로드 하시겠습니까', true, getSample);
    },
    openConfirm(message, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text: message,
        closable: closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.address = data.address;
          this.param.address2 = data.address2;
          this.param.postcode = data.zonecode;
        },
      }).open();
    },
    closeModal() {},
    closeModalPartner() {},
    closeModalAddressPT() {},
    handleAction() {
      this.partnerSearchServer();
    },
    partnerSearchServer() {
      // if (this.param.request_company != '') {

      const searchRequest = {
        pt_cd: '',
        pt_nm: this.param.request_company,
        rep_name: '',
        open_yn: 'Y',
        search_yn: 'Y',
        currentPage: 1,
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
      };
      
      partnerMasterList(searchRequest)
        .then(res => {
          const response = res.data;
          const items = response.data.items;
          const page = response.data.params;

          if (items && items.length == 1) {
            this.selectionRow(items[0]);
            return false;
          } else {
            this.searchRequest = searchRequest;
            this.$nextTick(() => {
              if (this.$refs.approvePartner && this.$refs.approvePartner.partnerSearchRequest) {
                this.$refs.approvePartner.partnerSearchRequest(searchRequest);
              } else {
                console.error('PartnerSearch component did not mount successfully.');
              }
            });
            this.openPartner();
          }
        })
        .catch(err => {
          console.log('Error : ', err);
        })
        .finally();
      // }
    },
    selectionRow(v) {
      // this.selectPartnerTemp = `${v.pt_cd}, ${v.pt_nm}, ${v.rep_name}`;
      this.param.pt_cd = v.pt_cd;
      this.param.request_company = v.pt_nm;
      this.param.pick_name = v.rep_name;
      this.param.phone2 = v.phone;
      this.param.address = v.address1;
      this.param.address2 = v.address2;
      this.param.postcode = v.postcode;
    },
    openPartner() {
      this.SET_MODAL({
        // height: 600,
        width: 650,
        closable: true,
        customApprove: true,
      });
      this.$refs.postModalPartner.openModal();
    },

    searchAddressPT() {

      if (this.param.pt_cd === "") {
        this.isIvd_rq_company = true;
        this.openPopup('요청업체를 먼저 선택해 주세요');
        return false;
      };

      const searchRequestPT = {
        ptCd: this.param.pt_cd,
        name: '',
        pickname: '',
        mobile: '',
        postcode: '',
        address1: '',
        address2: '',
        open_yn: 'Y',
        search_yn: 'Y',
        currentPage: 1,
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
      };

      searchAddressPTList(searchRequestPT)
        .then(res => {
          const response = res.data;
          const items = response.data.items;
          const page = response.data.params;
          if (items && items.length !== 0) {
            this.searchRequestPT = searchRequestPT;
            this.$nextTick(() => {
              if (this.$refs.approveAddressPT && this.$refs.approveAddressPT.AddressPTReques) {
                this.$refs.approveAddressPT.AddressPTReques(searchRequestPT)                
              } else {
                console.error('AddressPT component did not mount successfully.');
              }
            });

            this.openAddressPT();
          }
        })
        .catch(err => {
          console.log('Error : ', err);
        })
        .finally();
    },

    openAddressPT(){
      this.SET_MODAL({
        // height: 600,
        width: 800,
        closable: true,
        customApprove: true,
      });

      this.$refs.postModalAddressPT.openModal();
    },
    closePostPartner() {
      this.$refs.postModalPartner.closeModal();
      this.approvePartnerKey = moment().valueOf();
    },
    closeAddressPT() {
      this.$refs.postModalAddressPT.closeModal();
      this.approveAddressPTKey = moment().valueOf();
    },
    approvePartner(partner) {
      if (partner.pt_cd != '') {
        this.param.pt_cd = partner.pt_cd;
        this.param.request_company = partner.pt_nm;
        this.param.pick_name = partner.pick_name;
        this.param.phone2 = partner.phone;
        this.param.postcode = partner.postcode;
        this.param.address = partner.address1;
        this.param.address2 = partner.address2;
      }
    },
    approveAddressPT(address) {
      if (address) {
        this.param.pick_name = address.pickname;
        this.param.phone1 = address.mobile;
        this.param.postcode = address.postcode;
        this.param.address = address.address1;
        this.param.address2 = address.address2;
      }
    },

    reset() {
      this.resetUserData();
      this.file = '';
      this.checkRows = false;
    },
    resetUserData() {
      this.param = {
        pt_cd: '',
        default: 0,
        price_type: '',
        packing: '',
        etc: '',
        same: false,
        address: '',
        address2: '',
        qty: '',
        request_code: '',
        request_name: '',
        request_company: '',
        pick_name: '',
        memberId: '',
        analysis: '',
        delivery_type: '',
        delivery_date: '',
        postcode: '',
        ww: '',
        pick_company: '',
      };

      //
      // 여기서는 validate 하는 것을 막아야 함
      //
      // this.$nextTick(() => {
      if (this.$refs.price_type) {
        this.$refs.price_type.resetValidation();
      }
      if (this.$refs.packing) {
        this.$refs.packing.resetValidation();
      }
      if (this.$refs.etc) {
        this.$refs.etc.resetValidation();
      }
      if (this.$refs.qty) {
        this.$refs.qty.resetValidation();
      }
      if (this.$refs.request_code) {
        this.$refs.request_code.resetValidation();
      }
      if (this.$refs.request_company) {
        this.$refs.request_company.resetValidation();
      }
      if (this.$refs.request_name) {
        this.$refs.request_name.resetValidation();
      }
      if (this.$refs.phone1) {
        this.$refs.phone1.resetValidation();
      }
      if (this.$refs.phone2) {
        this.$refs.phone2.resetValidation();
      }
      if (this.$refs.pick_name) {
        this.$refs.pick_name.resetValidation();
      }
      if (this.$refs.analysis) {
        this.$refs.analysis.resetValidation();
      }
      if (this.$refs.delivery_date) {
        this.$refs.delivery_date.resetValidation();
      }
      if (this.$refs.postcode) {
        this.$refs.postcode.resetValidation();
      }
      if (this.$refs.ww) {
        this.$refs.ww.resetValidation();
      }
      if (this.$refs.memberId) {
        this.$refs.memberId.resetValidation();
      }
      if (this.$refs.refAddress) {
        this.$refs.refAddress.resetValidation();
      }
      if (this.$refs.refAddress2) {
        this.$refs.refAddress2.resetValidation();
      }
    },
    // 반드시 테스트에서만 사용하는 코드임.
    testData() {
      let nm = this.generateTestNumber();
      this.param = {
        pt_cd: '',
        default: 0,
        price_type: '유상',
        packing: 'AI',
        etc: `기타요청_${nm}`,
        same: false,
        address: '충남 천안시 서북구 1공단2길 7',
        address2: '상세주소 1',
        qty: '10',
        request_code: `자재코드_${nm}`,
        request_name: '요청자',
        pick_name: '수령자',
        analysis: `분석요청_${nm}`,
        delivery_date: '2024-07-25',
        postcode: '12345',
        request_company: `업체명_${nm}`,
        pick_company: `수령업체_${nm}`,
        phone1: '010-1234-5678',
        phone2: '02-5678-1234',
        ww: '',
        memberId: 'kconfine@gmail.com',
        delivery_type: '직납',
      };
    },
    // 반드시 테스트에서만 사용하는 코드임.
    generateTestNumber() {
      let d = new Date();
      return d.getMonth() + 1 + '_' + d.getDate() + '_' + d.getHours() + '_' + d.getMinutes();
    },
    request() {
      const row = this.$refs.grid.getCheckedRow();
      if (row.length > 0 && this.valid()) {
        _.each(row, v => {
          const data = {
            ...this.param,
            ...v,
          };
          insertSample(data).then(res => {
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              this.openConfirm(body.errorMessage);
              this.reset();
            } else {
              this.openConfirm(body.message, false, () => {
                const idx = this.$refs.grid.getCheckedRowIdxRadio();
                const before = this.$refs.grid.getJsonRows();
                const updateRow = _.filter(before, (v, index) => {
                  return index !== idx;
                });
                this.$refs.grid.loadData(updateRow);
                this.$refs.grid.setColor();
                this.reset();
              });
            }
          });
        });
      }
    },
  },
  components: {
    RealGrid,
    Address,
    SetPopup,
    SetDialog,
    PartnerSearch,
    AddressPTSearch,
  },
  mounted() {
    this.reset();
    this.tester = this.userInfo.memberId == 'kconfine@gmail.com' ? true : false;

    // 추가된 부분: PartnerSearch가 제대로 마운트되었는지 확인
    this.$nextTick(() => {
      if (this.$refs.approvePartner && this.$refs.approvePartner.partnerSearchRequest) {
        console.log('PartnerSearch component is mounted successfully.');
      } else {
        console.error('PartnerSearch component did not mount successfully.');
      }
    });
  },
};
</script>
<style lang="scss">
.aLink {
  color: blue !important;
  text-decoration: underline;
}
.address .v-input__slot {
  width: 100%;
}
.address .row {
  height: 100px;
}
.adminsample {
  .v-input--selection-controls__input {
    margin-right: 0px;
  }
}
.add-btn-filter-list {
  display: flex !important;
  width: 100% !important;
  li {
    position: relative;
    width: 100% !important;
  }
}
.justify-content-between {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
}
.add-btn {
  justify-content: space-between !important;
}
#one_speed_add {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  padding: 8px 20px !important;
  border-radius: 5px !important;
  border: 1px solid #01aa01 !important;
  background-color: #01aa01 !important;
  font-size: 1rem !important;
  span.text {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 5px;
  }
}
.search-button {
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: 8px;
  margin-left: 8px;
}
.invalid-input {
  border: 1px solid red !important;  // 테두리 색상을 빨간색으로 설정
  border-radius: 6px !important;
}

.valid-input {
  border: 0.5px solid black !important;  // 테두리 색상을 빨간색으로 설정  
}


</style>
