import { ValueType } from 'realgrid';

export const fields = [
  {
    fieldName: 'memberidx',
    dataType: ValueType.INT,
  },
  {
    fieldName: 'roles',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'member_name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'phone',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'employee_code',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'work',
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'memberidx',
    fieldName: 'memberidx',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '번호',
      showTooltip: true,
      tooltip: '<span style="color: red;">번호</span>',
    },
    renderer: {
      type: 'text',
      showTooltip: true,
    },
    editable: false,
  },
  {
    name: 'roles',
    fieldName: 'roles',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '계정 구분',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'member_name',
    fieldName: 'member_name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: '이름',
    editable: false,
  },
  {
    name: 'phone',
    fieldName: 'phone',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '휴대폰 번호',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'employee_code',
    fieldName: 'employee_code',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '사번',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'work',
    fieldName: 'work',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '재직',
      showTooltip: false,
    },
    editable: false,
  },
];

export const rows = [];
export const height = 350;
