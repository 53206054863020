<template>
  <div>
    <SetPopup ref="confirm" />
    <!-- <h3 class="mb-2">샘플 마스터 상세</h3> -->
    <div class="confirmSample wrapperSpace filter-box filter-box__row">
      <ul class="filter-list_free">
        <li style="width: 200px">
          <h4>Code Grade</h4>
          <v-text-field v-model="param.code" outlined dense filled disabled></v-text-field>
        </li>
        <li style="width: 150px">
          <h4>w/w</h4>
          <!-- <v-select
            :v-model="param.ww"
            outlined
            dense
            :items="selectWw"    
            item-text="name"
            item-value="value" 
          >
          </v-select> -->
          <!-- <v-select
            v-model="param.ww"
            outlined
            dense
            :items="options"
            item-text="text"
            item-value="value"
          /> -->
          <v-select v-model="param.ww" :items="options" outlined dense no-outline hide-details />
          <!-- <select v-model="param.ww" style="
              padding: 10px;
              border: 1px solid #ccc;
              height: 36px;
              outline: none;
              font-size: 13px;
              width: 150px;
            ">
            <option v-for="option in options" :key="option.value" :value="option.value">
              {{option.text}} {{ option.value}} {{param.ww}}
            </option>
          </select> -->
        </li>
        <li style="width: 700px">
          <h4>품명</h4>
          <v-text-field v-model="param.code_title" outlined dense placeholder=""></v-text-field>
        </li>
        <li style="width: 200px">
          <h4>양산자재 코드</h4>
          <v-text-field v-model="param.mass_code" outlined dense placeholder=""></v-text-field>
        </li>
        <li style="width: 200px">
          <h4>제품자재 코드</h4>
          <v-text-field v-model="param.product_code" outlined dense placeholder=""></v-text-field>
        </li>
      </ul>

      <!-- <v-col cols="12" sm="2">
        <h4>Code Grade</h4>
        <v-text-field
          v-model="param.code"
          outlined
          dense
          filled
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <h4>w/w</h4>
        <v-text-field
          v-model="param.wwval"
          outlined
          dense
          filled
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="8">
        <h4>품명</h4>
        <v-text-field
          v-model="param.code_title"
          outlined
          dense
          placeholder="품명을 입력해 주세요."
        ></v-text-field>
      </v-col> -->
    </div>
    <h3 class="mt-4">조성</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">조성</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid :oneLineGrid="true" domName="settings_sample_detail" ref="sample_grid" :settings="settings_sample" :nonePage="true" @changeData="addSpec" />
    <h3 class="mt-4">실제조성</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">실제조성</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid :oneLineGrid="true" domName="settings_real_detail" ref="real_grid" :settings="settings_real" :nonePage="true" @changeData="changeDataReal" />
    <h3 class="mt-4">제조조성</h3>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">제조조성</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid :oneLineGrid="true" domName="settings_make_detail" ref="make_grid" :settings="settings_make" :nonePage="true" @changeData="changeDataMake" />
    <h3 class="mt-2 dp--row">세부스팩</h3>
    <!-- <h3 class="mt-8 mb-2 pl-1 pr-1">
      <div class="wrapperSpace">
        세부 스펙
        <div>
          <v-btn depressed @click="specAdd">추가</v-btn>
          <v-btn depressed color="primary" @click="specDel">삭제</v-btn>
        </div>
      </div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid domName="settings_real_spec_detail" ref="real_spec_grid" :settings="real_settings_spec" :nonePage="true" :style="realSpecCheck ? '' : 'display: none'" />
    <h3 class="mt-2 dp--row">
      <div class="mb-2 dp--row btn-box">
        <v-btn depressed class="btn-add" @click="specAdd">추가</v-btn>
        <v-btn depressed class="btn-delete" @click="specDel">삭제</v-btn>
      </div>
    </h3>
    <RealGrid domName="settings_spec_detail" ref="spec_grid" :settings="settings_spec" :nonePage="true" />
    <h3 class="mt-2 dp--row">비고</h3>
    <v-textarea outlined rows="6" class="filter-textarea" dense placeholder="" v-model="param.etc" maxlength="999" @input="cntPlus"></v-textarea>
    <h1 v-if="cntCheck" class="text-red">{{ this.message }}</h1>
    <div class="wrapper">
      <div class="btn-box mt-2 mb-2">
        <v-btn depressed class="btn-cancle" @click="cancle">취소</v-btn>
        <v-btn depressed class="btn-save" @click="save">저장</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import * as sample from '@/assets/grid/sampleRequest';
import * as sampleSum from '@/assets/grid/sampleRequestSumWt';
import * as spec from '@/assets/grid/spec';
import * as spec2 from '@/assets/grid/spec2';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
import { sampleMasterDetail, updateSampleMaster } from 'api/sample/sample';
import { mapMutations } from 'vuex';
import { makeSum, makeSampleSet, setNewSum, makeARow } from '@/assets/grid/gridUtill';
// import { makeARow } from "@/assets/grid/gridUtill";
import _ from 'lodash';
import { scrollToTop } from '@/utils/common_utils';
export default {
  props: ['data'],
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      id: '',
      cnt: 0,
      message: '최대 1,000자 까지 입력 가능합니다.',
      cntCheck: false,
      realSpecCheck: false,
      key: {
        solvent1: 'solventVol1',
        solvent2: 'solventVol2',
        solvent3: 'solventVol3',
        solvent4: 'solventVol4',
        solvent5: 'solventVol5',
        solvent6: 'solventVol6',
        solvent7: 'solventVol7',
        solvent8: 'solventVol8',
        salt1: 'saltVol1',
        salt2: 'saltVol2',
        salt3: 'saltVol3',
        add1: 'addVol1',
        add2: 'addVol2',
        add3: 'addVol3',
        add4: 'addVol4',
        add5: 'addVol5',
        add6: 'addVol6',
        add7: 'addVol7',
        add8: 'addVol8',
        add9: 'addVol9',
        add10: 'addVol10',
      },
      param: {
        code: '',
        code_title: '',
        ww: '',
        mass_code: '',
        product_code: '',
        etc: '',
      },
      options: [
        { text: 'Y', value: 1 },
        { text: 'N', value: 0 },
      ],
      grid: 'sampleMasterDetail',
      settings_sample: {
        ...sample,
        columns: _.map(
          _.filter(_.cloneDeep(sample.columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            v.editable = true;
            return v;
          },
        ),
        hideCheckBar: true,
        // height: 150,
        noneNo: true,
      },
      settings_real: {
        ...sampleSum,
        columns: _.map(_.cloneDeep(sampleSum.columns), function (v) {
          v.editable = true;
          return v;
        }),
        hideCheckBar: true,
        // height: 150,
        noneNo: true,
      },
      settings_make: {
        ...sampleSum,
        columns: _.map(_.cloneDeep(sampleSum.columns), function (v) {
          v.editable = true;
          return v;
        }),
        hideCheckBar: true,
        // height: 150,
        noneNo: true,
      },
      real_settings_spec: {
        ...spec,
        columns: _.map(_.cloneDeep(spec2.columns), function (v) {
          if (v.name == 'low1' || v.name == 'hig1' || v.name == 'mid1') {
            v.editable = true;
          }
          return v;
        }),
        // height: 550,
        hideCheckBar: true,
        noneNo: true,
      },
      settings_spec: {
        ...spec,
        columns: _.map(_.cloneDeep(spec.columns), function (v) {
          v.editable = true;
          return v;
        }),
        // height: 550,
        noneNo: true,
      },
    };
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('member', ['SET_USER_INFO']),
    setModalAutoClose(msg, closable, cb, autoclose) {
      this.SET_POPUP({
        title: '조성 데이터가 누락되었습니다.',
        // height: 150,
        width: 350,
        closable: closable,
        text: msg,
      });
      this.$refs.confirm.openPopupAutoClose(cb, autoclose, 1800);
    },
    loadData() {
      var chk = false;
      if (this.param.code != this.data.code) {
        chk = true;
      }
      this.param.code = this.data.code;
      if (chk) {
        this.search(this.param.code);
      }
    },
    cntPlus() {
      this.cnt = this.param.etc.length;
      if (this.cnt >= 1000) {
        this.cntCheck = true;
      } else {
        this.cntCheck = false;
      }
    },
    ...mapMutations('popup', ['SET_POPUP']),
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    specAdd() {
      const row = this.$refs.spec_grid.getJsonRows();
      const add = makeARow(spec.fields);
      this.$refs.spec_grid.loadData(row.concat(add));
    },
    specDel() {
      const row = this.$refs.spec_grid.getJsonRows();
      const idx = this.$refs.spec_grid.getCheckedRowIdx();
      if (idx.length < 1) {
        this.openPopup('삭제할 행을 선택해주세요');
      } else {
        this.openPopup('삭제하시겠습니까', true, () => {
          const ret = _.filter(row, (v, index) => {
            return !idx.includes(index);
          });
          this.$refs.spec_grid.loadData(ret);
        });
      }
    },
    closePopup() {
      this.$refs.confirm.closePopup();
    },
    async search(code) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const res = await sampleMasterDetail(code);
        this.isDataLoaded = true;

        const response = res.data.data;
        const CodeDB = response.CodeDB;
        const CodeDB_A = response.CodeDB_A;
        const CodeDB_B = response.CodeDB_B;
        const CodeDB_Dt = response.CodeDB_Dt;
        let subCodeDB_Dt = response.subCodeDB_Dt;
        this.$refs.sample_grid.loadData([{ ...CodeDB, code }]);
        this.$refs.real_grid.loadData([{ ...makeSum(CodeDB_A), code }]);
        this.$refs.make_grid.loadData([{ ...makeSum(CodeDB_B), code }]);
        this.param.code_title = CodeDB.code_title;
        this.param.ww = CodeDB.ww;
        this.param.mass_code = CodeDB.mass_code;
        this.param.product_code = CodeDB.product_code;
        this.param.etc = CodeDB.etc;
        if (subCodeDB_Dt?.length > 0) {
          subCodeDB_Dt = subCodeDB_Dt.map(item => ({
            ...item,
            hig: item.hig === null || '' ? 0 : item.hig,
            low: item.low === null || '' ? 0 : item.low,
            mid: item.mid === null || '' ? 0 : item.mid,
            rv: item.rv === null || '' ? 0 : item.rv,
          }));
          this.$refs.real_spec_grid.loadData(subCodeDB_Dt);
          this.realSpecCheck = true;
        } else {
          this.$refs.real_spec_grid.loadData(CodeDB_A);
        }
        this.$refs.spec_grid.loadData(CodeDB_Dt);
        this.$refs.sample_grid.allRemoveUserCellStyle();
        this.$refs.real_grid.allRemoveUserCellStyle();
        this.$refs.make_grid.allRemoveUserCellStyle();
        scrollToTop();
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    cancle() {
      this.openPopup('취소 하시겠습니까?', true, () => {
        this.REMOVE_SELECT_MENU('sampleDetail');
        this.SET_SELECT_MENU_TAB_BY_CODE('SPMGMT');
      });
    },
    sampleGridColumnKeyValueChecked() {
      return this.$refs.sample_grid.checkKeyValuePairs(0, 1);
    },
    realGridColumnKeyValueChecked() {
      return this.$refs.real_grid.checkKeyValuePairs(0, 0);
    },
    makeGridColumnKeyValueChecked() {
      return this.$refs.make_grid.checkKeyValuePairs(0, 0);
    },
    save() {
      // 조성 데이터 셋트 유무 확인
      const sampleMissingColumns = this.sampleGridColumnKeyValueChecked();
      const sampleKeyCount = Object.keys(sampleMissingColumns['key']).length;
      const sampleValueCount = Object.keys(sampleMissingColumns['value']).length;

      const realMissingColumns = this.realGridColumnKeyValueChecked();
      const realKeyCount = Object.keys(realMissingColumns['key']).length;
      const realValueCount = Object.keys(realMissingColumns['value']).length;

      const makeMissingColumns = this.makeGridColumnKeyValueChecked();
      const makeKeyCount = Object.keys(makeMissingColumns['key']).length;
      const makeValueCount = Object.keys(makeMissingColumns['value']).length;
      if (sampleKeyCount > 0 || sampleValueCount > 0 || realKeyCount > 0 || realValueCount > 0 || makeKeyCount > 0 || makeValueCount > 0) {
        this.setModalAutoClose('성분명과 볼륨은 세트로 입력되야 합니다.<br>누락된 데이터를 입력해주세요.', false, null, true);
        scrollToTop();
        return;
      }

      if (this.cntCheck) {
        // 비고 1000자 넘으면 저장 안됨
        return;
      } else {
        this.openPopup('저장하시겠습니까?', true, () => {
          const code = this.param.code;
          const code_title = this.param.code_title;
          const ww = this.param.ww;
          const mass_code = this.param.mass_code;
          const product_code = this.param.product_code;
          const etc = this.param.etc;
          const memberId = this.id;
          let dt = this.$refs.spec_grid.getJsonAllRow();
          dt = dt.filter(item => item[0]); // spec명 없는경우 삭제
          const dt2 = this.$refs.real_spec_grid.getJsonAllRow();

          let dtSpecNames = dt.map(row => row[0]);
          let dt2SpecNames = dt2.map(row => row[0]);
          let duplicateFound = dtSpecNames.some(specName => dt2SpecNames.includes(specName)); // 중복 스펙명 확인

          const sum1 = this.$refs.real_grid.getJsonRow().sum;
          const sum2 = this.$refs.make_grid.getJsonRow().sum;
          if (duplicateFound) {
            this.openPopup('중복된 스펙이 있습니다');
          } else if (sum1 > 100 || sum2 > 100) {
            this.openPopup('SUM 정보를 확인해 주세요');
          } else {
            const sampleDetail = _.each(makeSampleSet(dt, null, this.id), v => {
              v.code = code;
            });
            const sampleDetail2 = _.each(makeSampleSet(dt2, null, this.id), v => {
              v.code = code;
            });
            // 여기서 합쳐도됨
            updateSampleMaster({
              sample: {
                ...this.$refs.sample_grid.getJsonRow(),
                code,
                code_title,
                ww,
                mass_code,
                product_code,
                etc,
                memberId,
              },
              sampleA: { ...this.$refs.real_grid.getJsonRow(), code, memberId },
              sampleB: { ...this.$refs.make_grid.getJsonRow(), code, memberId },
              sampleDetail,
              sampleDetail2,
              // eslint-disable-next-line no-unused-vars
            }).then(res => {
              this.closePopup();
              this.openPopup('저장되었습니다', false);
              // this.REMOVE_SELECT_MENU("sampleMasterDetail");
            });
          }
        });
      }
    },
    reset() {
      this.param = {
        solvent: '',
        solventVol: '',
        salt: '',
        saltVol: '',
        add: '',
        addVol: '',
        code: '',
        ww: '',
        mass_code: '',
        product_code: '',
        etc: '',
      };
    },
    setNewSum(ref) {
      const row = this.$refs[ref].getJsonRow();
      const sum = [setNewSum(row)];
      this.$refs[ref].loadData(sum);
    },
    changeDataReal() {
      const row = this.$refs.real_grid.getJsonRow();
      const row2 = this.$refs.real_spec_grid.getJsonAllRow();
      const key = _.without(_.keys(row), 'ww', 'lotNo', 'sum');
      const rowArr = [];
      let unit = '';
      let vol = '';
      _.each(key, v => {
        if (!v.includes('Vol')) {
          unit = 'wt%';
          vol = Number(row[this.key[v]]);
          if (isNaN(vol)) {
            vol = '';
          }
          const spec = row[v];
          if (!_.isEmpty(spec)) {
            const findRow = _.find(row2, row2Item => row2Item[0] === spec);
            const specData = findRow ? { low: findRow[3], mid: findRow[4], hig: findRow[5] } : { low: 0, mid: 0, hig: 0 };
            rowArr.push({ spec, unit, vol, ...specData });
          }
        }
      });
      this.$refs.real_spec_grid.loadData(rowArr);
      this.setNewSum('real_grid');
    },
    changeDataMake() {
      this.setNewSum('make_grid');
    },
    addSpec() {},
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.loadData();
    this.specBefore = this.$refs.spec_grid.getJsonRows();
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss">
.text-red {
  color: red;
  font-size: 16px;
}
</style>
