import _ from 'lodash';
import { makeCell } from './gridUtill';
let fields = [];
let columns = [];
const data = [
  {
    alias: 'Lot No',
    field: 'lotNo',
  },
  {
    alias: '요청자',
    field: 'request_name',
  },
  {
    alias: '요청업체명',
    field: 'request_company',
  },
  {
    alias: '수령자',
    field: 'pick_name',
  },
  {
    alias: '수령업체명',
    field: 'pick_company',
  },
  {
    alias: '요청일',
    field: 'reg_dt',
    date: 'yyyy-MM-dd',
  },
  {
    field: 'postcode',
    alias: '우편번호',
    editable: true,
  },
  {
    alias: '배송지',
    field: 'address',
    width: 300,
  },
  {
    field: 'address2',
    alias: '상세주소',
    width: 250,
  },
  {
    alias: '비고',
    field: 'etc',
  },
];

_.each(data, function (o) {
  o.indexExclusive = true;
  // o.field =? o.alias;
  makeCell(1, [o], fields, columns);
});
export { fields, columns };
