<template>
  <v-dialog :max-width="this.maxWidth" v-model="open" persistents>
    <v-form ref="form" lazy-validation>
      <v-card :style="'height:' + this.height + 'px; width:100%'" class="tableParent modal-contents">
        <v-row no-gutters>
          <v-col v-if="this.alertIconUsed" cols="auto" style="align-items: center; justify-content: center; display: flex">
            <img :src="this.alertIcon" class="alert-img" />
          </v-col>
          <v-col cols="auto">
            <v-card-title v-if="this.dialogTitle">{{ this.dialogTitle }}</v-card-title>
            <v-card-text v-if="this.dialogText">
              <p v-html="this.dialogText" :class="{ ft09rem: this.alertIconUsed }"></p>
            </v-card-text>
          </v-col>
        </v-row>
        <slot ref="slot"></slot>
        <div class="modal__btn-box" v-if="!this.customApprove">
          <div class="wrapper dp--row-end btn-box">
            <v-card-actions v-if="this.closable">
              <v-btn class="btn-reset" depressed @click="closePopup">취소</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed color="primary" @click="onApprove">{{ approveName }}</v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import alertIcon from '../../assets/img/alert.png';
export default {
  props: {
    slotProps: {
      type: Object,
    },
  },
  data() {
    return {
      paramProps: {},
      open: false,
      callback: '',
      autoclose: false,
      alertIconUsed: false,
      alertIcon,
    };
  },
  computed: {
    ...mapState('popup', ['param', 'height', 'dialogTitle', 'dialogText', 'maxWidth', 'closable', 'customApprove', 'approveName']),
  },
  methods: {
    ...mapMutations('popup', ['RESET_POPUP']),
    openPopup(cb) {
      this.open = true;
      this.callback = cb;
      this.alertIconUsed = false;
    },
    openPopupAutoClose(cb, autoclose = false, closetime = 1000) {
      this.autoclose = autoclose;
      this.open = true;
      this.callback = cb;
      this.alertIconUsed = true;
      if (this.autoclose) {
        const that = this;
        setTimeout(function () {
          that.closePopup();
        }, closetime);
      }
    },
    closePopup() {
      this.open = false;
      this.RESET_POPUP();
    },
    onApprove() {
      this.closePopup();
      if (_.isFunction(this.callback)) {
        this.callback();
      }
    },
  },
};
</script>
<style>
.approve span {
  color: white;
}

.tableParent {
  border-collapse: collapse;
  display: table;
}

.tableChild {
  display: table-row;
  vertical-align: bottom;
  height: 1px;
}
.alert-img {
  width: 50px;
  height: 50px;
}
.ft09rem {
  font-size: 0.9rem;
}
</style>
