<template>
  <div>
    <v-form ref="form" lazy-validation>
      <div class="service commonCode">
        <!-- <h3 class="mb-2">공통코드 관리</h3> -->
        <div class="filter-box filter-box--row">
          <ul class="filter-list02">
            <li>
              <h4>공통코드명</h4>
              <div class="wrapperEnd">
                <v-text-field outlined dense placeholder="" v-model="input.gubun" @keyup.enter="search"></v-text-field>
              </div>
            </li>
          </ul>
          <div class="btn-box">
            <v-card-actions>
              <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed class="btn-search" @click="search">검색</v-btn>
            </v-card-actions>
          </div>
        </div>
        <!-- <div class="filter">
          <v-row class="row px-4 py-3 sch wrapperSpace" style="height: 105px">
            <v-col cols="12" sm="3">
              <h4>공통코드명</h4>
              <div class="wrapperEnd">
                <v-text-field
                  outlined
                  dense
                  placeholder="공통코드명을 입력해 주세요."
                  v-model="input.gubun"
                ></v-text-field>
              </div>
            </v-col>
            <div class="wrapperTop pt-7">
              <v-card-actions>
                <v-btn depressed outlined @click="reset">초기화</v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn depressed color="primary" @click="search">검색</v-btn>
              </v-card-actions>
            </div>
          </v-row>
        </div> -->
        <!-- <h3 class="mt-4 mb-2">목록</h3> -->
        <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" />
      </div>
    </v-form>
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/codeMgn';
import { getCodeAll } from 'api/common';
import { mapMutations, mapState } from 'vuex';
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import _ from 'lodash';
export default {
  data() {
    return {
      input: {
        gubun: '',
        pageSize: 100000,
      },
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        hideCheckBar: true,
      },
      grid: 'codeMgn',
      validSet,
      currentPage: 1,
      saveParam: {},
    };
  },
  computed: {
    ...mapState('select', ['workType', 'roleType']),
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  mounted() {
    this.SET_POPUP({
      title: '알림',
      // height: 150,
      width: 300,
      customApprove: true,
    });
    this.loadData();
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    reset() {
      this.input = {
        gubun: '',
        pageSize: 100000,
      };
    },
    loadData(v) {
      this.search(v);
    },
    valid() {
      return this.$refs.form.validate();
    },
    search(v) {
      if (this.valid()) {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        getCodeAll({
          ...this.input,
          currentPage: this.currentPage,
        }).then(res => {
          const response = res.data;
          const items = response.data.items;
          const page = response.data.params;
          this.$refs.grid.loadData(items);
          this.$refs.grid.setPage(page);
        });
      }
    },
    dbClick(data) {
      this.$emit('dbClick', data);
    },
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss">
.commonCode {
  .v-text-field__details {
    display: none;
  }
}
</style>
