<template>
  <v-container fill-height fluid class="">
    <SetDialog ref="modal" />
    <div class="pa-4 pt-4 pb-0 full">
      <v-tabs :value="tab">
        <!-- 메인페이지 이동 링크 필요  -->
        <button class="btn_home" @click="home">
          <img src="@/assets/img/icon_home_g.png" alt="home" />
        </button>
        <v-tab v-for="item in this.selectMenu" :key="item.key" @click="tabChange(item)">
          <template v-if="locale === 'ko'">
            {{ item.menu }}
          </template>
          <template v-else-if="locale === 'en'">
            {{ item.menu_eng }}
          </template>
          <v-btn icon @click="removeTab(item.code)" class="ml-2"><v-icon x-small>mdi-close</v-icon></v-btn>
        </v-tab>
      </v-tabs>
      <v-tabs-items :value="tab" :style="'min-width:' + 100 + 'px;padding-top: 16px;'">
        <v-tab-item v-for="item in this.selectMenu" :key="item.code">
          <template v-if="item.code === 'IDMGMT'">
            <Accont ref="IDMGMT" />
          </template>
          <template v-if="item.code === 'PWMGMT'">
            <AccontPw ref="PWMGMT" />
          </template>
          <template v-if="item.code === 'LGMGMT'">
            <LoginMgn ref="LGMGMT" />
          </template>
          <template v-if="item.code === 'MUMGMT'">
            <MenuMgn ref="MUMGMT" />
          </template>
          <template v-if="item.code === 'CDMGMT'">
            <CodeMgn @dbClick="codeDetail" ref="CDMGMT" />
          </template>
          <template v-if="item.code === 'CDMGMTDT'">
            <CodeDetail :data="codeDetailData" ref="CDMGMTDT" />
          </template>
          <template v-if="item.code === 'LMMGMT'">
            <LangCode ref="LMMGMT" />
          </template>
          <template v-if="item.code === 'MBMGMT'">
            <UserMaster @dbClick="userDetail" ref="MBMGMT" />
          </template>
          <template v-if="item.code === 'userDetail'">
            <UserMasterDetail :data="userDetailData" ref="userDetail" />
          </template>
          <template v-if="item.code === 'SPMGMT'">
            <SampleMaster @sampleMasterDetail="sampleMasterDetail" @sampleAdd="sampleAdd" ref="SPMGMT" />
          </template>
          <template v-if="item.code === 'sampleDetail'">
            <SampleMasterDetail :data="sampleDetailData" ref="sampleDetail" />
          </template>
          <template v-if="item.code === 'sampleAdd'">
            <SampleAdd ref="sampleAdd" />
          </template>
          <template v-if="item.code === 'PTMGMT'">
            <PartnerMaster @partnerMasterDetail="partnerMasterDetail" @partnerAdd="partnerAdd" ref="PTMGMT" />
          </template>
          <template v-if="item.code === 'partnerDetail'">
            <PartnerMasterDetail :data="partnerDetailData" ref="partnerDetail" />
          </template>
          <template v-if="item.code === 'partnerAdd'">
            <PartnerAdd @partnerMasterDetail="partnerMasterDetail" ref="partnerAdd" />
          </template>
          <template v-if="item.code === 'SAMGMT'">
            <MenstruumMaster @dbClick="menstruumMasterDetail" @menstruumAdd="menstruumAdd" ref="SAMGMT" />
          </template>
          <template v-if="item.code === 'menstruumDetail'">
            <MenstruumDetail :data="menstruumDetailData" ref="menstruumDetail" />
          </template>
          <template v-if="item.code === 'menstruumAdd'">
            <MenstruumAdd ref="menstruumAdd" />
          </template>
          <template v-if="item.code === 'STMGMT'">
            <MaterialIndex @dbClick="materialIndexDetail" @materialIndexAdd="materialIndexAdd" ref="STMGMT" />
          </template>
          <template v-if="item.code === 'materialIndexDetail'">
            <MaterialIndexDetail :data="materialIndexDetailData" ref="materialIndexDetail" />
          </template>
          <template v-if="item.code === 'materialIndexAdd'">
            <MaterialIndexAdd ref="materialIndexAdd" />
          </template>
          <template v-if="item.code === 'COMGMT'">
            <COA @dbClick="coaDetail" ref="COMGMT" />
          </template>
          <template v-if="item.code === 'coaDetail'">
            <COADetail :data="coaDetailData" ref="coaDetail" />
          </template>
          <template v-if="item.code === 'MSMGMT'">
            <UserSample @newSample="newSample" ref="MSMGMT" @dbClick="openSearchProcessCustom" />
          </template>
          <template v-if="item.code === 'NUSMGMT'">
            <NewSample @newSample="newSample" ref="NUSMGMT" />
          </template>
          <template v-if="item.code === 'MAMGMT'">
            <AdminSample @newSample="newSample" ref="MAMGMT" />
          </template>
          <template v-if="item.code === 'SRMGMT'">
            <ConfirmSample @confirmDetail="confirmDetail" ref="SRMGMT" />
          </template>
          <template v-if="item.code === 'sampleRequestDetail'">
            <SampleRequestDetail ref="sampleRequestDetail" :data="sampleRequestDetailData" />
          </template>
          <template v-if="item.code === 'MMGMTM'">
            <SearchProcess ref="MMGMTM" />
          </template>
          <template v-if="item.code === 'MMGMT'">
            <SearchProcessCustom ref="MMGMT" />
          </template>
          <template v-if="item.code === 'OLMGMT'">
            <DeliveryReport ref="OLMGMT" @dbClick="delveryReportDetail" />
          </template>
          <template v-if="item.code === 'delivaryReportDetail'">
            <DeliveryReportDetail ref="delivaryReportDetail" :data="delivaryReportDetailData" />
          </template>
          <template v-if="item.code === 'RIMGMT'">
            <ResultInput ref="RIMGMT" @dbClick="resultInputDetail" />
          </template>
          <template v-if="item.code === 'resultInputDetail'">
            <ResultInputDetail ref="resultInputDetail" :data="resultInputDetailData" />
          </template>
          <template v-if="item.code === 'qulityTestInput'">
            <QulityTestInput ref="qulityTestInput" />
          </template>
          <template v-if="item.code === 'RMGMT'">
            <Report ref="RMGMT" @dbClick="reportDetail" />
          </template>
          <template v-if="item.code === 'reportDetail'">
            <ReportDetail ref="reportDetail" :data="reportDetailData" />
          </template>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>
<script>
import SetDialog from '@/components/SetDialog';
import UserSample from '@/views/sample/sampleTab/UserSample';
import AdminSample from '@/views/sample/sampleTab/AdminSample';
import ConfirmSample from '@/views/sample/sampleTab/ConfirmSample';
import SampleRequestDetail from '@/views/sample/sampleTab/SampleRequestDetail';
import SearchProcess from '@/views/sample/sampleTab/SearchProcess';
import SearchProcessCustom from '@/views/sample/sampleTab/SearchProcessCustom';
import DeliveryReport from '@/views/sample/sampleTab/DeliveryReport';
import DeliveryReportDetail from '@/views/sample/sampleTab/DeliveryReportDetail';
import ResultInput from '@/views/sample/sampleTab/ResultInput';
import ResultInputDetail from '@/views/sample/sampleTab/ResultInputDetail';
import QulityTestInput from '@/views/sample/sampleTab/QulityTestInput';
import Report from '@/views/sample/sampleTab/Report.vue';
import ReportDetail from '@/views/sample/sampleTab/ReportDetail.vue';
import { mapState, mapMutations } from 'vuex';
import _ from 'lodash';
import NewSample from '@/views/sample/sampleTab/NewSample.vue';
import Accont from '@/views/admin/user/Account.vue';
import AccontPw from '@/views/admin/user/AccountPw.vue';
import MenuMgn from '@/views/admin/user/MenuMng.vue';
import LoginMgn from '@/views/admin/user/LoginMgn.vue';
import CodeMgn from '@/views/admin/user/CodeMgn.vue';
import CodeDetail from '@/views/admin/user/CodeDetail.vue';
import LangCode from '@/views/admin/user/LangCode';
import UserMaster from '@/views/master/masterTap/UserMaster';
import UserMasterDetail from '@/views/master/masterTap/UserMasterDetail';
import MenstruumMaster from '@/views/master/masterTap/MenstruumMaster';
import MenstruumDetail from '@/views/master/masterTap/MenstruumDetail';
import MenstruumAdd from '@/views/master/masterTap/MenstruumAdd';
import SampleAdd from '@/views/master/masterTap/SampleAdd';
import SampleMaster from '@/views/master/masterTap/SampleMaster';
import PartnerMaster from '@/views/master/masterTap/PartnerMaster';
import PartnerAdd from '@/views/master/masterTap/PartnerAdd';
import PartnerMasterDetail from '@/views/master/masterTap/PartnerMasterDetail';
import SampleMasterDetail from '@/views/master/masterTap/SampleMasterDetail';
import MaterialIndex from '@/views/master/masterTap/MaterialIndex';
import MaterialIndexDetail from '@/views/master/masterTap/MaterialIndexDetail';
import MaterialIndexAdd from '@/views/master/masterTap/MaterialIndexAdd';
import COA from '@/views/document/documentTap/COA';
import COADetail from '@/views/document/documentTap/COADetail';
export default {
  watch: {
    // 탭간 이동 시 리스트에서 상세 보기 데이터 전달 문제때문에 원복 시킴.
    selectMenu: {
      deep: true,
      handler: function (v) {
        if (v.length < 1) {
          this.$router.push({ name: 'main' });
        } else {
          setTimeout(() => {
            if (v[this.tab] && v[this.tab].code) {
              const ref = v[this.tab].code;
              const tmp = this.$refs[ref];
              if (tmp) {
                const component = this.$refs[ref][0];
                if (_.has(component, 'loadData')) {
                  component.loadData();
                }
              }
            }
          }, 100);
        }
      },
    },
    // 탭간 이동 시 리스트에서 상세 보기 데이터 전달 문제때문에 원복 시킴.
    tab(v) {
      setTimeout(() => {
        if (this.selectMenu[v]) {
          const ref = this.selectMenu[v].code;
          const tmp = this.$refs[ref];
          if (tmp) {
            const component = this.$refs[ref][0];
            if (_.has(component, 'loadData')) {
              component.loadData();
            }
          }
        }
      }, 100);
    },
    // tab(v) {
    //   this.activeTabAndLoadData(v, true); // tab 변경 시에는 조건에 따라 새로고침
    // },
  },
  data() {
    return {
      loadedTabs: {}, // 로드된 탭과 그 타임스탬프를 추적
      resultInputDetailData: {},
      sampleRequestDetailData: {},
      reportDetailData: {},
      newSampleData: {},
      codeDetailData: {},
      userDetailData: {},
      sampleDetailData: {},
      sampleAddData: {},
      menstruumDetailData: {},
      menstruumAddData: {},
      materialIndexDetailData: {},
      materialIndexAddData: {},
      coaDetailData: {},
      partnerDetailData: {},
      partnerAddData: {},
      items: [],
    };
  },
  computed: {
    ...mapState('loading', ['loading']),
    ...mapState('member', ['accessToken']),
    ...mapState('menu', ['menu', 'selectMenu', 'tab']),
    ...mapState('locale', ['locale']),
  },
  components: {
    SetDialog,
    UserSample,
    AdminSample,
    ConfirmSample,
    SampleRequestDetail,
    SearchProcess,
    SearchProcessCustom,
    DeliveryReport,
    DeliveryReportDetail,
    ResultInput,
    ResultInputDetail,
    QulityTestInput,
    Report,
    ReportDetail,
    NewSample,
    Accont,
    AccontPw,
    MenuMgn,
    LoginMgn,
    CodeMgn,
    CodeDetail,
    LangCode,
    UserMaster,
    UserMasterDetail,
    MenstruumMaster,
    SampleMaster,
    SampleMasterDetail,
    SampleAdd,
    MenstruumDetail,
    MenstruumAdd,
    MaterialIndex,
    MaterialIndexDetail,
    MaterialIndexAdd,
    COA,
    COADetail,
    PartnerMaster,
    PartnerAdd,
    PartnerMasterDetail,
  },
  created() {
    this.SET_MENU();
    const param = this.$route.params;
    if (param && param.tab) {
      const tab = param.tab;
      this.ADD_MENU_TAB_BY_CODE(tab);
    }
  },
  methods: {
    ...mapMutations('menu', ['SET_MENU', 'ADD_MENU_TAB', 'ADD_MENU_TAB_BY_CODE', 'REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB', 'SET_SELECT_MENU_TAB_BY_CODE']),
    activeTabAndLoadData(tabIndex, forceRefresh = false) {
      const selectMenuAndTab = this.selectMenu[tabIndex];
      if (selectMenuAndTab && selectMenuAndTab.code) {
        // 강제 새로고침이 요청되었거나, 탭이 활성화될 때마다 API 호출
        this.loadTabAndData(selectMenuAndTab, forceRefresh);
      }
    },
    loadTabAndData(selectMenuAndTab, forceRefresh) {
      const ref = selectMenuAndTab.code;
      const componentRef = this.$refs[ref];
      if (componentRef) {
        const component = componentRef[0];
        // loadData 메소드가 있는지 확인하고 호출
        if (component && typeof component.loadData === 'function') {
          // forceRefresh가 true이거나 컴포넌트가 아직 데이터를 로드하지 않았을 경우에만 호출
          if (forceRefresh || !component.isDataLoaded) {
            component.loadData();
          }
        }
      }
    },
    home() {
      this.$router.push({ name: 'main' });
    },
    tabChange(item) {
      this.SET_SELECT_MENU_TAB_BY_CODE(item.code);
    },
    findTab(code, menu, menu_eng, target, closeable, data) {
      let idx = _.findIndex(this.selectMenu, function (v) {
        return v.code === code;
      });
      if (idx === -1) {
        this.ADD_MENU_TAB({
          code,
          menu,
          menu_eng,
          closeable,
          url: '/',
        });
        idx = _.findIndex(this.selectMenu, function (v) {
          return v.code === code;
        });
      }
      this.SET_SELECT_MENU_TAB(idx);
      this[target] = data;

      if (this.selectMenu.length > 8) {
        if (!confirm(`You can open up to 8 tabs. The first tab disappears.`)) {
          this.REMOVE_SELECT_MENU(this.selectMenu[8].code);
        } else {
          this.REMOVE_SELECT_MENU(this.selectMenu[0].code);
        }
      }
    },
    reset() {
      this.resultInputDetail = {};
      this.sampleRequestDetailData = {};
      this.reportDetailData = {};
      this.newSampleData = {};
      this.codeDetailData = {};
      this.userDetailData = {};
      this.sampleDetailData = {};
      this.sampleAddData = {};
      this.menstruumDetailData = {};
      this.menstruumAddData = {};
      this.materialIndexDetailData = {};
      this.materialIndexAddData = {};
      this.coaDetailData = {};
      this.partnerDetailData = {};
      this.partnerAddData = {};
      this.items = [];
    },
    removeTab(code) {
      this.REMOVE_SELECT_MENU(code);
    },
    userDetail(data) {
      this.findTab('userDetail', '회원 마스터 상세', 'User Detail', 'userDetailData', true, data);
    },
    sampleAdd() {
      this.findTab('sampleAdd', '샘플 마스터 등록', 'Sample Code Reg', 'sampleAddData', true);
    },
    sampleMasterDetail(data) {
      this.findTab('sampleDetail', '샘플 마스터 상세', 'Sample Code Detail', 'sampleDetailData', true, data);
    },
    partnerAdd() {
      this.findTab('partnerAdd', '고객 등록', 'Partner Master Reg', 'partnerAddData', true);
    },
    partnerMasterDetail(data) {
      this.findTab('partnerDetail', '고객 상세', 'Partner Detail', 'partnerDetailData', true, data);
    },
    menstruumMasterDetail(data) {
      this.findTab('menstruumDetail', '용매조성 마스터 상세', 'Solvent Composition Detail', 'menstruumDetailData', true, data);
    },
    menstruumAdd() {
      this.findTab('menstruumAdd', '용매조성 마스터등록', 'Solevent Composition Reg', 'menstruumAddData', true);
    },
    materialIndexDetail(data) {
      this.findTab('materialIndexDetail', '물질명 INDEX 마스터 상세', 'Substance Index Detail', 'materialIndexDetailData', true, data);
    },
    materialIndexAdd() {
      this.findTab('materialIndexAdd', '물질명 INDEX 등록', 'Substance Index Add', 'materialIndexAddData', true);
    },
    codeDetail(data) {
      this.findTab('CDMGMTDT', '코드 상세', 'Code Detail', 'codeDetailData', true, data);
    },
    coaDetail(data) {
      this.findTab('coaDetail', 'COA 상세', 'COA Detail', 'coaDetailData', true, data);
    },
    delveryReportDetail(data) {
      this.findTab('delivaryReportDetail', '납품일보 상세', 'deliveray Report Detail', 'delivaryReportDetailData', true, data);
    },
    confirmDetail(data) {
      this.findTab('sampleRequestDetail', '샘플 요청상세', 'Sample Request Detail', 'sampleRequestDetailData', true, data);
    },
    newSample(data) {
      this.findTab('NUSMGMT', '신규 샘플요청', 'Users Sample MGMT', 'newSampleData', true, data);
    },
    reportDetail(data) {
      this.findTab('reportDetail', '제조 기록지 상세', '제조 기록지 상세', 'reportDetailData', true, data);
    },
    openSearchProcessCustom() {
      this.findTab('MMGMT', '진행 사항 조회 (사용자)', null, true, null);
    },
    resultInputDetail(data) {
      this.findTab('resultInputDetail', '품질검사 결과입력', '품질검사 결과입력', 'resultInputDetailData', true, data);
    },
  },
  mounted() {
    if (this.selectMenu.length == 0) {
      this.$router.push({ name: 'main' });
    }
  },
};
</script>
<style>
.full {
  height: 100%;
  width: 100%;
  background-color: white;
}
</style>
