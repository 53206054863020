<template>
  <v-flex xs12>
    <div class="signupPost">
      <SetPopup ref="alertPopup" />
      <SetDialogVue ref="dialog">
        <div class="post-contents">
          <div class="sign-up-container-1">
            <h4>비밀번호 변경</h4>
            <hr class="mt-3 mb-3" />
            <div class="pa-5 border mb-3">
              <v-form ref="passwordrechange" lazy-validation>
                <div class="post-contents">
                  <ul class="post__box">
                    <li class="post__list">
                      <SignupInputVue placeholder="기존 비밀번호를 입력해 주세요." label="*기존 비밀번호" type="password" append-icon="mdi-eye" v-model="param.olbPassword" :rules="[this.validSet.empty(param.olbPassword, '비밀번호를 입력해주세요'), this.validSet.olbPassword]" />
                    </li>
                    <li class="post__list">
                      <SignupInputVue placeholder="신규 비밀번호를 입력해 주세요." label="*신규 비밀번호" type="password" append-icon="mdi-eye" v-model="param.password" :rules="[this.validSet.empty(param.password, '비밀번호를 입력해주세요'), this.validSet.password]" />
                    </li>
                    <li class="post__list">
                      <SignupInputVue placeholder="신규 비밀번호를 입력해 주세요." label="*비밀번호 재확인" labelWidth="150" type="password" append-icon="mdi-eye" v-model="param.passwordCode" :rules="[this.validSet.empty(param.passwordCode, '비밀번호를 입력해주세요'), this.validSet.passwordCode(param.password, param.passwordCode)]" />
                    </li>
                  </ul>
                </div>
              </v-form>
            </div>
            <div class="wrapper">
              <div class="btn-box">
                <v-btn depressed color="primary" @click="approve">닫기</v-btn>
                <v-btn depressed color="primary" @click="onPasswordChange">비밀번호 변경 저장</v-btn>
              </div>
            </div>
          </div>
        </div>
      </SetDialogVue>
    </div>
  </v-flex>
</template>

<script>
import _ from 'lodash';
import SignupInputVue from '@/views/member/SignUpInput';
import validSet from '@/assets/valid';
import SetDialogVue from './SetDialog.vue';
import SetPopup from '@/components/SetPopup.vue';
import { mapMutations } from 'vuex';
import { resetPassword } from 'api/member/member';
export default {
  name: 'PasswordChange',
  data: function () {
    return {
      signupPostKey: 0,
      validSet,
      param: {
        olbPassword: '',
        password: '',
        passwordCode: '',
      },
    };
  },
  watch: {},
  methods: {
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_TOKEN', 'SET_USER_INFO']),
    open() {
      this.SET_MODAL({
        // height: 100,
        width: 500,
        closable: true,
        customApprove: true,
      });
      this.loadData(() => {
        this.$refs.dialog.openModal();
      });
    },
    loadData(cb) {
      cb();
    },
    close() {
      this.reset();
      this.$refs.dialog.closeModal();
    },
    onPasswordChange() {
      if (this.valid()) {
        const params = {
          memberId: this.$store.state.member.userInfo.memberId,
          oldPassword: this.param.olbPassword,
          memberPw: this.param.password,
          ...this.param.post,
        };
        resetPassword(params)
          .then(res => {
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              this.openPopup(body.errorMessage);
            } else {
              this.reset();
              this.$refs.dialog.closeModal();
              // this.SET_USER_INFO({});
              this.SET_TOKEN({});
              this.openPopup(body.message + ' 다시 로그인을 하셔야 합니다.', false, () => {
                this.$router.push({ name: 'login' });
              });
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
    valid() {
      return this.$refs.passwordrechange.validate();
    },
    reset() {
      this.param = {
        olbPassword: '',
        password: '',
        passwordCode: '',
      };
    },
    approve() {
      this.reset();
      this.close();
      this.$emit('onApprove', this.param);
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        width: 300,
        approveName: '확인',
        text,
        closable,
      });
      this.$refs.alertPopup.openPopup(cb);
    },
  },
  mounted() {
    this.reset();
  },
  components: {
    SetDialogVue,
    SignupInputVue,
    SetPopup,
  },
};
</script>
<style lang="scss">
.signupPost {
  .cardWrapperLeft {
    display: flex;
  }
  .cardWrapper {
    display: flex;
    justify-content: center;
  }
  .col {
    flex-direction: column;
  }
  .addDetail {
    width: 400px;
    margin-top: 17px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  .addDetailOther {
    width: 400px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  table {
    border: 1px solid;
    border-collapse: collapse;
    width: 60%;
    margin-top: 15px;
  }
  th,
  td {
    border: 1px solid;
  }
  .v-input--radio-group--row .v-messages.theme--light {
    display: none !important;
  }
  .addressSelect {
    display: flex;
    position: relative;
    align-items: center;
    height: 56px;
  }
}
</style>
