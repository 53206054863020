const initialState = () => ({
  requestCount: 0,
});

const loadingbar = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: state => state.requestCount > 0, // 로딩 상태는 요청 카운터가 0보다 클 때 true
  },
  mutations: {
    START_LOADING(state) {
      state.requestCount++;
    },
    FINISH_LOADING(state) {
      if (state.requestCount > 0) {
        state.requestCount--;
      }
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    },
  },
};

export default loadingbar;
