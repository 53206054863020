<template>
  <div>
    <!-- <h3 class="mb-2">진행사항 조회 사용자</h3>
    <h3 class="mt-4 mb-2 pl-1 pr-1">요청 내역</h3> -->
    <!-- <div class="wrapperSpace">
      <div>
        <h4 class="mt-4 mb-2">요청 내역</h4>
      </div>
    </div> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @click="click" />
  </div>
</template>
<script>
import _ from 'lodash';
import RealGrid from '@/components/RealGrid.vue';
import { columns, fields, rows, height } from '@/assets/grid/searchProcessCustom';
import { sampleRequestandstatus } from 'api/sample/sample';
import { getCoapdfFile, getMsdspdfFile } from 'api/file';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'searchProcesCustom',
      settings: {
        columns,
        fields,
        rows,
        height,
        // hideCheckBar: true,
        errorMessage: '진행중인 요청내역이 없습니다',
      },
      param: {
        pageSize: 100000,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    reset() {},
    async search(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await sampleRequestandstatus({
          ...this.param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = false;
        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.$refs.grid.setPage(page);
        this.$refs.grid.loadData(items, ['delivery_date']);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    loadData(v) {
      this.search(v);
    },
    click(data) {
      // 그리드 빈 공간 클릭 제외.
      if (data.clickData.cellType == 'gridEmpty') {
        return;
      }
      const row = this.$refs.grid.getJsonRows();
      const coaPath = row[data.clickData.itemIndex].coapdf_path;
      const msdsPath = row[data.clickData.itemIndex].msdspdf_path;

      if (data.clickData.fieldName == 'coapdf_path' && coaPath) {
        var coaPaths = coaPath.split(',');
        coaPaths.forEach(function (path) {
          path = path.trim();
          getCoapdfFile(path);
        });
      } else if (data.clickData.fieldName == 'msdspdf_path' && msdsPath) {
        var paths = msdsPath.split(',');
        paths.forEach(function (path) {
          path = path.trim();
          getMsdspdfFile(path);
        });
      }
    },
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss"></style>
