<template>
  <v-container fill-height fluid class="">
    <SetDialog ref="modal" />
    <div class="pa-4 pt-1 pb-0 full">
      <div class="wrapper">
        <div class="filter">
          <v-row :class="userInfo.admincheck == 2 ? 'fiveCol ' : 'mt-0'">
            <v-col cols="12" :sm="userInfo.admincheck === 2 ? 2 : 4">
              <div class="main-info info01" @click="newRq">
                신규요청
                <div>
                  <span>{{ dash.new }}</span
                  ><span>건</span>
                </div>
                <i class="i_deco"><img src="@/assets/img/icon_dashboard02.png" /></i>
              </div>
            </v-col>
            <v-col cols="12" :sm="userInfo.admincheck === 2 ? 2 : 4">
              <div class="main-info info02" @click="progress">
                제조중
                <div>
                  <span>{{ dash.progress }}</span>
                  <span>건</span>
                </div>
                <i class="i_deco"><img src="@/assets/img/icon_dashboard03.png" /></i>
              </div>
            </v-col>
            <template v-if="userInfo.admincheck === 2">
              <v-col cols="12" sm="2">
                <div class="main-info info03" @click="progressDelay">
                  제조지연
                  <div>
                    <span>{{ dash.produceduedelay }}</span>
                    <span>건</span>
                  </div>
                  <i class="i_deco"><img src="@/assets/img/icon_dashboard03.png" /></i>
                </div>
              </v-col>
              <v-col cols="12" sm="2">
                <div class="main-info info04" @click="deliveryDelay">
                  출하지연
                  <div>
                    <span>{{ dash.outduedelay }}</span>
                    <span>건</span>
                  </div>
                  <i class="i_deco"><img src="@/assets/img/icon_dashboard02.png" /></i>
                </div>
              </v-col>
            </template>
            <v-col cols="12" :sm="userInfo.admincheck === 2 ? 2 : 4">
              <div class="main-info info05" @click="delivery">
                출하예정
                <div>
                  <span>{{ dash.outdue }}</span>
                  <span>건</span>
                </div>
                <i class="i_deco"><img src="@/assets/img/icon_dashboard04.png" /></i>
              </div>
            </v-col>
          </v-row>
          <div class="wrapperEnd"></div>
          <h3 class="mb-2 dp--row">
            {{ tab }}
            <a style="margin-left: auto" @click="more" class="btn_more">더보기</a>
          </h3>
          <RealGrid v-show="key === 'new'" domName="new" ref="newGrid" :settings="newGrid" @changePage="newRq" />
          <RealGrid v-show="key === 'delivery'" domName="delivery" ref="deliveryGrid" :settings="deliveryGrid" @changePage="delivery" />
          <RealGrid v-show="key === 'progressDelay'" domName="progressDelay" ref="progressDelayGrid" :settings="progressDelayGrid" @changePage="progressDelay" />
          <RealGrid v-show="key === 'deliveryDelay'" domName="deliveryDelay" ref="deliveryDelayGrid" :settings="deliveryDelayGrid" @changePage="deliveryDelay" />
          <RealGrid v-show="key === 'progress'" domName="progress" ref="progressGrid" :settings="progressGrid" @changePage="progress" />
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import SetDialog from '@/components/SetDialog';
import RealGrid from '@/components/RealGrid.vue';
import * as newGrid from '@/assets/grid/newGrid';
import * as deliveryGrid from '@/assets/grid/delivery';
import * as progressGrid from '@/assets/grid/progress';
import * as deliveryDelayGrid from '@/assets/grid/delivery';
import * as progressDelayGrid from '@/assets/grid/progress';
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { dashcount, dashdelivery, dashnew, progress, dashdeliverydelay, dashproducedelay } from 'api/sample/sample';
export default {
  data() {
    return {
      newGrid,
      deliveryGrid,
      progressGrid,
      deliveryDelayGrid,
      progressDelayGrid,
      key: 'new',
      set: [
        {
          key: 'new',
          value: '신규요청',
        },
        {
          key: 'progress',
          value: '제조중',
        },
        {
          key: 'progressDelay',
          value: '제조지연',
        },
        {
          key: 'deliveryDelay',
          value: '출하지연',
        },
        {
          key: 'delivery',
          value: '출하예정',
        },
      ],
      dash: {},
      param: { currentPage: 1, pageSize: 100000 },
    };
  },
  computed: {
    ...mapState('menu', ['menu']),
    ...mapState('member', ['accessToken', 'userInfo']),
    tab() {
      return _.reduce(
        this.set,
        (a, v) => {
          if (v.key == this.key) {
            a = v.value;
          }
          return a;
        },
        '',
      );
    },
  },
  components: {
    SetDialog,
    RealGrid,
  },
  created() {
    if (!this.accessToken) {
      this.$router.push({ name: 'login' });
    } else {
      this.SET_MENU();
    }
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('menu', ['SET_MENU']),
    async chageTab(v, key, ref, fun) {
      if (_.isNumber(v)) {
        this.currentPage = v;
      }
      if (this.key != key) {
        this.currentPage = 1;
        this.key = key;
      }

      const res = await fun({
        ...this.param,
        currentPage: this.currentPage,
        pageSize: 100000,
      });
      const data = res.data.data;
      const grid = this.$refs[ref];
      if (grid) {
        if (data.items) {
          // 메인 화면에서 열고정.
          if (ref == 'newGrid') grid.gv.setFixedOptions({ colCount: 1 });
          grid.loadData(data.items, ['delivery_date']);
        } else {
          grid.loadData([]);
        }
        grid.setPage(data.params);
      }
    },
    newRq(v) {
      this.chageTab(v, 'new', 'newGrid', dashnew);
    },
    progress(v) {
      this.chageTab(v, 'progress', 'progressGrid', progress);
    },
    progressDelay(v) {
      this.chageTab(v, 'progressDelay', 'progressDelayGrid', dashproducedelay);
    },
    deliveryDelay(v) {
      this.chageTab(v, 'deliveryDelay', 'deliveryDelayGrid', dashdeliverydelay);
    },
    delivery(v) {
      this.chageTab(v, 'delivery', 'deliveryGrid', dashdelivery);
    },
    more() {
      let tab;
      if (this.userInfo.admincheck == 1) {
        tab = 'MMGMT';
      } else if (this.userInfo.admincheck == 2) {
        tab = 'MMGMTM';
      }
      this.$router.push({ name: 'service', params: { tab } });
    },
    async loadData() {
      await this.loadDeshCount();
      await this.newRq();
    },
    async loadDeshCount() {
      const res = await dashcount();
      this.dash = res.data.data;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped lang="scss">
.filter {
  z-index: 1;
  width: 90%;
  padding-top: 0px;
  .v-card {
    height: 50px;
    align-items: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
  }
}
.fiveCol {
  width: 120%;
}
</style>
