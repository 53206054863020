<template>
  <div>
    <!-- <h3 class="mb-2">회원 상세</h3> -->
    <SetPopup ref="alert" />
    <RealGrid domName="gridDetail" ref="gridDetail" class="mb-2" :settings="settingDetail" :nonePage="true" @changeData="changeData" />
    <!-- <h3 class="mt-2 mb-2">샘플 요청 내역</h3> -->
    <!-- <h3 class="mt-6 mb-2">
      <div class="wrapperSpace">샘플 요청 내역</div>
    </h3>
    <hr class="mb-4" /> -->
    <RealGrid domName="gridSample" ref="gridSample" :settings="settingsSample" :nonePage="true" @changePage="getMemberSampleList" @changeData="changeData" />
  </div>
</template>
<script>
import * as detail from '@/assets/grid/userMaster';
import * as sample from '@/assets/grid/sampleRequestDetailAddEdit';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
import { memberSampleListAdm } from 'api/sample/sample';
import { mapMutations } from 'vuex';
import { requestSampleAddressUpdate } from 'api/member/member';
export default {
  props: ['data'],
  watch: {
    data: {
      deep: true,
      handler: function (v) {
        this.loadData([v]);
      },
    },
  },
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'userMasterDetail',
      settingsSample: {
        ...sample,
        errorMessage: '샘플요청내역이 비었습니다',
        hideCheckBar: true,
        noneNo: true,
      },
      settingDetail: {
        ...detail,
        height: 100,
        errorMessage: '회원내용이 비었습니다',
        hideCheckBar: true,
        noneNo: true,
      },
    };
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    async getMemberSampleList(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const res = await memberSampleListAdm({
          memberId: this.data.email,
          currentPage: v ? v : '1',
          pageSize: '15',
        });
        this.isDataLoaded = false;

        const response = res.data;
        const items = response.data.items;
        this.$refs.gridSample.loadData(items, ['reg_dt', 'delivery_date']);
        // eslint-disable-next-line no-undef
        this.$refs.gridSample.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    updateAddress() {
      const row = this.$refs.gridSample.getCheckedRow();
      if (row.length > 0) {
        this.$refs.address.open();
      } else {
        this.SET_POPUP({
          title: '알림',
          text: '항목을 선택해주세요',
          // height: 150,
          width: 300,
        });
        this.$refs.alert.openPopup();
      }
    },
    loadData() {
      this.$refs.gridDetail.loadData([this.data]);
      this.getMemberSampleList();
    },
    changeData(v) {
      const row = this.$refs.gridSample.getJsonRowByIdx(v.dataRow);
      row.memberId = this.data.email;
      requestSampleAddressUpdate(row)
        .then(() => {
          this.loadData();
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.loadData();
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss"></style>
