<template>
  <div class="material">
    <!-- <h3 class="mb-2">물질명 INDEX 상세</h3> -->
    <SetPopup ref="confirm" />
    <RealGrid :domName="grid" ref="grid" :settings="settings" :nonePage="true" />
    <div class="wrapper">
      <div class="btn-box mt-2 mb-2">
        <v-btn depressed class="btn-cancle" @click="cancle">취소</v-btn>
        <v-btn depressed class="btn-save" @click="save">저장</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { columns, fields, height } from '@/assets/grid/materialIndex';
import { subsMasterDetail, updateSubsMaster } from 'api/subIndex/subIndex';
import { mapState, mapMutations } from 'vuex';
import _ from 'lodash';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
export default {
  props: ['data'],
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'materialIndexDetail',
      id: '',
      settings: {
        columns: _.map(_.cloneDeep(columns), function (v) {
          v.editable = true;
          return v;
        }),
        fields,
        height,
        hideCheckBar: true,
        noneNo: true,
      },
      material: '',
    };
  },
  computed: {
    ...mapState('common', ['code']),
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('member', ['SET_USER_INFO']),
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable,
        text,
      });
      this.$refs.confirm.openPopup(cb);
    },
    save() {
      this.openPopup('저장하시겠습니까?', true, () => {
        const row = this.$refs.grid.getJsonRow();
        row.memberId = this.id;
        updateSubsMaster(row)
          .then(() => {
            this.openPopup('저장되었습니다', false, () => {
              this.reset();
            });
          })
          .catch(() => {});
      });
    },
    reset() {
      this.loadData();
    },
    cancle() {
      this.openPopup('취소하시겠습니까?', true, () => {
        this.REMOVE_SELECT_MENU('materialIndexDetail');
        this.SET_SELECT_MENU_TAB_BY_CODE('STMGMT');
      });
    },
    async loadData() {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const res = await subsMasterDetail(this.data.rmav);
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data;
        this.$refs.grid.loadData([items]);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
  },
  components: {
    RealGrid,
    SetPopup,
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.loadData();
  },
};
</script>
<style lang="scss">
.material {
  .v-input__slot {
    width: 200px;
  }
}
</style>
