<template>
  <div>
    <!-- <h3 class="mb-2">진행사항 조회 관리자</h3> -->
    <v-form lazy-validation ref="form">
      <div class="filter-box filter-box--row">
        <ul class="filter-list">
          <li>
            <h4>Lot No</h4>
            <v-text-field outlined dense placeholder="" v-model="param.lotNo" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청자</h4>
            <v-text-field outlined dense placeholder="" v-model="param.request_name" :rules="[this.validSet.name]" @keyup.enter="search"></v-text-field>
          </li>
          <!-- 2023.05.16 개선업무코드 [ST]-->
          <!-- <li>
            <h4>요청자 연락처</h4>
            <v-text-field
              outlined
              dense
              placeholder=""
              v-model="param.request_name"
              :rules="[this.validSet.name]"
            ></v-text-field>
          </li> -->
          <!-- 2023.05.16 개선업무코드 [ED]-->
          <li>
            <h4>접수 상태</h4>
            <v-select :items="code.Q" v-model="param.stat" outlined id="work" />
          </li>
          <li>
            <h4>제조 진행사항</h4>
            <v-select :items="code.R" v-model="param.producestat" outlined id="work" />
          </li>
          <li>
            <h4>출하 사항</h4>
            <div class="wrapperTop">
              <v-select :items="code.O" v-model="param.releasestat" outlined id="work" />
            </div>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions class="px-0">
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- <div class="searchProcess wrapperSpace pa-6 mt-4 mb-4 sch">
        <v-row class="pl-2" style="height: 86px">
          <v-col cols="12" sm="3">
            <h4>Lot No</h4>
            <v-text-field
              outlined
              dense
              placeholder="Lot No를 입력해 주세요."
              v-model="param.lotNo"
              :rules="[this.validSet.commonCodeHipen]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <h4>요청자</h4>
            <v-text-field
              outlined
              dense
              placeholder="요청자를 입력해 주세요."
              v-model="param.request_name"
              :rules="[this.validSet.name]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <h4>접수 상태</h4>
            <v-select :items="code.Q" v-model="param.stat" outlined id="work" />
          </v-col>
          <v-col cols="12" sm="2">
            <h4>제조 진행사항</h4>
            <v-select
              :items="code.R"
              v-model="param.producestat"
              outlined
              id="work"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <h4>출하 사항</h4>
            <div class="wrapperTop">
              <v-select
                :items="code.O"
                v-model="param.releasestat"
                outlined
                id="work"
              />
              <v-card-actions>
                <v-btn depressed outlined @click="reset">초기화</v-btn>
              </v-card-actions>
              <v-card-actions class="px-0">
                <v-btn depressed color="primary" @click="search">검색</v-btn>
              </v-card-actions>
            </div>
          </v-col>
        </v-row>
      </div> -->

      <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">요청 내역</h3> -->
      <!-- <div class="wrapperSpace">
        <div>
          <h4 class="mt-4 mb-2">요청 내역</h4>
        </div>
      </div> -->
    </v-form>
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="search" @click="click" />
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/searchProcessCustom';
import { mapState } from 'vuex';
import { sampleRequestandstatusadmin } from 'api/sample/sample';
import { getCoapdfFile, getMsdspdfFile } from 'api/file';
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import _ from 'lodash';

export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'searchProces',
      validSet,
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        // hideCheckBar: true,
        errorMessage: '진행중인 요청내역이 없습니다',
      },
      param: {
        currentPage: '1',
        lotNo: '',
        pageSize: 100000,
        producestat: '전체',
        releasestat: '전체',
        request_name: '',
        stat: '전체',
      },
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    reset() {
      this.param = {
        currentPage: '1',
        lotNo: '',
        pageSize: 100000,
        producestat: '전체',
        releasestat: '전체',
        request_name: '',
        stat: '전체',
      };
    },
    loadData(v) {
      this.search(v);
    },
    async search(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await sampleRequestandstatusadmin({
          ...this.param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = false;
        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.$refs.grid.setPage(page);
        this.$refs.grid.loadData(items, ['delivery_date']);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    valid() {
      return this.$refs.form.validate();
    },
    click(data) {
      // 그리드 빈 공간 클릭 제외.
      if (data.clickData.cellType == 'gridEmpty') {
        return;
      }
      const row = this.$refs.grid.getJsonRows();
      const coaPath = row[data.clickData.itemIndex].coapdf_path;
      const msdsPath = row[data.clickData.itemIndex].msdspdf_path;

      if (data.clickData.fieldName == 'coapdf_path' && coaPath) {
        var coaPaths = coaPath.split(',');
        coaPaths.forEach(function (path) {
          path = path.trim();
          getCoapdfFile(path);
        });
      } else if (data.clickData.fieldName == 'msdspdf_path' && msdsPath) {
        var paths = msdsPath.split(',');
        paths.forEach(function (path) {
          path = path.trim();
          getMsdspdfFile(path);
        });
      }
    },
  },
  components: {
    RealGrid,
  },
  computed: {
    ...mapState('common', ['code']),
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style lang="scss">
.searchProcess {
  .v-input__slot {
    width: 100% !important;
  }
}
</style>
