import { ValueType } from 'realgrid';
export const fields = [
  {
    fieldName: 'member_name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'phone',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'memberId',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'postcode',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address',
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'member_name',
    fieldName: 'member_name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '이름',
      showTooltip: false,
    },
    editable: false,
    width: 100,
  },
  {
    name: 'phone',
    fieldName: 'phone',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '휴대폰 번호',
      showTooltip: false,
    },
    editable: false,
    width: 100,
  },
  {
    name: 'memberId',
    fieldName: 'memberId',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: '이메일 주소',
    editable: false,
  },
  {
    name: 'postcode',
    fieldName: 'postcode',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '우편번호',
      showTooltip: false,
    },
    editable: false,
    width: 100,
  },
  {
    name: 'address',
    fieldName: 'address',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '주소',
      showTooltip: false,
    },
    editable: false,
  },
];
export const rows = [
  {
    member_name: '',
    memberId: '123',
    phone: '123',
    postcode: 'Arcu Et Pede Incorporated',
    address: '17',
  },
];
