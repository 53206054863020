import { render, staticRenderFns } from "./MenstruumAdd.vue?vue&type=template&id=a10efabe"
import script from "./MenstruumAdd.vue?vue&type=script&lang=js"
export * from "./MenstruumAdd.vue?vue&type=script&lang=js"
import style0 from "./MenstruumAdd.vue?vue&type=style&index=0&id=a10efabe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports