<template>
  <div class="file-upload">
    <div class="header">
      <h4>{{ title }}</h4>
      <div class="file-actions" v-show="files.length > 0">
        <button @click="selectFiles" :disabled="files.length >= maxFiles">파일추가</button>
        <button @click="removeSelectedFiles" id="btn_delete">삭제</button>
      </div>
    </div>
    <div class="drop-area" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave" @drop.prevent="onDrop" :class="{ 'is-dragover': isDragover }" @click="selectFiles" v-show="files.length === 0" :style="{ height: dropAreaHeight }">
      <div class="drop-content">
        <img :src="this.fileuploa01" class="alert-img" />
        <p>파일을 마우스로 끌어 오세요.</p>
      </div>
      <input type="file" multiple @change="onFileChange" ref="fileInput" accept=".pdf" style="display: none" />
    </div>
    <div v-if="files.length" class="file-list-container" :style="{ height: dropAreaHeight }">
      <div class="select-all">
        <input type="checkbox" :id="`${_uid}-select-all`" @change="selectAllFiles" :checked="isAllSelected" />
        <label :for="`${_uid}-select-all`">전체선택</label>
      </div>
      <div class="file-list">
        <ul>
          <li v-for="(file, index) in files" :key="`${_uid}-file-${index}`">
            <input type="checkbox" v-model="selectedFileIds" :value="index" :id="`${_uid}-file-${index}`" />
            <label :for="`${_uid}-file-${index}`"><img src="@/assets/img/pdf_multifile.png" class="pdf-img" alt="PDF Icon" /> {{ file.name }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    initialFiles: {
      type: Array,
      default: () => [],
    },
    dropAreaHeight: {
      type: String,
      default: '300px',
    },
  },
  data() {
    return {
      files: [],
      deleteFiles: [],
      selectedFileIds: [],
      isDragover: false,
      acceptedFormats: ['application/pdf'],
      fileuploa01: require('@/assets/img/fileuploa01.png'),
      pdfmultifile: require('@/assets/img/pdf_multifile.png'),
    };
  },
  watch: {
    initialFiles: {
      handler(newFiles) {
        if (JSON.stringify(newFiles) !== JSON.stringify(this.files)) {
          this.files = newFiles.slice(0, this.maxFiles);
        }
      },
      immediate: true,
    },
    files: {
      handler(newFiles) {
        this.$emit('update:files', newFiles);
      },
      deep: true,
    },
  },
  computed: {
    isAllSelected() {
      return this.selectedFileIds.length === this.files.length && this.files.length > 0;
    },
  },
  methods: {
    onDragOver() {
      this.isDragover = true;
    },
    onDragLeave() {
      this.isDragover = false;
    },
    onDrop(event) {
      this.isDragover = false;
      const droppedFiles = Array.from(event.dataTransfer.files);
      this.addFiles(droppedFiles);
    },
    onFileChange(event) {
      const selectedFiles = Array.from(event.target.files);
      this.addFiles(selectedFiles);
    },
    selectFiles() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.click();
      }
    },
    addFiles(files) {
      const existingFileNames = this.files.map(file => file.name);

      // 파일 형식 필터링 및 중복 파일 제거
      const uniqueFiles = files.filter(file => {
        if (!this.acceptedFormats.includes(file.type)) {
          alert(`업로드 파일 양식이 아닙니다.: ${file.name}`);
          return false;
        }
        if (existingFileNames.includes(file.name)) {
          alert(`중복된 파일입니다.: ${file.name}`);
          return false;
        }
        return true;
      });

      // 파일개수 체크
      const remainingSlots = this.maxFiles - this.files.length;
      if (uniqueFiles.length > remainingSlots) {
        alert(`최대 파일 ${this.maxFiles}가능합니다.`);
      }

      // 남은 슬롯만큼만 파일 추가
      const filesToAdd = uniqueFiles.slice(0, remainingSlots);

      this.files = [...this.files, ...filesToAdd];
    },
    removeSelectedFiles() {
      if (this.selectedFileIds.length === 0) {
        alert('삭제할 파일을 선택하세요.');
        return;
      }

      this.files = this.files.filter((_, index) => !this.selectedFileIds.includes(index));
      this.selectedFileIds = [];
    },
    selectAllFiles(event) {
      if (event.target.checked) {
        this.selectedFileIds = this.files.map((_, index) => index);
      } else {
        this.selectedFileIds = [];
      }
    },
  },
};
</script>
<style scoped>
.file-upload {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.header {
  height: 30px;
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.header h4 {
  font-size: 16px;
}

.file-actions button {
  /* background: #007bff; */
  /* color: white; */
  background: #fff;
  color: #000;
  font-size: 11px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 4px;
}

.file-actions button[disabled] {
  background: #fff;
  cursor: not-allowed;
}
#btn_delete {
  background: #08800a;
  color: #fff;
}

.drop-area {
  border: 1px dashed #bababa;
  background-color: #f9f9f9;
  padding: 0px;
  text-align: center;
  cursor: pointer;
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-area.is-dragover,
.drop-area:hover {
  border: 4px solid #73c076; /* 테두리 색상 변경 */
}

.drop-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.drop-content p {
  margin-bottom: 0px;
  margin-left: 10px;
}
.drop-area.is-dragover > .drop-content,
.drop-area:hover {
  color: #73c076;
  font-weight: 600;
}
.file-list-container {
  width: 99%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0d0;
}

.select-all {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background: #efefef;
  padding: 3px 5px;
  z-index: 1;
}

.file-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 5px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.file-list li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}
.select-all input[type='checkbox']:checked,
.file-list input[type='checkbox']:checked {
  background-color: #73c076;
  accent-color: #73c076; /* 체크박스가 선택된 상태의 배경색 */
}
.select-all input[type='checkbox'],
.file-list input[type='checkbox'] {
  display: none;
}

.select-all input[type='checkbox'] + label,
.file-list input[type='checkbox'] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.select-all input[type='checkbox'] + label::before,
.file-list input[type='checkbox'] + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.select-all input[type='checkbox']:checked + label::before,
.file-list input[type='checkbox']:checked + label::before {
  background-color: #73c076;
  border-color: #73c076;
}

.select-all input[type='checkbox']:checked + label::after,
.file-list input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 45%;
  transform: translateY(-50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
}

.select-all label,
.file-list label {
  cursor: pointer;
}
.file-list label {
  display: flex;
  align-items: center;
}
.alert-img {
  width: 25px;
  height: 25px;
}
.alert-img:hover {
  color: #73c076;
}
.pdf-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>
