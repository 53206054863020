import http from '../http';
import { version } from '../version';
const prefix = `${version}/ptAddress`;

export async function getAddressPT(param) {
  return http.post(prefix + `/getAddressPT`, param);
}

export async function insertAddressPT(param) {
  return http.post(prefix + `/insertAddressPT`, param);
}

export async function updateAddressPT(param) {
  return http.put(prefix + `/updateAddressPT`, param);
}

export async function deleteAddressPT(param) {
  return http.delete(prefix + `/deleteAddressPT?idx=${param}`);
}

export async function addressPTList(param) {
  return http.post(prefix + `/addressPTList`, param);
}

export async function searchAddressPTList(param) {
  return http.post(prefix + `/searchAddressPTList`, param);
}

export async function addressPTDefault(param) {
  return http.post(prefix + `/addressPTDefault`, param);
}
