import { ValueType } from 'realgrid';

export const fields = [
  {
    fieldName: 'pt_cd',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pt_nm',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'phone',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'rep_name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'postcode',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address1',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address2',
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'pt_cd',
    fieldName: 'pt_cd',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: '고객사코드',
    editable: false,
  },
  {
    name: 'pt_nm',
    fieldName: 'pt_nm',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '고객사명',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'rep_name',
    fieldName: 'rep_name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '담당자명',
      showTooltip: false,
    },
    editable: false,
  },
];
export const rows = [
  {
    pt_cd: 'SMP00001',
    pt_nm: '샘플고객사명',
    rep_name: '샘플고객명',
    postcode: '',
  },
];
