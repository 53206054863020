import { ValueType } from 'realgrid';

export const fields = [
  {
    fieldName: 'remove',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'defaultYn',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'idx',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pt_cd',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pickname',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'mobile',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'postcode',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address1',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address2',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'fullAddress', // New combined field
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'remove',
    fieldName: 'remove',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '삭제',
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell) {
        // cell.index.itemIndex가 유효한지 확인합니다.
        const itemIndex = cell.index ? cell.index.itemIndex : null;
        if (itemIndex !== null) {
          // itemIndex가 유효한 경우에만 getValue를 호출합니다.
          const defaultYn = grid.getValue(itemIndex, 'defaultYn');
          if (defaultYn == 1) {
            return '<div class="delete-area"><span class="default-post">기존배송지</span></div>';
          }
          return '<svg class="delete-post-icon" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 40 40"><path fill="currentColor" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>';
        } else {
          // itemIndex가 null인 경우 빈 문자열을 반환하여 렌더링 오류를 방지합니다.
          return '';
        }
      },
    },
    editable: false,
    sortable: false,
    width: 60,
  },
  // {
  //   name: 'defaultYn',
  //   fieldName: 'defaultYn',
  //   type: 'data',
  //   styles: {
  //     textAlignment: 'center',
  //   },
  //   header: {
  //     text: '기본',
  //   },
  //   renderer: {
  //     type: 'html',
  //     callback: function (grid, cell) {
  //       let defaultYn = cell.value == 'N' ? '' : '<svg xmlns="http://www.w3.org/2000/svg" color="#5A9549" width="2.5em" height="2.5em" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007l3.849-5.886a.625.625 0 0 1 .865-.181" clip-rule="evenodd"/></svg>';
  //       return defaultYn;
  //     },
  //   },
  //   editable: false,
  //   sortable: false,
  //   width: 50,
  // },
  {
    name: 'idx',
    fieldName: 'idx',
    type: 'data',
    visible: false,
    sortable: false,
  },
  {
    name: 'pt_cd',
    fieldName: 'pt_cd',
    type: 'data',
    visible: false,
    sortable: false,
  },
  {
    name: 'name',
    fieldName: 'name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '배송지명',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 90,
  },
  {
    name: 'pickname',
    fieldName: 'pickname',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '수령자',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 80,
  },
  {
    name: 'mobile',
    fieldName: 'mobile',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '핸드폰 번호',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 100,
  },
  {
    name: 'postcode',
    fieldName: 'postcode',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '우편번호',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 80,
  },
  {
    name: 'address1',
    fieldName: 'address1',
    type: 'data',
    styles: {
      textAlignment: 'left',
    },
    header: {
      text: '주소',
      showTooltip: false,
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell) {
        const address1 = grid.getValue(cell.index.itemIndex, 'address1');
        const address2 = grid.getValue(cell.index.itemIndex, 'address2');
        return `<div>${address1} ${address2}</div>`;
      },
    },
    editable: false,
    sortable: false,
    width: 300,
    // Adding a filter for fullAddress
    filter: {
      criteria: function (value, row, field, dataRow) {
        const fullAddress = dataRow['fullAddress'];
        return fullAddress.includes(value);
      },
    },
  },
];
export const rows = [
  {
    remove: '',
    defaultYn: 'Y',
    idx: '',
    pt_cd: '',
    name: '',
    pickname: '',
    mobile: '',
    postcode: '',
    address1: '',
    address2: '',
    fullAddress: '', // Initial empty value
  },
];
