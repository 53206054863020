<template>
  <div>
    <!-- <h3 class="mb-2">샘플요청 검수</h3> -->
    <v-form ref="form" lazy-validation>
      <div class="filter-box filter-box--row">
        <ul class="filter-list">
          <li>
            <h4>Lot No</h4>
            <v-text-field outlined dense placeholder="" v-model="param.lotNo" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청자</h4>
            <div class="wrapperEnd">
              <v-text-field outlined dense placeholder="" v-model="param.request_name" :rules="[this.validSet.name]" @keyup.enter="search"></v-text-field>
            </div>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed outlined class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions class="px-0">
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- <div class="wrapperSpace py-1 sch">
        <div class="mt-4 px-2" style="width: 50%">
          <v-row class="px-4 py-2">
            <v-col cols="12" sm="6">
              <h4>Lot No</h4>
              <v-text-field
                outlined
                dense
                placeholder="Lot No를 입력해 주세요."
                v-model="param.lotNo"
                :rules="[this.validSet.commonCodeHipen]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <h4>요청자</h4>
              <div class="wrapperEnd">
                <v-text-field
                  outlined
                  dense
                  placeholder="요청자를 입력해 주세요."
                  v-model="param.request_name"
                  :rules="[this.validSet.name]"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="wrapper pr-3 pt-3">
          <v-card-actions>
            <v-btn depressed outlined @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions class="px-0">
            <v-btn depressed color="primary" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div> -->
    </v-form>
    <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">요청 내역</h3> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" />
  </div>
</template>
<script>
import _ from 'lodash';
import RealGrid from '@/components/RealGrid.vue';
import validSet from '@/assets/valid';
import { sampleSearch } from 'api/sample/sample';
import { columns, fields, rows } from '@/assets/grid/confirmSearch';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false, // 데이터 로드 완료 상태 추가
      grid: 'confirmSample',
      validSet,
      settings: {
        columns,
        fields,
        rows,
        height: 555,
        hideCheckBar: true,
        errorMessage: '샘플 요청 내역이 없습니다',
      },
      param: {
        request_name: '',
        lotNo: '',
        pageSize: 100000,
      },
    };
  },
  mounted() {
    this.reset();
    this.loadData();
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    loadData(v) {
      this.search(v);
    },
    dbClick(data) {
      this.$emit('confirmDetail', data);
    },
    valid() {
      return this.$refs.form.validate();
    },
    async search(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await sampleSearch({
          ...this.param,
          currentPage: this.currentPage,
          stat: 0,
        });
        this.isDataLoaded = true; // 여기에서 로드 완료 상태 업데이트

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.$refs.grid.setPage(page);
        this.$refs.grid.loadData(items, ['reg_dt']);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    reset() {
      this.param = {
        request_name: '',
        lotNo: '',
        currentPage: 1,
        pageSize: 100000,
      };
    },
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss">
.confirmSample {
  .v-text-field__details {
    display: none;
  }
}
</style>
