<template>
  <div>
    <!-- <h3 class="mb-2">용매조성 상세</h3> -->
    <SetPopup ref="confirm" />
    <RealGrid :domName="grid" ref="grid" :settings="settings" :nonePage="true" @changeData="changeData" />
    <div class="empty-area"></div>
    <h3 class="mb-2">실제조성 정보</h3>
    <!-- <div> -->
    <RealGrid :domName="grid2" ref="actGrid" :settings="settings2" :nonePage="true" @changeData="actChangeData" />
    <p class="warning-text" v-if="isExceed">100%가 초과됩니다.</p>
    <!-- </div> -->
    <div class="wrapper">
      <div class="btn-box mt-2 mb-2">
        <v-btn depressed class="btn-cancle" @click="reset">취소</v-btn>
        <v-btn depressed class="btn-save" @click="save">저장</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import * as menstrumm from '@/assets/grid/menstrumm';
import * as actualComposition from '@/assets/grid/actualComposition';
import RealGrid from '@/components/RealGrid.vue';
import _ from 'lodash';
import SetPopup from '@/components/SetPopup.vue';
import { setNewSum } from '@/assets/grid/gridUtill';
import { actSetNewSum } from '@/assets/grid/gridUtill';
import { mapMutations } from 'vuex';
import { updateSolventMaster } from 'api/solvent/solvent';
import { getActualCompositionDetailList } from 'api/solvent/solvent';
import { updateActualComposition } from 'api/solvent/solvent';
export default {
  props: ['data'],
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      isExceed: false,
      actList: {},
      grid: 'menstrummDetail',
      grid2: 'actDetailGrid',
      id: '',
      settings: {
        ...menstrumm,
        columns: _.map(_.cloneDeep(menstrumm.columns), function (v) {
          if (v.key != 'idx') {
            v.editable = true;
          }
          return v;
        }),
        hideCheckBar: true,
        noneNo: true,
      },
      settings2: {
        ...actualComposition,
        columns: _.map(_.cloneDeep(actualComposition.columns), function (v) {
          if (v.key != 'idx') {
            v.editable = true;
          }
          return v;
        }),
        hideCheckBar: true,
        noneNo: true,
        gridPairChk: false,
      },
    };
  },
  watch: {
    data(dt) {
      this.getActDetail();
      this.setSumRow(dt);
      this.actSetSumRow(this.actList);
    },
  },
  methods: {
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_USER_INFO']),
    async getActDetail() {
      if (this.isFetching) return;
      this.isFetching = true;
      try {
        const param = Number(this.data.idx);
        const response = await getActualCompositionDetailList(param);
        this.isDataLoaded = true;
        if (response) {
          this.actList = response.data.data;
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    changeData() {
      const row = this.$refs.grid.getJsonRow();
      this.setSumRow(row);
    },
    actChangeData() {
      const actRow = this.$refs.actGrid.getJsonRow();
      this.actSetSumRow(actRow);
    },
    actSetSumRow(actRow) {
      if (actRow.solventVol1 == 0) actRow.solventVol1 = null;
      if (actRow.solventVol2 == 0) actRow.solventVol2 = null;
      if (actRow.solventVol3 == 0) actRow.solventVol3 = null;
      if (actRow.solventVol4 == 0) actRow.solventVol4 = null;
      if (actRow.solventVol5 == 0) actRow.solventVol5 = null;
      if (actRow.solventVol6 == 0) actRow.solventVol6 = null;
      if (actRow.solventVol7 == 0) actRow.solventVol7 = null;
      if (actRow.solventVol8 == 0) actRow.solventVol8 = null;
      if (actRow.saltVol1 == 0) actRow.saltVol1 = null;
      if (actRow.saltVol2 == 0) actRow.saltVol2 = null;
      if (actRow.saltVol3 == 0) actRow.saltVol3 = null;
      if (actRow.saltVol4 == 0) actRow.saltVol4 = null;
      if (actRow.saltVol5 == 0) actRow.saltVol5 = null;
      const actSum = [actSetNewSum(actRow)];
      if (Number(actSum[0].sum) > 100) {
        this.isExceed = true;
      } else {
        this.isExceed = false;
      }
      this.$refs.actGrid.loadData(actSum);
    },
    setSumRow(row) {
      const sum = [setNewSum(row)];
      this.$refs.grid.loadData(sum);
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable,
        text,
      });
      this.$refs.confirm.openPopup(cb);
    },
    isBlank(str) {
      return !str || /^\s*$/.test(str);
      // null, undefined, "" 경우 true 반환
    },
    checkPairs(row) {
      const result = [];
      for (let i = 1; i <= 10; i++) {
        const solvent = 'solvent' + i;
        const solventVol = 'solventVol' + i;

        if ((row[solvent] && !this.isBlank(row[solvent])) || (row[solventVol] && !this.isBlank(row[solventVol]))) {
          if (!(row[solvent] && !this.isBlank(row[solvent]) && row[solventVol] && !this.isBlank(row[solventVol]))) {
            // this.gridPairChk = false;
            return false;
          }
          if (row[solvent] && !this.isBlank(row[solvent]) && !row[solventVol] && !this.isBlank(row[solventVol])) {
            // this.gridPairChk = false;
            return false;
          }
          result.push({
            [solvent]: row[solvent],
            [solventVol]: row[solventVol],
          });
        }
      }
      // salt와 saltVol 페어 확인
      for (let i = 1; i <= 5; i++) {
        const salt = 'salt' + i;
        const saltVol = 'saltVol' + i;

        if ((row[salt] && !this.isBlank(row[salt])) || (row[saltVol] && !this.isBlank(row[saltVol]))) {
          if (!(row[salt] && !this.isBlank(row[salt]) && row[saltVol] && !this.isBlank(row[saltVol]))) {
            // this.gridPairChk = false;
            return false;
          }
          if (row[salt] && !this.isBlank(row[salt]) && !row[saltVol] && !this.isBlank(row[saltVol])) {
            // this.gridPairChk = false;
            return false;
          }
          result.push({
            [salt]: row[salt],
            [saltVol]: row[saltVol],
          });
        }
      }
      // this.gridPairChk = true;
      return true;
    },

    save() {
      if (this.checkPairs(this.$refs.actGrid.getJsonRow())) {
        if (!this.isExceed) {
          this.openPopup('저장하시겠습니까?', true, () => {
            try {
              let actParam = this.$refs.actGrid.getJsonRow();
              let updateSolventParam = this.$refs.grid.getJsonRow();
              if (updateSolventParam.ww == 'Y') {
                updateSolventParam.ww = 1;
              } else if (updateSolventParam.ww == 'N') {
                updateSolventParam.ww = 0;
              } else {
                updateSolventParam.ww = 0;
              }
              updateSolventParam.memberId = this.id;
              actParam.sum = Number(Math.round(actParam.sum));
              actParam.memberId = this.id;
              actParam.idx = Number(this.data.idx);
              updateSolventMaster(updateSolventParam);
              updateActualComposition(actParam);
              this.openPopup('저장되었습니다', false, () => {
                this.REMOVE_SELECT_MENU('menstruumDetail');
              });
            } catch (error) {
              console.error(error);
            }
          });
        } else {
          this.openPopup('Sum 값이 100을 초과합니다.', false, () => {
            // this.REMOVE_SELECT_MENU('menstruumDetail');
          });
        }
      } else {
        this.openPopup('성분명 또는 볼륨 값이 누락되었습니다.', false, () => {
          // this.REMOVE_SELECT_MENU('menstruumDetail');
        });
      }
    },
    reset() {
      this.openPopup('취소하시겠습니까?', true, () => {
        this.REMOVE_SELECT_MENU('menstruumDetail');
        this.SET_SELECT_MENU_TAB_BY_CODE('SAMGMT');
      });
    },
  },
  async mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    await this.getActDetail();
    this.setSumRow(this.data);
    this.actSetSumRow(this.actList);
  },
  components: {
    RealGrid,
    SetPopup,
  },
};
</script>
<style lang="scss">
.empty-area {
  height: 50px; /* Adjust the height as needed */
}
.warning-text {
  color: red;
  text-align: right;
}
</style>
