<template>
  <div class="coa">
    <SetPopup ref="confirm" />
    <!-- <h3 class="mb-2">COA 상세</h3> -->
    <div class="filter-box filter-box__row">
      <ul class="filter-list_free" style="width: 90%">
        <li>
          <h4>Lot No</h4>
          <v-text-field outlined dense disabled filled v-model="param.lotNo"></v-text-field>
        </li>
        <li>
          <h4>Code Grade</h4>
          <v-text-field outlined dense disabled filled v-model="param.code"></v-text-field>
        </li>
        <li style="width: 700px">
          <h4>품명</h4>
          <v-text-field outlined dense disabled filled v-model="param.code_title"></v-text-field>
        </li>
        <li style="width: 100px">
          <h4>수량(Qty)</h4>
          <v-text-field outlined dense disabled filled v-model="param.qty"></v-text-field>
        </li>
        <li>
          <h4>제조일</h4>
          <v-text-field outlined dense disabled filled v-model="param.produce_date"></v-text-field>
        </li>
        <li style="width: 180px">
          <h4>수령업체명</h4>
          <v-text-field outlined dense disabled filled v-model="param.pickpart"></v-text-field>
        </li>
      </ul>
      <!-- <v-row class="px-2">
        <v-col cols="12" sm="3">
          <h4>Code Grade</h4>
          <v-text-field
            outlined
            dense
            disabled
            filled
            v-model="param.code"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <h4>Lot No</h4>
          <v-text-field
            outlined
            dense
            disabled
            filled
            v-model="param.lotNo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <h4>수량(Qty)</h4>
          <v-text-field
            outlined
            dense
            disabled
            filled
            v-model="param.qty"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <h4>제조일</h4>
          <v-text-field
            outlined
            dense
            disabled
            filled
            v-model="param.produce_date"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" sm="3">
          <h4>수령업체명</h4>
          <v-text-field
            outlined
            dense
            disabled
            filled
            v-model="param.pickpart"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="9">
          <h4>품명</h4>
          <v-text-field
            outlined
            dense
            disabled
            filled
            v-model="param.code_title"
          ></v-text-field>
        </v-col>
      </v-row> -->
    </div>
    <!-- <h3 class="mt-2 mb-2">상세정보</h3> -->
    <!-- <hr class="mb-4" /> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" :nonePage="true" />
    <!-- 삭제예정_개선업무코드 [ST] -->
    <!-- <div class="filter-box filter-box--row" style="margin-top: 24px">
      <ul class="filter-list03">
        <li>
          <h4 class="">PDF 업로드</h4> -->
    <!-- <hr class="mb-4" /> -->
    <!-- <div class="dp--row">
            <v-text-field
              placeholder="파일을 선택해주세요"
              type="text"
              v-model="file.name"
              outlined
              dense
              disabled
              filled
            />
            <input
              class="d-none"
              ref="uploader"
              type="file"
              @change="onFileChanged"
            />
            <v-btn
              depressed
              class="ml-1 mr-1 btn-choice-file"
              @click="upload"
              :loading="isSelecting"
              >파일선택</v-btn
            >
          </div>
        </li>
      </ul>
      <div class="btn-box">
        <v-btn depressed class="btn-cancle" @click="reset">취소</v-btn>
        <v-btn depressed class="btn-save" @click="save"> 저장 </v-btn>
      </div>
    </div> -->
    <!-- 삭제예정_개선업무코드 [ED] -->
    <!-- 2023.05.22 개선업무코드 [ST] -->
    <div class="file-upload-container">
      <div class="file-upload-column">
        <FileUpload ref="upload1" :title="'COA 업로드'" :dropAreaHeight="dropAreaHeight" :maxFiles="4" @update:files="updateFiles('upload1', $event)" :initialFiles="files1" />
      </div>
      <div class="file-upload-column">
        <FileUpload ref="upload2" :title="'MSDS 업로드'" :dropAreaHeight="dropAreaHeight" :maxFiles="5" @update:files="updateFiles('upload2', $event)" :initialFiles="files2" />
      </div>
    </div>

    <div class="wrapper">
      <div class="btn-box">
        <v-btn depressed class="btn-cancle" @click="reset">취소</v-btn>
        <v-btn depressed class="btn-save" @click="save"> 저장 </v-btn>
      </div>
    </div>

    <!-- 2023.05.22 개선업무코드 [ED] -->
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { columns, fields, rows, height, grouping } from '@/assets/grid/coaDetail';
import RealGrid from '@/components/RealGrid.vue';
import _ from 'lodash';
import { coadetail, coaPDFupload, msdsPDFupload, coaUpdate } from 'api/sample/sample';
import { mapMutations } from 'vuex';
import { makeCOASpec } from '@/assets/grid/gridUtill';
import SetPopup from '@/components/SetPopup.vue';
import FileUpload from '@/components/FileUpload';

export default {
  props: ['data'],
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  data() {
    let coaPdfFiles = Array(5).fill({ name: '' });
    let files = Array(5).fill({ name: '' });

    for (let i = 0; i < 5; i++) {
      let fullPath = this.data['coapdf_path' + (i + 1)];
      let fileName = fullPath ? fullPath.split('/').pop() : '';
      coaPdfFiles[i] = { name: fileName };
    }

    for (let i = 0; i < 5; i++) {
      let fullPath = this.data['msdspdf_path' + (i + 1)];
      let fileName = fullPath ? fullPath.split('/').pop() : '';
      files[i] = { name: fileName };
    }

    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'coaDetail',
      id: '',
      files1: [],
      files2: [],
      dropAreaHeight: '165px',
      coaPdfFile1: coaPdfFiles[0],
      coaPdfFile2: coaPdfFiles[1],
      coaPdfFile3: coaPdfFiles[2],
      coaPdfFile4: coaPdfFiles[3],
      coaPdfFile5: coaPdfFiles[4],
      msdsFile1: files[0],
      msdsFile2: files[1],
      msdsFile3: files[2],
      msdsFile4: files[3],
      msdsFile5: files[4],
      coaNullCheck: false,
      nullCheck: false,
      settings: {
        columns,
        fields,
        rows,
        height: 585,
        grouping,
        hideCheckBar: true,
        noneNo: true,
      },
      // file: {
      //   name: this.data.coapdf_path ? this.data.coapdf_path.split('/').pop() : '',
      // },
      fileBinary: '',
      param: {
        code: this.data.code ? this.data.code : '',
        lotNo: this.data.lotNo ? this.data.lotNo : '',
        request_name: this.data.request_name ? this.data.request_name : '',
        qty: this.data.qty ? this.data.qty : '',
        produce_date: this.data.produce_date ? this.data.produce_date : '',
        pickpart: this.data.pickpart ? this.pickpart : '',
        code_title: this.data.code_title ? this.data.code_title : '',
      },
      isSelecting: false,
      isSelecting1: false,
      isSelecting2: false,
      isSelecting3: false,
      isSelecting4: false,
      isSelecting5: false,
      currentPage: 1,
    };
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('member', ['SET_USER_INFO']),
    async loadData(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        this.param.lotNo = this.data.lotNo;
        this.param.code = this.data.code;
        const res = await coadetail({
          ...this.param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = false;

        const data = res.data.data;
        this.param.qty = data.qty;
        this.param.produce_date = data.produce_date;
        this.param.pickpart = data.pickpart;
        this.param.code_title = data.code_title;

        this.coaPdfFile1.name = data.coapdf_path1;
        this.coaPdfFile2.name = data.coapdf_path2;
        this.coaPdfFile3.name = data.coapdf_path3;
        this.coaPdfFile4.name = data.coapdf_path4;
        this.coaPdfFile5.name = data.coapdf_path5;
        this.files1 = [];
        const sortedKeys1 = Object.keys(data)
          .filter(key => key.startsWith('coapdf_path'))
          .sort((a, b) => {
            const aNum = parseInt(a.replace('coapdf_path', ''), 10);
            const bNum = parseInt(b.replace('coapdf_path', ''), 10);
            return aNum - bNum;
          });
        this.files1 = sortedKeys1.map(key => ({
          name: data[key].replace('/coa_pdf/', ''),
          fromServer: true,
        }));

        this.msdsFile1.name = data.msdspdf_path1;
        this.msdsFile2.name = data.msdspdf_path2;
        this.msdsFile3.name = data.msdspdf_path3;
        this.msdsFile4.name = data.msdspdf_path4;
        this.msdsFile5.name = data.msdspdf_path5;
        this.files2 = [];
        const sortedKeys2 = Object.keys(data)
          .filter(key => key.startsWith('msdspdf_path'))
          .sort((a, b) => {
            const aNum = parseInt(a.replace('msdspdf_path', ''), 10);
            const bNum = parseInt(b.replace('msdspdf_path', ''), 10);
            return aNum - bNum;
          });
        this.files2 = sortedKeys2.map(key => ({
          name: data[key].replace('/msds_pdf/', ''),
          fromServer: true,
        }));
        this.$refs.grid.loadData(data.items);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    reset() {
      this.openPopup('취소하시겠습니까?', true, () => {
        this.REMOVE_SELECT_MENU('coaDetail');
        // this.file = {};
        this.fileBinary = '';
        this.$refs.uploader.value = null;
        this.loadData();
      });
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    updateFiles(uploadId, files) {
      if (uploadId === 'upload1') {
        this.files1 = files;
      } else if (uploadId === 'upload2') {
        this.files2 = files;
      }
    },
    save() {
      this.openPopup('저장하시겠습니까?', true, this.saveExec);
    },
    async saveExec() {
      let coaUploaded = false;
      try {
        let next = 1;
        const coaFrm = new FormData();
        const msdsFrm = new FormData();

        const coaFileKeys = ['coaPdfFile1', 'coaPdfFile2', 'coaPdfFile3', 'coaPdfFile4', 'coaPdfFile5'];
        for (const file of this.files1) {
          if (!file.fromServer) {
            this[`coaPdfFile${next}`] = file;
          }
          next++;
        }

        if (!this.coaPdfFile1.name && !this.coaPdfFile2.name && !this.coaPdfFile3.name && !this.coaPdfFile4.name && !this.coaPdfFile5.name) {
          this.coaNullCheck = true;
        }
        coaFrm.append('lotNo', this.data.lotNo);
        coaFrm.append('memberId', this.id);
        coaFrm.append('coaNullCheck', this.coaNullCheck);
        let coaFileUploaded = false;
        for (const fileKey of coaFileKeys) {
          const file = this[fileKey];
          if (file.name === '' && typeof file === 'object') {
            coaFileUploaded = true;
            coaFrm.append(fileKey, file);
          }
          if (!file.name) {
            coaFileUploaded = true;
            coaFrm.append(fileKey, file);
          }
          if (file.size) {
            coaFileUploaded = true;
            coaFrm.append(fileKey, file);
          }
        }
        if (coaFileUploaded) {
          await coaPDFupload(coaFrm);
        }
        let coaFileNames = [];
        for (const item of this.files1) {
          if (item instanceof File) {
            coaFileNames.push(item.name);
          } else if (item && typeof item === 'object' && item.name) {
            coaFileNames.push(item.name);
          }
        }
        coaUploaded = true;

        // msds 업로드
        const fileKeys = ['msdsFile1', 'msdsFile2', 'msdsFile3', 'msdsFile4', 'msdsFile5'];
        next = 1;
        for (const file of this.files2) {
          if (!file.fromServer) {
            this[`msdsFile${next}`] = file;
          }
          next++;
        }
        if (!this.msdsFile1.name && !this.msdsFile2.name && !this.msdsFile3.name && !this.msdsFile4.name && !this.msdsFile5.name) {
          this.nullCheck = true;
        }
        msdsFrm.append('lotNo', this.data.lotNo);
        msdsFrm.append('memberId', this.id);
        msdsFrm.append('nullCheck', this.nullCheck);
        let msdsFileUploaded = false;
        for (const fileKey of fileKeys) {
          const file = this[fileKey];
          if (file.name === '' && typeof file === 'object') {
            msdsFileUploaded = true;
            msdsFrm.append(fileKey, file);
          }
          if (!file.name) {
            msdsFileUploaded = true;
            msdsFrm.append(fileKey, file);
          }
          if (file.size) {
            msdsFileUploaded = true;
            msdsFrm.append(fileKey, file);
          }
        }
        if (msdsFileUploaded) {
          await msdsPDFupload(msdsFrm);
        }
        let msdsFileNames = [];
        for (const item of this.files2) {
          if (item instanceof File) {
            msdsFileNames.push(item.name);
          } else if (item && typeof item === 'object' && item.name) {
            msdsFileNames.push(item.name);
          }
        }
        if (coaUploaded) {
          const dt = this.$refs.grid.getJsonAllRow(); // 모든행 가져옴
          const sampleDetail = makeCOASpec(dt, this.data.lotNo, this.data.code);
          await coaUpdate({
            memberId: this.id,
            lotNo: this.data.lotNo,
            coaFileNames: coaFileNames,
            msdsFileNames: msdsFileNames,
            results: sampleDetail,
          });
        }
        this.openPopup(`저장되었습니다`, false, () => {
          this.REMOVE_SELECT_MENU('coaDetail');
          this.SET_SELECT_MENU_TAB_BY_CODE('COMGMT');
        });
      } catch (error) {
        this.openPopup(`관리자에게 문의하세요 :${error}`);
      }
    },
    read() {},
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.loadData();
  },
  components: {
    RealGrid,
    SetPopup,
    FileUpload,
  },
};
</script>
<style lang="scss">
.coa {
  .row {
    height: 75px;
  }
}
.file-upload-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.file-upload-column {
  width: 48%; /* 50% - some margin for better spacing */
}
.btn-box {
  margin-bottom: 20px;
}
</style>
