<template>
  <div class="menstrumm">
    <h3 class="mb-2">용매조성 등록</h3>
    <SetPopup ref="confirm" />
    <v-form lazy-validation ref="form">
      <div class="filter-box filter-box__add">
        <h4 class="title_sub02">solvent</h4>
        <ul class="filter-list03" style="margin-bottom: 30px">
          <li cols="12" class="row_input" v-for="(solVentCol, index) in solventList.length / 2" :key="solVentCol">
            <div>
              <template>
                <h4>{{ index + 1 }}</h4>
                <v-text-field :class="{ line_red: requiredList.includes('solvent' + (index + 1)) }" outlined dense v-model="menstParam['solvent' + (index + 1)]"></v-text-field>
              </template>
            </div>
            <div>
              <template>
                <h4>{{ 'Vol%' }}</h4>
                <v-text-field :class="{ line_red: requiredList.includes('solventVol' + (index + 1)) }" outlined dense :rules="[validSet.menstNumberDot]" v-model="menstParam['solventVol' + (index + 1)]"></v-text-field>
              </template>
            </div>
          </li>
        </ul>
        <h4 class="title_sub02">salt</h4>
        <ul class="filter-list03" style="margin-bottom: 30px">
          <li cols="12" class="row_input" v-for="(saltCol, index) in saltList.length / 2" :key="saltCol">
            <div>
              <template>
                <h4>{{ index + 1 }}</h4>
                <v-text-field :class="{ line_red: requiredList.includes('salt' + (index + 1)) }" outlined dense v-model="menstParam['salt' + (index + 1)]"></v-text-field>
              </template>
            </div>
            <div>
              <template>
                <h4>{{ 'M,wt%' }}</h4>
                <v-text-field :class="{ line_red: requiredList.includes('saltVol' + (index + 1)) }" :rules="[validSet.menstNumberDot]" outlined dense v-model="menstParam['saltVol' + (index + 1)]"></v-text-field>
              </template>
            </div>
          </li>
        </ul>
        <h4 class="title_sub02">기타정보</h4>
        <ul class="filter-list03" style="margin-bottom: 30px">
          <li>
            <h4>w/w</h4>
            <v-select :items="['Y', 'N']" v-model="etcInfoList.ww" outlined style="width: 100%"></v-select>
          </li>
          <li cols="12" class="row_input">
            <div>
              <template>
                <h4>{{ '밀도' }}</h4>
                <v-text-field outlined dense v-model="etcInfoList.density" maxlength="10"></v-text-field>
              </template>
            </div>
            <div>
              <template>
                <h4>{{ '비고' }}</h4>
                <v-text-field outlined dense v-model="etcInfoList.note"></v-text-field>
              </template>
            </div>
          </li>
        </ul>
      </div>
      <h3 class="mb-2">실제조성 정보</h3>
      <div class="filter-box filter-box__add">
        <h4 class="title_sub02">solvent</h4>
        <ul class="filter-list03" style="margin-bottom: 30px">
          <li cols="12" class="row_input" v-for="(actSolventCol, index) in actSolventList.length / 2" :key="actSolventCol">
            <div>
              <template>
                <h4>{{ index + 1 }}</h4>
                <v-text-field :class="{ line_red: actRequiredList.includes('solvent' + (index + 1)) }" outlined dense v-model="actParam['solvent' + (index + 1)]"></v-text-field>
              </template>
            </div>
            <div>
              <template>
                <h4>{{ 'wt%' }}</h4>
                <v-text-field :class="{ line_red: actRequiredList.includes('solventVol' + (index + 1)) }" :rules="[validSet.menstNumberDot]" outlined dense v-model="actParam['solventVol' + (index + 1)]"></v-text-field>
              </template>
            </div>
          </li>
        </ul>
        <h4 class="title_sub02">salt</h4>
        <ul class="filter-list03" style="margin-bottom: 30px">
          <li cols="12" class="row_input" v-for="(actSaltCol, index) in actSaltList.length / 2" :key="actSaltCol">
            <div>
              <template>
                <h4>{{ index + 1 }}</h4>
                <v-text-field :class="{ line_red: actRequiredList.includes('salt' + (index + 1)) }" outlined dense v-model="actParam['salt' + (index + 1)]"></v-text-field>
              </template>
            </div>
            <div>
              <template>
                <h4>{{ 'wt%' }}</h4>
                <v-text-field :class="{ line_red: actRequiredList.includes('saltVol' + (index + 1)) }" :rules="[validSet.menstNumberDot]" outlined dense v-model="actParam['saltVol' + (index + 1)]"></v-text-field>
              </template>
            </div>
          </li>
        </ul>
        <div class="result_box">
          <strong>SUM</strong>
          <span>{{ actSum }}</span>
          <p class="warning-text" v-if="isExceed">100%가 초과됩니다.</p>
        </div>
      </div>
    </v-form>
    <div class="wrapper">
      <div class="btn-box">
        <v-card-actions>
          <v-btn depressed outlined @click="cancle">초기화</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed color="primary" @click="save">저장</v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { insertSolventMaster } from 'api/solvent/solvent';
import { insertActualCompositionList } from 'api/solvent/solvent';
import * as menstrumm from '@/assets/grid/menstrummAdd';
import * as actualAdd from '@/assets/grid/actualCompositionAdd';
import validSet from '@/assets/valid';
import _ from 'lodash';
import SetPopup from '@/components/SetPopup.vue';
export default {
  data() {
    return {
      settingInput: '',
      menstParam: [],
      inputValue: '',
      gridPairChk: true,
      id: '',
      requiredList: [],
      actRequiredList: [],
      actResult: [],
      validMenstParam: [],
      solventPairs: [{ solvent: '', solventVol: '' }],
      actParam: [],
      emptyFields: [],
      validSet,
      isExceed: false,
      solventList: [],
      saltList: [],
      actSolventList: [],
      actSaltList: [],
      etcInfoList: {
        ww: '',
        density: '',
        note: '',
      },
      grid: 'menstrummDetail',
      settings: menstrumm,
      menstrummLayout: menstrumm.columns,
      actualAddLayout: actualAdd.columns,
      col: 4,
      param: {},
      bindKey: [],
      select: [
        {
          name: 'Y',
          value: '1',
        },
        {
          name: 'N',
          value: '0',
        },
      ],
    };
  },
  watch: {
    actSum(sumValue) {
      this.isExceed = sumValue > 100;
    },
  },
  methods: {
    ...mapMutations('menu', ['REMOVE_SELECT_MENU', 'SET_SELECT_MENU_TAB_BY_CODE']),
    ...mapMutations('popup', ['SET_POPUP']),
    ...mapMutations('member', ['SET_USER_INFO']),
    convertW() {
      this.etcInfoList.ww == 'Y' ? (this.etcInfoList.ww = 1) : (this.etcInfoList.ww = 0);
    },
    init() {
      this.menstrummLayout.forEach(row => {
        if (row.fieldName.includes('solvent')) {
          this.solventList.push({
            row,
          });
        } else if (row.fieldName.includes('salt')) {
          this.saltList.push({
            row,
          });
        }
      });
      this.actualAddLayout.forEach(row => {
        if (row.fieldName.includes('solvent')) {
          this.actSolventList.push({ row });
        } else if (row.fieldName.includes('salt')) {
          this.actSaltList.push({ row });
        }
      });
    },
    newSample() {
      this.$emit('newSample');
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        closable,
        text,
      });
      this.$refs.confirm.openPopup(cb);
    },
    checkGridPair() {
      let menstSolventInfo = this.menstParam;

      for (let i = 1; i <= 10; i++) {
        const solvent = 'solvent' + i;
        const solventVol = 'solventVol' + i;

        if ((menstSolventInfo[solvent] && !this.isBlank(menstSolventInfo[solvent])) || (menstSolventInfo[solventVol] && !this.isBlank(menstSolventInfo[solventVol]))) {
          if (!(menstSolventInfo[solvent] && !this.isBlank(menstSolventInfo[solvent]) && menstSolventInfo[solventVol] && !this.isBlank(menstSolventInfo[solventVol]))) {
            this.gridPairChk = false;
            return;
          }
          if (menstSolventInfo[solvent] && !this.isBlank(menstSolventInfo[solvent]) && !menstSolventInfo[solventVol] && !this.isBlank(menstSolventInfo[solventVol])) {
            this.gridPairChk = false;
            return;
          }
        }
        this.gridPairChk = true;
      }
      // salt와 saltVol 페어 확인
      for (let i = 1; i <= 5; i++) {
        const salt = 'salt' + i;
        const saltVol = 'saltVol' + i;

        if ((menstSolventInfo[salt] && !this.isBlank(menstSolventInfo[salt])) || (menstSolventInfo[saltVol] && !this.isBlank(menstSolventInfo[saltVol]))) {
          if (!(menstSolventInfo[salt] && !this.isBlank(menstSolventInfo[salt]) && menstSolventInfo[saltVol] && !this.isBlank(menstSolventInfo[saltVol]))) {
            this.gridPairChk = false;
            return;
          }
          if (menstSolventInfo[salt] && !this.isBlank(menstSolventInfo[salt]) && !menstSolventInfo[saltVol] && !this.isBlank(menstSolventInfo[saltVol])) {
            this.gridPairChk = false;
            return;
          }
        }
        this.gridPairChk = true;
      }
      //
      let actSolventInfo = this.actParam;

      for (let i = 1; i <= 10; i++) {
        const solvent = 'solvent' + i;
        const solventVol = 'solventVol' + i;

        if ((actSolventInfo[solvent] && !this.isBlank(actSolventInfo[solvent])) || (actSolventInfo[solventVol] && !this.isBlank(actSolventInfo[solventVol]))) {
          if (!(actSolventInfo[solvent] && !this.isBlank(actSolventInfo[solvent]) && actSolventInfo[solventVol] && !this.isBlank(actSolventInfo[solventVol]))) {
            this.gridPairChk = false;
            return;
          }
          if (actSolventInfo[solvent] && !this.isBlank(actSolventInfo[solvent]) && !actSolventInfo[solventVol] && !this.isBlank(actSolventInfo[solventVol])) {
            this.gridPairChk = false;
            return;
          }
        }
        this.gridPairChk = true;
      }
      // salt와 saltVol 페어 확인
      for (let i = 1; i <= 5; i++) {
        const salt = 'salt' + i;
        const saltVol = 'saltVol' + i;

        if ((actSolventInfo[salt] && !this.isBlank(actSolventInfo[salt])) || (actSolventInfo[saltVol] && !this.isBlank(actSolventInfo[saltVol]))) {
          if (!(actSolventInfo[salt] && !this.isBlank(actSolventInfo[salt]) && actSolventInfo[saltVol] && !this.isBlank(actSolventInfo[saltVol]))) {
            this.gridPairChk = false;
            return;
          }
          if (actSolventInfo[salt] && !this.isBlank(actSolventInfo[salt]) && !actSolventInfo[saltVol] && !this.isBlank(actSolventInfo[saltVol])) {
            this.gridPairChk = false;
            return;
          }
        }
        this.gridPairChk = true;
      }
    },
    ventAndVolValid() {
      let menstSolventInfo = this.menstParam;
      this.requiredList = [];
      this.actRequiredList = [];
      this.actResult = [];
      const result = [];

      for (let i = 1; i <= 10; i++) {
        const solvent = 'solvent' + i;
        const solventVol = 'solventVol' + i;

        if ((menstSolventInfo[solvent] && !this.isBlank(menstSolventInfo[solvent])) || (menstSolventInfo[solventVol] && !this.isBlank(menstSolventInfo[solventVol]))) {
          if (!(menstSolventInfo[solvent] && !this.isBlank(menstSolventInfo[solvent]) && menstSolventInfo[solventVol] && !this.isBlank(menstSolventInfo[solventVol]))) {
            // this.gridPairChk = false;
            result.push({
              [solvent]: menstSolventInfo[solvent],
              [solventVol]: menstSolventInfo[solventVol],
            });
          }
          if (menstSolventInfo[solvent] && !this.isBlank(menstSolventInfo[solvent]) && !menstSolventInfo[solventVol] && !this.isBlank(menstSolventInfo[solventVol])) {
            // this.gridPairChk = false;
            result.push({
              [solvent]: menstSolventInfo[solvent],
              [solventVol]: menstSolventInfo[solventVol],
            });
          }
        }
        this.gridPairChk = true;
      }
      // salt와 saltVol 페어 확인
      for (let i = 1; i <= 5; i++) {
        const salt = 'salt' + i;
        const saltVol = 'saltVol' + i;

        if ((menstSolventInfo[salt] && !this.isBlank(menstSolventInfo[salt])) || (menstSolventInfo[saltVol] && !this.isBlank(menstSolventInfo[saltVol]))) {
          if (!(menstSolventInfo[salt] && !this.isBlank(menstSolventInfo[salt]) && menstSolventInfo[saltVol] && !this.isBlank(menstSolventInfo[saltVol]))) {
            // this.gridPairChk = false;
            result.push({
              [salt]: menstSolventInfo[salt],
              [saltVol]: menstSolventInfo[saltVol],
            });
          }
          if (menstSolventInfo[salt] && !this.isBlank(menstSolventInfo[salt]) && !menstSolventInfo[saltVol] && !this.isBlank(menstSolventInfo[saltVol])) {
            // this.gridPairChk = false;
            result.push({
              [salt]: menstSolventInfo[salt],
              [saltVol]: menstSolventInfo[saltVol],
            });
          }
        }
        // this.gridPairChk = true;
      }
      //
      let actSolventInfo = this.actParam;

      for (let i = 1; i <= 10; i++) {
        const solvent = 'solvent' + i;
        const solventVol = 'solventVol' + i;

        if ((actSolventInfo[solvent] && !this.isBlank(actSolventInfo[solvent])) || (actSolventInfo[solventVol] && !this.isBlank(actSolventInfo[solventVol]))) {
          if (!(actSolventInfo[solvent] && !this.isBlank(actSolventInfo[solvent]) && actSolventInfo[solventVol] && !this.isBlank(actSolventInfo[solventVol]))) {
            // this.gridPairChk = false;
            this.actResult.push({
              [solvent]: actSolventInfo[solvent],
              [solventVol]: actSolventInfo[solventVol],
            });
          }
          if (actSolventInfo[solvent] && !this.isBlank(actSolventInfo[solvent]) && !actSolventInfo[solventVol] && !this.isBlank(actSolventInfo[solventVol])) {
            // this.gridPairChk = false;
            this.actResult.push({
              [solvent]: actSolventInfo[solvent],
              [solventVol]: actSolventInfo[solventVol],
            });
          }
        }
        this.gridPairChk = true;
      }
      // salt와 saltVol 페어 확인
      for (let i = 1; i <= 5; i++) {
        const salt = 'salt' + i;
        const saltVol = 'saltVol' + i;

        if ((actSolventInfo[salt] && !this.isBlank(actSolventInfo[salt])) || (actSolventInfo[saltVol] && !this.isBlank(actSolventInfo[saltVol]))) {
          if (!(actSolventInfo[salt] && !this.isBlank(actSolventInfo[salt]) && actSolventInfo[saltVol] && !this.isBlank(actSolventInfo[saltVol]))) {
            // this.gridPairChk = false;
            this.actResult.push({
              [salt]: actSolventInfo[salt],
              [saltVol]: actSolventInfo[saltVol],
            });
          }
          if (actSolventInfo[salt] && !this.isBlank(actSolventInfo[salt]) && !actSolventInfo[saltVol] && !this.isBlank(actSolventInfo[saltVol])) {
            // this.gridPairChk = false;
            this.actResult.push({
              [salt]: actSolventInfo[salt],
              [saltVol]: actSolventInfo[saltVol],
            });
          }
        }
        // this.gridPairChk = true;
      }
      return result;
    },
    valid(type) {
      if (type === 'save') {
        if (this.actSum > 100) {
          this.openPopup('SUM값이 100을 초과합니다.', false, () => {});
          return false;
        } else {
          return true;
        }
      }

      // 검사 통과 시 저장 작업 수행
      // ... (저장 작업 코드)
    },
    isBlank(str) {
      return !str || /^\s*$/.test(str);
      // null, undefined, "" 경우 true 반환
    },
    isValidValue(value) {
      return value !== '';
    },
    checkForEmptyFields() {
      this.emptyFields = [];

      // Check for empty fields in the solvent and salt sections
      for (let index = 0; index < this.solventList.length / 2; index++) {
        const solventValue = this.menstParam['solvent' + (index + 1)];
        const saltValue = this.menstParam['salt' + (index + 1)];

        if (!solventValue) {
          this.emptyFields.push('solvent' + (index + 1));
        }
        if (!saltValue) {
          this.emptyFields.push('salt' + (index + 1));
        }
      }
    },
    test() {
      return this.$refs.form.validate();
    },
    save() {
      this.checkGridPair();
      if (this.test()) {
        if (!this.gridPairChk) {
          this.openPopup('성분명 또는 Vol 값이 누락되었습니다.', false, () => {
            const validCheck = this.ventAndVolValid();
            validCheck.forEach(subObject => {
              for (const key in subObject) {
                if (subObject[key] === undefined) {
                  this.requiredList.push(key);
                }
              }
            });
            this.actResult.forEach(subObject => {
              for (const key in subObject) {
                if (subObject[key] === undefined) {
                  this.actRequiredList.push(key);
                }
              }
            });
            this.checkGridPair();
          });
          return;
        } else if (this.actSum > 100) {
          this.openPopup('SUM값이 100을 초과합니다.', false, () => {});
          return;
        } else {
          this.openPopup('저장하시겠습니까?', true, () => {
            insertSolventMaster({ ...this.menstParam, ...this.etcInfoList, memberId: this.id })
              .then(() => {
                return insertActualCompositionList({ ...this.actParam, memberId: this.id });
              })
              .then(() => {
                this.openPopup('저장되었습니다', false, () => {
                  this.REMOVE_SELECT_MENU('menstruumAdd');
                  this.SET_SELECT_MENU_TAB_BY_CODE('SAMGMT');
                });
              })
              .catch(() => {});
          });
        }
      }
    },
    cancle() {
      this.openPopup('초기화 하시겠습니까?', true, () => {
        this.setData();
      });
    },
    item(idx, i) {
      const index = (idx - 1) * this.col + i - 1;
      if (index < this.layout.length) {
        const ret = this.layout;
        return ret.header.text;
      } else {
        return;
      }
    },
    setData() {
      const obj = {};
      const key = [];
      _.each(this.settings.fields, v => {
        if (v.fieldName === 'SUM1') {
          key.push('sum');
          obj.sum = '';
        } else if (v.fieldName === '비고1') {
          key.push('memo');
          obj.memo = '';
        } else if (v.fieldName === '밀도1') {
          key.push('mildo');
          obj.mildo = '';
        } else {
          key.push(v.fieldName);
          obj[v.fieldName] = '';
        }
      });
      this.bindKey = key;
      this.param = obj;
      this.menstParam = [];
      this.actParam = [];
      this.etcInfoList = {
        ww: '',
        density: '',
        note: '',
      };
    },
  },
  computed: {
    actSum() {
      const actVolValues = Object.keys(this.actParam)
        .filter(key => key.includes('Vol'))
        .map(key => parseFloat(this.actParam[key]) || 0);

      const sum = actVolValues.reduce((acc, curr) => acc + curr, 0);
      return sum.toFixed(2);
    },
  },
  components: {
    SetPopup,
  },
  filters: {
    rm(v) {
      const idx = _.indexOf(v, '%');
      if (idx > -1) {
        return v.slice(0, idx + 1);
      } else {
        return v;
      }
    },
  },
  mounted() {
    this.id = this.$store.state.member.userInfo.memberId;
    this.setData();
  },
  created() {
    this.init();
  },
};
</script>
<style lang="scss">
.warning-text {
  color: red;
}
// .menstrumm {
// .row {
//   margin-top: 0px;
// }
// }

.v-input--is-disabled .v-messages {
  color: red !important;
}
</style>
