import _ from 'lodash';
import { makeCell } from './gridUtill';
// import config from 'api/config';
import pdfIcon from '../../assets/img/icon_pdf.png';

let fields = [];
let columns = [];
const data = [
  {
    field: 'lotNo',
    alias: 'Lot No',
  },
  {
    field: 'code',
    alias: 'Code Grade',
  },
  {
    field: 'pick_name',
    alias: '수령자',
  },
  {
    field: 'pickpart',
    alias: '수령업체명',
  },
  {
    field: 'request_date',
    alias: '요청일',
  },
  {
    field: 'produce_due_date',
    alias: '제조예정일',
  },
  {
    field: 'produce_date',
    alias: '제조완료일',
  },
  {
    field: 'out_due_date',
    alias: '출하예정일',
  },
  {
    field: 'out_date',
    alias: '출하완료일',
  },
  {
    field: 'delivery_date',
    alias: '납기일',
  },
  {
    field: 'stat_str',
    alias: '상태',
  },
  {
    field: 'coapdf_path',
    alias: 'COA',
    urlCallback: true,
    width: 180,
  },
  {
    field: 'msdspdf_path',
    alias: 'MSDS',
    urlCallback: true,
    width: 180,
  },
];
_.each(data, function (o) {
  o.indexExclusive = true;

  if (o.urlCallback) {
    makeCell(1, [o], fields, columns, null, null, {
      type: 'html',
      callback: function (grid, cell) {
        if (cell.valueColumn.fieldName && cell.valueColumn.fieldName === 'coapdf_path') {
          if (cell.value == undefined || cell.value == '') {
            return `<a href="#"></a>`;
          } else {
            let pdfPaths = cell.value.split(',');
            let pdfIcons = `<div class="icons">`;
            let nonEmptyPdfPaths = pdfPaths.filter(path => path.trim() !== '');
            if (nonEmptyPdfPaths.length > 0) {
              pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
              if (nonEmptyPdfPaths.length > 1) {
                pdfIcons += `<span class="icon_num">` + nonEmptyPdfPaths.length + `</span>`;
              }
            }
            pdfIcons += `</div>`;
            return pdfIcons;
            // return `<div class="icons"><img src="${pdfIcon}" alt="pdf icon" /></div>`;
          }
        } else if (cell.valueColumn.fieldName && cell.valueColumn.fieldName === 'msdspdf_path') {
          if (cell.value == undefined || cell.value == '') {
            return `<a href="#"></a>`;
          } else {
            let pdfPaths = cell.value.split(',');

            let pdfIcons = `<div class="icons">`;

            // 아이콘 파일로
            // pdfPaths.forEach((pdfPath, i)=>{
            //   if (pdfPath.trim() !== "") {
            //     pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
            //   }
            // });

            // 아이콘 곱하기
            let nonEmptyPdfPaths = pdfPaths.filter(path => path.trim() !== '');
            if (nonEmptyPdfPaths.length > 0) {
              pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
              if (nonEmptyPdfPaths.length > 1) {
                pdfIcons += `<span class="icon_num">` + nonEmptyPdfPaths.length + `</span>`;
              }
            }
            pdfIcons += `</div>`;
            return pdfIcons;
          }
        }
      },
    });
  } else {
    makeCell(1, [o], fields, columns);
  }
});

export { fields, columns };
