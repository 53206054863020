import _ from 'lodash';
import { makeCell, makeRowSet } from './gridUtill';
// import config from 'api/config';
import pdfIcon from '../../assets/img/icon_pdf.png';

let fields = [];
let columns = [];
const data = [
  {
    field: 'lotNo',
    alias: 'Lot No',
  },
  {
    field: 'code',
    alias: 'Code Grade',
  },
  {
    field: 'ww',
    alias: 'w/w',
  },
  {
    field: 'code_title',
    alias: '품명',
  },
  {
    field: 'request_company',
    alias: '요청업체명',
  },
  {
    field: 'request_name',
    alias: '요청자',
  },
  {
    field: 'delivery_date',
    alias: '납기일',
  },
  {
    field: 'qty',
    alias: '제품수량(kg)',
  },
  {
    field: 'stat',
    alias: '접수 상태',
  },
  {
    field: 'producestat',
    alias: '제조 진행 사항',
  },
  {
    field: 'releasestat',
    alias: '출하 사항',
  },
  {
    field: 'coapdf_path',
    alias: 'COA',
    urlCallback: true,
    width: 180,
  },
  {
    field: 'msdspdf_path',
    alias: 'MSDS',
    urlCallback: true,
    width: 180,
  },
];
_.each(data, function (o) {
  o.indexExclusive = true;

  if (o.urlCallback) {
    makeCell(1, [o], fields, columns, null, null, {
      type: 'html',
      callback: function (grid, cell) {
        if (cell.valueColumn.fieldName && cell.valueColumn.fieldName === 'coapdf_path') {
          if (cell.value == undefined) {
            return `<a href="#"></a>`;
          } else {
            // return `<div class="icons"><img src="${pdfIcon}" alt="pdf icon" /></div>`;
            let pdfPaths = cell.value.split(',');
            let pdfIcons = `<div class="icons">`;

            // 아이콘 곱하기
            let nonEmptyPdfPaths = pdfPaths.filter(path => path.trim() !== '');
            if (nonEmptyPdfPaths.length > 0) {
              pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
              if (nonEmptyPdfPaths.length > 1) {
                pdfIcons += `<span class="icon_num">` + nonEmptyPdfPaths.length + `</span>`;
              }
            }
            pdfIcons += `</div>`;
            return pdfIcons;
          }
        } else if (cell.valueColumn.fieldName && cell.valueColumn.fieldName === 'msdspdf_path') {
          if (cell.value == undefined) {
            return `<a href="#"></a>`;
          } else {
            let pdfPaths = cell.value.split(',');
            let pdfIcons = `<div class="icons">`;

            // 아이콘 곱하기
            let nonEmptyPdfPaths = pdfPaths.filter(path => path.trim() !== '');
            if (nonEmptyPdfPaths.length > 0) {
              pdfIcons += `<img src="${pdfIcon}" alt="pdf icon" />`;
              if (nonEmptyPdfPaths.length > 1) {
                pdfIcons += `<span class="icon_num">` + nonEmptyPdfPaths.length + `</span>`;
              }
            }
            pdfIcons += `</div>`;
            return pdfIcons;
          }
        }
      },
    });
  } else {
    makeCell(1, [o], fields, columns);
  }
});

const rowSet = makeRowSet(fields);
const errorMessage = '요청하신 샘플 내역이 없습니다';
export { fields, columns, rowSet, errorMessage };
