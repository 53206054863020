<template>
  <div class="address newSample">
    <SetPopup ref="confirm" />
    <Address ref="address" @select="onAddress" p:userId="userId" />
    <!-- <h3 class="mb-2">신규 샘플 요청</h3> -->

    <div class="filter-box filter-box--row">
      <ul class="filter-list add-btn-filter-list">
        <li>
          <h4>파일 업로드</h4>
          <div class="dp--row justify-content-between">
            <div class="dp--row add-btn">
              <v-text-field placeholder="파일을 선택해주세요" type="text" v-model="file.name" outlined dense disabled filled />
              <input class="d-none" ref="uploader" type="file" @change="onFileChanged" />
              <v-btn depressed class="mr-1 ml-1 btn-choice-file" @click="upload" :loading="isSelecting">파일선택</v-btn>
              <!-- <v-btn depressed class="mr-1 btn-load" @click="read">템플릿 다운</v-btn> -->
              <v-btn depressed class="btn-sample" @click="downloadSample">템플릿 다운</v-btn>
            </div>
          </div>
          <v-btn depressed class="mr-1 btn-load" id="one_speed_add" @click="addGridRow"><span class="text">+</span> 빠른 등록</v-btn>
        </li>
      </ul>
    </div>

    <RealGrid :domName="grid" ref="grid" :settings="settings" @click="checkRow" />
    <template v-if="checkRows">
      <v-form lazy-validation ref="newSample">
        <!-- <h3 class="mt-2 mb-2">추가 정보</h3> -->
        <!-- <hr class="mb-4" /> -->
        <div class="filter-box--row mt-6">
          <div class="filter-box" style="padding-bottom: 62px">
            <ul class="filter-list02">
              <li>
                <h4>요청업체명</h4>
                <v-text-field ref="request_company" outlined dense v-model="param.request_company"></v-text-field>
              </li>
              <li>
                <h4>요청자*</h4>
                <v-text-field ref="request_name" outlined dense v-model="param.request_name" :rules="[this.validSet.empty(param.request_name, '요청자 이름을 입력해주세요'), this.validSet.name]"></v-text-field>
              </li>
              <li>
                <h4>요청자 연락처 *<span style="color: blue; font-weight: bold">(- 포함입력)</span></h4>
                <v-text-field ref="phone1" outlined dense v-model="param.phone1" placeholder="- 를 포함 입력" :rules="[this.validSet.phoneRules(true, param.phone1)]" minlength="11" maxlength="13"></v-text-field>
              </li>
            </ul>
            <ul class="filter-list04" style="margin-top: 30px">
              <li>
                <h4>수령업체명</h4>
                <div class="dp--row">
                  <div class="" style="height: 25px; margin-right: 10px">
                    <v-checkbox v-model="param.same2">
                      <template v-slot:label>
                        <h5>요청업체명과 동일</h5>
                      </template></v-checkbox
                    >
                  </div>

                  <v-text-field ref="pick_company" outlined dense placeholder="" v-model="param.pick_company"></v-text-field>
                </div>
              </li>
              <li>
                <h4>수령자*</h4>
                <div class="dp--row">
                  <div class="" style="height: 25px; margin-right: 10px">
                    <v-checkbox v-model="param.same">
                      <template v-slot:label>
                        <h5>요청자와 동일</h5>
                      </template></v-checkbox
                    >
                  </div>
                  <v-text-field ref="pick_name" outlined dense placeholder="" v-model="param.pick_name" :rules="[this.validSet.empty(param.pick_name, '수령자 이름을 입력해주세요'), this.validSet.name]"></v-text-field>
                </div>
              </li>
            </ul>
            <ul class="filter-list04" style="margin-top: 30px">
              <li>
                <h4>수령자 연락처<span style="color: blue; font-weight: bold">(- 포함입력)</span></h4>
                <v-text-field ref="phone2" outlined dense v-model="param.phone2" placeholder="- 를 포함 입력" :rules="[this.validSet.phoneRules(false, param.phone2)]" minlength="11" maxlength="13"></v-text-field>
              </li>
              <li>
                <h4>요청자 이메일</h4>
                <v-text-field ref="memberId" outlined dense v-model="param.memberId" :rules="[this.validSet.email]"></v-text-field>
              </li>
            </ul>
            <ul class="filter-list_address">
              <li class="dp--collums" style="margin-top: 30px">
                <h4>배송지 선택</h4>
                <div class="dp--row" style="width: 100%; align-items: flex-start">
                  <v-text-field ref="address" placeholder="" type="text" outlined dense disabled filled v-model="param.address" :rules="[this.validSet.empty(param.address, '주소를 입력해주세요')]" />
                  <v-btn depressed class="ml-1 btn-address-search" @click="searchAddress">주소검색</v-btn>
                </div>
              </li>
              <li style="margin-top: 10px">
                <h4 class="">상세주소*</h4>
                <v-text-field ref="address2" placeholder="" type="text" outlined dense v-model="param.address2" :rules="[this.validSet.empty(param.address2, '상세 주소를 입력해주세요')]" />
              </li>
            </ul>
          </div>
          <div class="filter-box">
            <ul class="filter-list02">
              <li>
                <h4>요청 자재코드</h4>
                <v-text-field ref="request_code" outlined dense placeholder="" v-model="param.request_code"></v-text-field>
              </li>
              <li>
                <h4>Qty(kg)*</h4>
                <v-text-field ref="qty" outlined dense placeholder="" v-model="param.qty" :rules="[this.validSet.empty(param.qty, 'Qty를 입력해주세요'), this.validSet.numberDot]"></v-text-field>
              </li>
              <li>
                <h4>유무상</h4>
                <v-select ref="price_type" :items="code.P" v-model="param.price_type" placeholder="선택해주세요" outlined id="work"></v-select>
              </li>
              <li>
                <h4>납기일</h4>
                <v-menu v-model="picker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field ref="delivery_date" v-model="param.delivery_date" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker ref="delivery_date" no-title @input="picker = false" v-model="param.delivery_date"></v-date-picker>
                </v-menu>
              </li>
              <li>
                <h4>배송방법</h4>
                <v-select ref="delivery_type" :items="code.D" v-model="param.delivery_type" placeholder="선택해주세요" outlined id="work"></v-select>
              </li>
              <li>
                <h4>포장용기</h4>
                <v-select ref="packing" :items="code.C" v-model="param.packing" placeholder="선택해주세요" outlined id="work"></v-select>
              </li>
            </ul>
            <ul class="filter-list_address">
              <li style="margin-top: 30px">
                <h4>분석 요청사항</h4>
                <v-text-field ref="analysis" outlined dense placeholder="선택해주세요" v-model="param.analysis"></v-text-field>
              </li>
              <li style="margin-top: 30px">
                <h4>기타 요청사항</h4>
                <v-textarea ref="etc" outlined rows="6" style="height: 158px" class="filter-textarea" dense placeholder="" v-model="param.etc"></v-textarea>
              </li>
            </ul>
          </div>
        </div>
      </v-form>
    </template>
    <div class="wrapper mb-2">
      <div v-if="checkRows" class="btn-box">
        <v-card-actions v-if="tester">
          <v-btn depressed @click="testData" class="btn-cancle">테스트 정보 자동입력</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed @click="cancle" class="btn-cancle">취소</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn depressed class="btn-sample-request" @click="request_promise">샘플 요청</v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<script>
import { getSample } from 'api/file';
import { insertSample } from 'api/sample/sample';
// import { userInfo } from 'api/member/member';
import { columns, fields, rows } from '@/assets/grid/sampleRequest';
import RealGrid from '@/components/RealGrid.vue';
import SetPopup from '@/components/SetPopup.vue';
import Address from '@/components/Address.vue';
import validSet from '@/assets/valid';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import { filterExel, blankRow } from '@/assets/grid/gridUtill';
export default {
  watch: {
    'param.same': function (v) {
      if (v) {
        this.param.pick_name = this.param.request_name;
      }
    },
    'param.same2': function (v) {
      if (v) {
        this.param.pick_company = this.param.request_company;
      }
    },
    'param.default': function (v) {
      if (v === 0) {
        this.param.address = this.defaultAddress.address;
        this.param.address2 = this.defaultAddress.address2;
        this.param.postcode = this.defaultAddress.postcode;
      } else {
        this.param.address = '';
        this.param.address2 = '';
        this.param.postcode = '';
      }
    },
  },
  data() {
    return {
      grid: 'newSample',
      settings: {
        columns: _.map(
          _.filter(_.cloneDeep(columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            v.editable = true;
            return v;
          },
        ),
        fields,
        rows,
        height: 490,
        radio: false, // true -> radio, false -> checkbox
        firstClick: true, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
      },
      validSet,
      checkRows: false,
      codeSet: {},
      file: '',
      currentFileName: '',
      userId: '',
      param: {
        default: 0,
        price_type: '',
        packing: '',
        etc: '',
        same: false,
        address: '',
        address2: '',
        qty: '',
        request_code: '',
        request_name: '',
        pick_name: '',
        pick_company: '',
        analysis: '',
        delivery_date: '',
        postcode: '',
        phone1: '',
        phone2: '',
        request_company: '',
        memberId: '',
        pickpart: '',
        // default: '',
        delivery_type: '',
        ww: '',
      },
      isSelecting: false,
      address: [
        {
          key: 'default',
          text: '기본배송지',
        },
        {
          key: 'other',
          text: '다른 배송지',
        },
      ],
      tmpResult: [],
      picker: false,
      tester: false,
    };
  },
  computed: {
    ...mapState('common', ['code']),
    ...mapState('member', ['defaultAddress', 'userInfo']),
    otherAddress() {
      return !this.param.default;
    },
  },
  methods: {
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    checkRow(v) {
      if (v.clickData) {
        this.checkRows = true; // 입력 화면 및 저장버튼 보여주기.
      }
    },
    newSample() {
      this.$emit('newSample');
    },
    addressBook() {
      this.$refs.address.open();
    },
    cancle() {
      this.openConfirm('취소하시겠습니까', true, () => {
        this.reset();
      });
    },
    async request_promise() {
      let insertAfterRemoveRows = [];
      const row = this.$refs.grid.getCheckedRow();

      if (row.length === 0) {
        this.openPopup('조성항목을 선택해 주세요');
        return false;
      }

      const validated = this.valid();
      if (!validated) {
        this.openPopup('필수 항목을 입력해 주세요');
        return false;
      }

      if (row.length > 0 && validated) {
        for (const v of row) {
          const data = {
            ...this.param,
            ...v.data,
          };

          try {
            const res = await insertSample(data);
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              this.openConfirm(body.errorMessage);
              this.reset();
              return false;
            }
            insertAfterRemoveRows.push(v.idx);
          } catch (error) {
            console.error('Error during request:', error);
          }
        }
        // 서버 요청이 완료된 후 실행할 코드
        this.openPopup(`${insertAfterRemoveRows.length}개를 저장하였습니다.`);
        this.$refs.grid.removeItemsById(insertAfterRemoveRows);

        if (this.$refs.grid.getJsonRows().length == 0) {
          this.reset();
          this.resetFileInput();
          this.$refs.grid.unsetGrid();
          this.settings.firstClick = true;
        } else {
          this.$refs.grid.unsetGrid();
          this.resetUserData();
        }
      }
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    initGrid() {
      this.settings = {
        columns: _.map(
          _.filter(_.cloneDeep(columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            v.editable = true;
            return v;
          },
        ),
        fields,
        rows,
        height: 490,
        radio: false, // true -> radio, false -> checkbox
        firstClick: true, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
      };
    },
    resetFileInput() {
      this.file = ''; // file.name 초기화
      this.currentFileName = '';
      this.$refs.uploader.value = ''; // 파일 input 초기화
      this.tmpResult = [];
    },
    valid() {
      return this.$refs.newSample.validate();
    },
    upload() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const input = e.target;
      this.file = input.files[0];
      if (this.file == '') {
        this.tmpResult = [];
        that.read();
        return false;
      }
      if (this.file.name === this.currentFileName) {
        return false;
      }
      const that = this;
      this.currentFileName = this.file.name;
      let reader = new FileReader();
      reader.onload = function () {
        let fileData = reader.result;
        let wb = XLSX.read(fileData, { type: 'binary' });
        wb.SheetNames.forEach(sheetName => {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { range: 1 });
          that.tmpResult = rowObj;
          if (Array.isArray(that.tmpResult) && that.tmpResult.length !== 0) {
            that.read();
          }
        });
      };
      reader.readAsArrayBuffer(input.files[0]);
    },
    read() {
      const rows = _.cloneDeep(this.tmpResult);
      let rowsForGrid = this.makeRowForm(rows);
      this.settings.firstClick = true;
      if (rows.length === 0) {
        this.openPopup('엑셀 파일을 선택해 주세요.');
        return false;
      }
      this.$refs.grid.loadData(rowsForGrid);
    },
    makeRowForm(rows) {
      let rowsForGrid = [];
      _.forEach(rows, row => {
        row = filterExel(row);
        rowsForGrid.push(row);
      });
      return rowsForGrid;
    },
    // 빠른 등록 버튼 클릭때마다 적용.
    addGridRow() {
      let blankRows = blankRow({});
      this.$refs.grid.addRow(blankRows);
    },
    downloadSample() {
      this.openConfirm('샘플을 다운로드 하시겠습니까', true, getSample);
    },
    async reset() {
      this.resetUserData();
      this.file = '';
      this.checkRows = false;
    },
    resetUserData() {
      this.param = {
        default: 0,
        price_type: '',
        packing: '',
        etc: '',
        same: false,
        address: this.defaultAddress.address,
        address2: this.defaultAddress.address2,
        qty: '',
        phone1: '',
        phone2: '',
        request_code: '',
        request_name: '',
        pick_name: '',
        analysis: '',
        delivery_date: '',
        postcode: this.defaultAddress.postcode,
        ww: '',
        memberId: '',
      };

      //
      // 여기서는 validate 하는 것을 막아야 함
      //
      // this.$nextTick(() => {
      if (this.$refs.price_type) {
        this.$refs.price_type.resetValidation();
      }
      if (this.$refs.packing) {
        this.$refs.packing.resetValidation();
      }
      if (this.$refs.etc) {
        this.$refs.etc.resetValidation();
      }
      if (this.$refs.qty) {
        this.$refs.qty.resetValidation();
      }
      if (this.$refs.request_code) {
        this.$refs.request_code.resetValidation();
      }
      if (this.$refs.request_name) {
        this.$refs.request_name.resetValidation();
      }
      if (this.$refs.phone1) {
        this.$refs.phone1.resetValidation();
      }
      if (this.$refs.phone2) {
        this.$refs.phone2.resetValidation();
      }
      if (this.$refs.pick_name) {
        this.$refs.pick_name.resetValidation();
      }
      if (this.$refs.analysis) {
        this.$refs.analysis.resetValidation();
      }
      if (this.$refs.delivery_date) {
        this.$refs.delivery_date.resetValidation();
      }
      if (this.$refs.postcode) {
        this.$refs.postcode.resetValidation();
      }
      if (this.$refs.ww) {
        this.$refs.ww.resetValidation();
      }
      if (this.$refs.memberId) {
        this.$refs.memberId.resetValidation();
      }
      // if (this.$refs.address) {
      //   this.$refs.address.resetValidation();
      // }
      // if (this.$refs.address2) {
      //   this.$refs.address2.resetValidation();
      // }
      // });
    },
    openConfirm(message, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text: message,
        closable: closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: data => {
          this.param.address = data.address;
          this.param.address2 = data.address2;
          this.param.postcode = data.zonecode;
        },
      }).open();
    },
    onAddress(data) {
      this.param.postcode = data.zonecode;
      this.param.address = data.address;
      this.param.address2 = data.address2;
    },
    // 반드시 테스트에서만 사용하는 코드임.
    testData() {
      let nm = this.generateTestNumber();
      this.param = {
        default: 0,
        price_type: '유상',
        packing: 'AI',
        etc: `기타요청_${nm}`,
        same: false,
        address: '충남 천안시 서북구 1공단2길 7',
        address2: '상세주소 1',
        qty: '10',
        request_code: `자재코드_${nm}`,
        request_name: '요청자',
        pick_name: '수령자',
        analysis: `분석요청_${nm}`,
        delivery_date: '2024-07-25',
        postcode: '12345',
        request_company: `업체명_${nm}`,
        pick_company: `수령업체_${nm}`,
        phone1: '010-1234-5678',
        phone2: '02-5678-1234',
        ww: '',
        memberId: 'kconfine@gmail.com',
        delivery_type: '직납',
      };
    },
    // 반드시 테스트에서만 사용하는 코드임.
    generateTestNumber() {
      let d = new Date();
      return d.getMonth() + 1 + '_' + d.getDate() + '_' + d.getHours() + '_' + d.getMinutes();
    },
    request() {
      let insertAfterRemoveRows = [];
      const row = this.$refs.grid.getCheckedRow();
      if (row.length === 0) {
        this.openPopup('조성항목을 선택해 주세요');
        return false;
      }
      const validated = this.valid();
      if (!validated) {
        this.openPopup('필수 항목을 입력해 주세요');
        return false;
      }
      if (row.length > 0 && validated) {
        // 선택한 row 개수 만큼 서버 저장을 처리해야한다.
        _.each(row, v => {
          const data = {
            ...this.param,
            ...v.data,
          };
          // 조성항목 셋트가 반드시 1개 이상이어야 함.
          insertSample(data).then(res => {
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              this.openConfirm(body.errorMessage);
              this.reset();
            } else {
              // 이미 다른곳에서 처리 하고 있음.
              // this.openConfirm(body.message, false, () => {
              //   const idx = this.$refs.grid.getCheckedRowIdxRadio();
              //   const before = this.$refs.grid.getJsonRows();
              //   const updateRow = _.filter(before, (v, index) => {
              //     return index !== idx;
              //   });
              //   this.$refs.grid.loadData(updateRow);
              //   this.reset();
              // });
            }
          });
          insertAfterRemoveRows.push(v.idx);
        });

        this.openPopup(`${insertAfterRemoveRows.length}개를 저장하였습니다.`);
        this.$refs.grid.removeItemsById(insertAfterRemoveRows);
        if (this.$refs.grid.getJsonRows().length == 0) {
          this.reset();
          this.resetFileInput();
          // this.initGrid();
          this.$refs.grid.unsetGrid();
          this.settings.firstClick = true;
        } else {
          this.$refs.grid.unsetGrid();
          this.resetUserData();
        }
      }
    },
  },
  components: {
    RealGrid,
    Address,
    SetPopup,
  },
  mounted() {
    this.reset();
    this.tester = this.userInfo.memberId == 'kconfine@gmail.com' ? true : false;
  },
};
</script>
<style lang="scss">
.aLink {
  color: blue !important;
  text-decoration: underline;
}
.address {
  .v-input__slot {
    width: 100%;
  }
  .row {
    height: 100px;
  }
}
.v-input--checkbox {
  .v-input__slot {
    top: -21px;
  }
}
.newSample {
  .v-input--selection-controls__input {
    margin-right: 0px;
  }
  .v-input--radio-group {
    margin-top: 0px;
  }
}
.add-btn-filter-list {
  display: flex !important;
  width: 100% !important;
  li {
    position: relative;
    width: 100% !important;
  }
}
.justify-content-between {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
}
.add-btn {
  justify-content: space-between !important;
}
#one_speed_add {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  padding: 8px 20px !important;
  border-radius: 5px !important;
  border: 1px solid #01aa01 !important;
  background-color: #01aa01 !important;
  font-size: 1rem !important;
  span.text {
    font-size: 2rem;
    font-weight: 300;
    padding-right: 5px;
  }
}
</style>
