<template>
  <div>
    <!-- <h3 class="mb-2">회원 샘플 요청</h3> -->
    <h4 class="mb-2 dp--row">
      <!-- 요청 내역 -->
      <div class="btn-box">
        <v-btn depressed class="btn-sample-request" @click="newSample">샘플 요청</v-btn>
      </div>
    </h4>
    <!-- <div class="wrapperSpace">
      <div>
        <h4 class="mt-4 mb-2">요청 내역</h4>
      </div>
      <div>
        <v-btn depressed color="primary" @click="newSample">샘플 요청</v-btn>
      </div>
    </div> -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" />
  </div>
</template>
<script>
import { columns, fields, rows, height } from '@/assets/grid/sampleRequest';
import RealGrid from '@/components/RealGrid.vue';
import { memberSampleList } from 'api/sample/sample';
import _ from 'lodash';

export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'userSample',
      settings: {
        columns,
        fields,
        rows,
        height,
        hideCheckBar: true,
        errorMessage: '요청한 샘플이 없습니다',
      },
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    newSample() {
      this.$emit('newSample');
    },
    dbClick() {
      this.$emit('dbClick');
    },
    async loadData(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }

        const res = await memberSampleList({
          currentPage: this.currentPage,
          pageSize: 100000,
        });
        this.isDataLoaded = true;
        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.$refs.grid.loadData(items);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
  },
  components: {
    RealGrid,
  },
  mounted() {
    this.loadData();
  },
};
</script>
