<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="pa-5 scroll">
    <center><b>개인정보 처리방침</b></center><br /><br />
    동화일렉트로라이트 (‘https://smde.dongwha.com’ 이하 “회사”)는 개인정보보호법 및 정보통신망 이용촉진 및 
    정보보호 등에 관한 법률(이하 ‘정보통신망법’이라 한다) 등 개인정보 관련 법률에 따라 이용자(정보주체, 이하 동일)의 
    개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 
    처리방침을 두고 있습니다. 회사의 각 사업부문이나 관계회사의 경우에 해당 사업이나 관련 업무의 특성에 따라 
    별도의 개인정보처리방침이 운영될 수 있으며, 이 경우 각 해당하는 별도의 개인정보처리방침이 우선 적용되오니 
    해당 웹사이트에 공지된 개인정보처리방침을 확인하시기 바랍니다.<br /><br />
    ○ 본 방침은 2023년 3월 1일부터 시행됩니다.<br /><br />
    <b>제1조 (개인정보의 처리 목적)</b> 회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 
    이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.<br /><br />
    <ul>
      <li>1)        고객서비스 제공</li>
      <li>          - 홈페이지 회원 가입 및 관리</li>
      <li>          - 샘플 접수 및 처리현황 확인</li>
      <li>          - 서류발급 관련 서비스</li>
    </ul><br /><br />
    <b>제2조 (처리하는 개인정보의 항목)</b> 회사는 온라인에서의 회원가입 및 각종 서비스 제공, 오프라인에서의 업무처리를 
    위해 처리하는 개인정보의 항목 및 수집 방법은 다음과 같습니다. 그 외 온라인 서비스를 이용하는 과정에서 시스템 이용 기록, 
    방문기록, 쿠키정보, 접속로그인정보, IP정보, MAC주소, 불량 이용기록, 통계데이터 등이 자동으로 수집될 수 있습니다.<br /><br />
    <ul>
      <li>1)        개인정보 수집방법</li>
        <ul>
          <li>    - 온라인 수집 : 홈페이지 등 웹사이트를 통해서 이용자가 직접 입력하는 정보를 수집</li>
        </ul>
      <li>2)        수집 목적 및 항목</li>
        <ul>
          <li>    가. 회원가입</li>
            <ul>
              <li>- 필수정보 : 이름, 휴대폰번호, 이메일 주소, 주소지(배송지) 정보</li>
              <li>- 선택정보 : 기업명</li>
            </ul>
          <li>    나. 샘플접수</li>
            <ul>
              <li>- 필수정보 : 요청자, 수령자, 배송지 정보</li>
            </ul>  
        </ul>
    </ul><br /><br />
    <b>제3조 (개인정보의 처리 및 보유 기간)</b> 이용자 개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다. 
    단, 민법, 상법 등의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. 
    이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br /><br />
    - 개인정보 보유 이용 기간<br />
    - 회원 정보 : 업무 목적 달성 시까지(회원탈퇴 후 즉시 삭제)<br />
    - 비회원 정보 : 업무 목적 달성 시까지<br />
    - 서비스 이용에 따른 업무 목적 달성 시까지<br />
    - 단, 전자상거래 등에서 소비자보호에 관한 법률 등 관련 법령의 규정에 따라 거래 관계 확인을 위해 개인정보를 일정기간 보유 할 수 있습니다.<br /><br />
    - 계약체결 및 이에 관한 기록<br />
    보존 이유 : 상법, 민법 등의 규정<br />
    보존 기간 : 10년<br /><br />
    <b>제4조 (개인정보의 제3자 제공)</b> 회사는 원칙적으로 이용자의 개인정보를 제1조 (개인정보의 처리 목적)에서 명시한 범위 내에서 처리하며, 
    원칙적으로 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. <br />
    다만, 다음의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.<br />
    1. 이용자들이 사전에 동의한 경우<br />
    2. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br /><br /><br />
    <b>제5조 (개인정보처리 위탁)</b> 회사는 원칙적으로 이용자의 동의 없이 해당 개인정보의 처리를 타인에게 위탁하지 않습니다.<br /><br />
    다만, 이용자의 동의를 받아 위탁할 시 개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자 제공 금지 및 책임부담 등을 명확히 규정하고 
    당해 계약내용을 서면 및 전자 보관하고 있습니다. 업체 변경 시 공지사항 및 개인정보처리방침을 통해 고지하겠습니다.<br /><br />
    현재 이용자의 동의를 받아 개인정보 처리를 위탁하는 사항은 다음과 같습니다.<br /><br />

    <table border="1" style="border-collapse: collapse;">
      <tr align="center" bgcolor="#d3d3d3">
        <td style="width: 40%"><b>수탁자</b></td>
        <td style="width: 32%"><b>수탁범위</b></td>
        <td><b>수탁기간</b></td>
      </tr>
      <tr>
        <td>mazon Web Service Inc.</td>
        <td>데이터 보관, 인증메일 발송</td>
        <td rowspan="3">ㆍ서비스 제공 목적 달성 시 까지 <br />ㆍ단, 관계 법령이 정한 시점까지 보존</td>
      </tr>
      <tr>
        <td>가온소프트㈜</td>
        <td>시스템 개발, 관리 및 유지보수</td>
      </tr>
      <tr>
        <td>택배업체(CJ대한통운, 로젠택배, 롯데글로벌로지스, KGB택배, 우체국 택배, 한진택배, 경동택배)</td>
        <td>샘플 배송</td>
      </tr>
    </table><br /><br /><br />
    <b>제6조 (정보주체의 권리, 의무 및 그 행사방법)</b> 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.<br /><br />
    1. 자신 및 14세 미만 아동의 개인정보의 조회, 수정 및 가입해지의 요청<br />
    2. 개인정보의 오류에 대한 정정 및 삭제의 요청<br />
    3. 개인정보의 조회, 수정 및 해지, 삭제 등의 요청은 '개인정보변경'/'회원정보수정' 및 '가입해지'/'회원탈퇴'/'동의철회' 등을 클릭하여 
    본인 확인 절차를 거치신 후 직접 열람, 정정, 혹은 탈퇴가 가능합니다.<br />
    4. 이용자가 개인정보의 오류에 대한 정정 및 삭제를 요청한 경우에는 정정 및 삭제를 완료할 때 까지 당해 개인정보를 이용 또는 제공하지 않습니다. 
    이 경우, 잘못된 개인정보를 이용 또는 제공한 경우 지체 없이 수정하겠습니다.<br />
    5. 회사는 이용자의 요청에 의해 해지 또는 삭제되는 개인정보는 제3조 개인정보의 처리 및 보유 기간에 따라 처리합니다.<br /><br /><br />
    <b>제7조 (개인정보의 파기)</b> 회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 
    파기의 절차, 기한 및 방법은 다음과 같습니다.<br /><br /><br />
    -파기절차<br />
    이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 
    이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br />
    -파기기한<br />
    이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 
    해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다. 
    홈페이지를 통하여 수집된 회원정보의 경우, 회원이 1년간 홈페이지를 미이용할 경우(로그인 안할 경우) 별도의 통지 없이 영구적으로 삭제합니다. 
    회원이 1년간 홈페이지를 미이용할 경우(로그인 안할 경우) 별도의 통지 없이 영구적으로 삭제합니다. <br />
    -파기방법<br />
    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br /><br /><br />
    <b>제8조 (개인정보 자동 수집 장치)</b> 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’ 등을 운용합니다. 
    쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. <br /><br /><br />
    가. 쿠키 등 사용 목적<br /><br />
    이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 맞춤 서비스 제공<br /><br />
    나. 쿠키의 설치/운영<br /><br />
    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 
    쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. <br /><br />
    다. 쿠키 설정 거부 방법<br /><br />
    쿠키 설정을 거부하는 방법으로는 이용자가 사용하시는 웹 브라우저의 옵션을 택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 
    모든 쿠키의 저장을 거부할 수 있습니다. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.<br /><br />
    쿠키 설치 허용 여부를 지정하는 방법('Chrome'의 경우)은 아래와 같습니다.<br /><br />
    - [설정] > [개인 정보 보호 및 보안] > [인터넷 사용 기록 삭제]<br /><br /><br />
    <b>제9조 (개인정보의 안전성 확보 조치)</b> 회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.<br /><br /><br />
    1. 개인정보 취급 직원의 최소화 및 교육<br /><br />
    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br /><br />
    2. 정기적인 자체 감사 실시<br /><br />
    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br /><br />
    3. 내부관리계획의 수립 및 시행<br /><br />
    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br /><br />
    4. 개인정보의 암호화<br /><br />
    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 
    암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br /><br />
    5. 해킹 등에 대비한 기술적 대책<br /><br />
    회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신/점검을 하며 외부로부터 
    접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br /><br />
    6. 개인정보에 대한 접근 제한<br /><br />
    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 
    침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br /><br />
    7. 접속기록의 보관 및 위변조 방지<br /><br />
    개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br /><br />
    8. 문서보안을 위한 잠금장치 사용<br /><br />
    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br /><br />
    9. 비인가자에 대한 출입 통제<br /><br />
    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br /><br /><br />
    <b>제10조 (개인정보보호 책임자)</b> 회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다.<br />
    (개인정보보호법 제31조제1항에 따른 개인정보보호책임자)<br /><br />
    <ul>
      <li>⦁    개인정보보호책임자</li>
        <ul>
          <li>- 전해질연구부문 박명구 상무</li>
          <li>- 이메일 : myounggupark@dongwha.com</li>
        </ul><br />
      <li>⦁    개인정보처리자</li>
        <ul>
          <li>- 양산개발팀 홍찬화 팀장</li>
          <li>- 이메일 : chhong@dongwha.com</li>
        </ul>
    </ul><br /><br />
    <b>제11조 (개인정보 처리방침의 변경)</b> 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 
    삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.<br /><br /><br />
    <b>제12조 (권익침해 구제방법)</b> 개인정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 
    한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.<br /><br /><br />
    이 밖에 기타 개인정보침해의 신고 및 상담에 대하여는 아래의 기관에 문의하시기를 바랍니다.<br /><br /><br />
    - 개인정보 침해신고센터 (privacy.kisa.or.kr / 118)<br />
    - 개인정보 분쟁조정위원회 (www.kopico.go.kr / 1833-6972)<br />
    - 대검찰청 사이버수사과 (www.spo.go.kr / 1301)<br />
    - 경찰청 사이버안전국 (cyberbureau.police.go.kr / 182)<br />

  </div>
</template>
<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      paramProps: {},
      open: false,
      callback: '',
    };
  },
  computed: {
    ...mapState('modal', ['param', 'height', 'dialogTitle', 'dialogText', 'maxWidth', 'closable', 'customApprove', 'approveName']),
  },
  methods: {
    ...mapMutations('modal', ['RESET_MODAL']),
    openModal(cb) {
      this.open = true;
      this.callback = cb;
    },
    closeModal() {
      this.open = false;
      this.RESET_MODAL();
    },
    onApprove() {
      this.closeModal();
      if (_.isFunction(this.callback)) {
        this.callback();
      }
    },
  },
  components: {},
};
</script>
<style>
.approve span {
  color: white;
}

.tableParent {
  border-collapse: collapse;
  display: table;
}

.tableChild {
  display: table-row;
  vertical-align: bottom;
  height: 1px;
}

.scroll {
  max-height: 500px;
  overflow: auto;
}

.pa-5 ul {
  list-style: none;
}
</style>
