import { ValueType } from 'realgrid';

export const fields = [
  {
    fieldName: 'remove',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'defaultYn',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'idx',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pt_cd',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pickname',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'mobile',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'postcode',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address1',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address2',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'fullAddress',
    dataType: ValueType.TEXT,
  },
  // {
  //   fieldName: 'edit',
  //   dataType: ValueType.TEXT,
  // },
  // {
  //   fieldName: 'copy',
  //   dataType: ValueType.TEXT,
  // },
];

export const columns = [
  {
    name: 'remove',
    fieldName: 'remove',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '삭제',
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell) {
        // cell.index.itemIndex가 유효한지 확인합니다.
        const itemIndex = cell.index ? cell.index.itemIndex : null;
        if (itemIndex !== null) {
          // itemIndex가 유효한 경우에만 getValue를 호출합니다.
          const defaultYn = grid.getValue(itemIndex, 'defaultYn');
          if (defaultYn == 1) {
            return '<div class="delete-area"><span class="default-post">기존배송지</span></div>';
          }
          return '<svg class="delete-post-icon" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 40 40"><path fill="currentColor" d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747z" /></svg>';
        } else {
          // itemIndex가 null인 경우 빈 문자열을 반환하여 렌더링 오류를 방지합니다.
          return '';
        }
      },
    },
    editable: false,
    sortable: false,
    width: 60,
  },
  {
    name: 'idx',
    fieldName: 'idx',
    type: 'data',
    visible: false,
    sortable: false,
  },
  {
    name: 'pt_cd',
    fieldName: 'pt_cd',
    type: 'data',
    visible: false,
    sortable: false,
  },
  {
    name: 'name',
    fieldName: 'name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '배송지명',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 90,
  },
  {
    name: 'pickname',
    fieldName: 'pickname',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '수령자',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 80,
  },
  {
    name: 'mobile',
    fieldName: 'mobile',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '핸드폰 번호',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 100,
  },
  {
    name: 'postcode',
    fieldName: 'postcode',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '우편번호',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 80,
  },
  {
    name: 'address1',
    fieldName: 'address1',
    type: 'data',
    styles: {
      textAlignment: 'near',
    },
    header: {
      text: '주소',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 300,
    visible: false,
  },
  {
    name: 'fullAddress',
    fieldName: 'fullAddress',
    type: 'data',
    styles: {
      textAlignment: 'near',
    },
    header: {
      text: '주소',
      showTooltip: false,
    },
    editable: false,
    sortable: false,
    width: 300,
  },
  {
    name: 'edit',
    fieldName: 'idx',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '수정',
    },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell) {
        return `<button type="button" class="svg-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="#07a60a" d="M3.548 20.938h16.9a.5.5 0 0 0 0-1h-16.9a.5.5 0 0 0 0 1M9.71 17.18a2.6 2.6 0 0 0 1.12-.65l9.54-9.54a1.75 1.75 0 0 0 0-2.47l-.94-.93a1.79 1.79 0 0 0-2.47 0l-9.54 9.53a2.5 2.5 0 0 0-.64 1.12L6.04 17a.74.74 0 0 0 .19.72a.77.77 0 0 0 .53.22Zm.41-1.36a1.47 1.47 0 0 1-.67.39l-.97.26l-1-1l.26-.97a1.5 1.5 0 0 1 .39-.67l.38-.37l1.99 1.99Zm1.09-1.08l-1.99-1.99l6.73-6.73l1.99 1.99Zm8.45-8.45L18.65 7.3l-1.99-1.99l1.01-1.02a.75.75 0 0 1 1.06 0l.93.94a.754.754 0 0 1 0 1.06"/>
                  </svg>
                </button>`;
        // return `<button type="button" class="svg-button" onclick="window.onEditClick(${cell.index.itemIndex})">
        //           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        //             <path fill="#07a60a" d="M3.548 20.938h16.9a.5.5 0 0 0 0-1h-16.9a.5.5 0 0 0 0 1M9.71 17.18a2.6 2.6 0 0 0 1.12-.65l9.54-9.54a1.75 1.75 0 0 0 0-2.47l-.94-.93a1.79 1.79 0 0 0-2.47 0l-9.54 9.53a2.5 2.5 0 0 0-.64 1.12L6.04 17a.74.74 0 0 0 .19.72a.77.77 0 0 0 .53.22Zm.41-1.36a1.47 1.47 0 0 1-.67.39l-.97.26l-1-1l.26-.97a1.5 1.5 0 0 1 .39-.67l.38-.37l1.99 1.99Zm1.09-1.08l-1.99-1.99l6.73-6.73l1.99 1.99Zm8.45-8.45L18.65 7.3l-1.99-1.99l1.01-1.02a.75.75 0 0 1 1.06 0l.93.94a.754.754 0 0 1 0 1.06"/>
        //           </svg>
        //         </button>`;
      },
    },
    width: 30,
  },
  {
    name: 'copy',
    fieldName: 'idx',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '복사',
    },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell) {
        return `<button type="button" class="svg-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="#07a60a" d="M18.333 6A3.667 3.667 0 0 1 22 9.667v8.666A3.667 3.667 0 0 1 18.333 22H9.667A3.667 3.667 0 0 1 6 18.333V9.667A3.667 3.667 0 0 1 9.667 6zM14 10a1 1 0 0 0-1 1v2h-2a1 1 0 0 0-.993.883L10 14a1 1 0 0 0 1 1h2v2a1 1 0 0 0 .883.993L14 18a1 1 0 0 0 1-1v-2h2a1 1 0 0 0 .993-.883L18 14a1 1 0 0 0-1-1h-2v-2a1 1 0 0 0-.883-.993zm1-8c1.094 0 1.828.533 2.374 1.514a1 1 0 1 1-1.748.972C15.405 4.088 15.284 4 15 4H5c-.548 0-1 .452-1 1v9.998c0 .32.154.618.407.805l.1.065a1 1 0 1 1-.99 1.738A3 3 0 0 1 2 15V5c0-1.652 1.348-3 3-3z"/>
                  </svg>
                </button>`;
        // return `<button type="button" class="svg-button" onclick="window.onCopyClick(${cell.index.itemIndex})">
        //           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        //             <path fill="#07a60a" d="M18.333 6A3.667 3.667 0 0 1 22 9.667v8.666A3.667 3.667 0 0 1 18.333 22H9.667A3.667 3.667 0 0 1 6 18.333V9.667A3.667 3.667 0 0 1 9.667 6zM14 10a1 1 0 0 0-1 1v2h-2a1 1 0 0 0-.993.883L10 14a1 1 0 0 0 1 1h2v2a1 1 0 0 0 .883.993L14 18a1 1 0 0 0 1-1v-2h2a1 1 0 0 0 .993-.883L18 14a1 1 0 0 0-1-1h-2v-2a1 1 0 0 0-.883-.993zm1-8c1.094 0 1.828.533 2.374 1.514a1 1 0 1 1-1.748.972C15.405 4.088 15.284 4 15 4H5c-.548 0-1 .452-1 1v9.998c0 .32.154.618.407.805l.1.065a1 1 0 1 1-.99 1.738A3 3 0 0 1 2 15V5c0-1.652 1.348-3 3-3z"/>
        //           </svg>
        //         </button>`;
      },
    },
    width: 30,
  },
];
export const rows = [
  {
    remove: '',
    copy: '',
    edit: '',
    defaultYn: 'Y',
    idx: '',
    pt_cd: '',
    name: '',
    pickname: '',
    mobile: '',
    postcode: '',
    address1: '',
    address2: '',
  },
];
