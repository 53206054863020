import _ from 'lodash';
import { makeCell } from './gridUtill';
const fields = [];
const columns = [];
const filterArr = ['delivery_date', 'qty', 'requeststat', 'producestat', 'releasestat'];
const errorMessage = '진행중인 사항이 없습니다';
const data = [
  {
    field: 'lotNo',
    alias: 'Lot No',
  },
  {
    field: 'code',
    alias: 'Code Grade',
  },
  {
    field: 'reg_dt',
    alias: '요청일',
    date: 'yyyy-MM-dd',
  },
  {
    field: 'delivery_date',
    alias: '납기일',
  },
  {
    field: 'qty',
    alias: '제품수량(kg)',
  },
  {
    field: 'requeststat',
    alias: '접수 상태',
  },
  {
    field: 'producestat',
    alias: '제조 진행사항',
  },
  {
    field: 'releasestat',
    alias: '출하사항',
  },
  {
    field: 'postcode',
    alias: '우편번호',
    editable: true,
  },
  {
    field: 'address',
    alias: '배송지 주소',
    editable: true,
  },
  {
    field: 'address2',
    alias: '상세주소',
    editable: true,
  },
  {
    field: 'etc',
    alias: '비고',
  },
];

_.each(data, function (o) {
  o.indexExclusive = true;
  makeCell(1, [o], fields, columns, false, o.editable);
});
const filteredFields = _.filter([...fields], function (v) {
  return filterArr.includes(v.fieldName);
});
const filteredColumn = _.filter([...columns], function (v) {
  return filterArr.includes(v.fieldName);
});

export { fields, columns, filteredFields, filteredColumn, errorMessage };
