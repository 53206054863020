import http from './http';
import config from './config';
import moment from 'moment';
// import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
const FileDownload = require('js-file-download');
const time = moment();
const formatTime = time.format('YYYYMMDDhhmm');
export async function getSample() {
  return http
    .get('/DW_Sample_request.xlsx', { responseType: 'blob' })
    .then(response => {
      FileDownload(response.data, `sample_${formatTime}.xlsx`);
    })
    .catch(exception => {
      console.log(exception);
      alert('파일 다운로드 실패');
    });
}

export async function getExcel(data, name) {
  try {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet(name);
    const columns = Object.keys(data[0]);
    // worksheet에 컬럼에 대한 정보를 줌
    // 맨 첫 번째 줄에 컬럼들이 삽입됨
    ws.columns = columns.map(column => ({
      header: column, // 컬럼 이름
      key: column, // data에서 컬럼의 값을 구분하기 위한 key
      width: 40,
      style: {
        font: {
          size: 12,
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        },
      },
    }));

    // 모든 컬럼에 대해 첫 번째 행(헤더)의 스타일 적용
    columns.forEach((_, index) => {
      let colLetter = getExcelColumnName(index); // 엑셀 열 레이블 생성
      let cell = ws.getCell(`${colLetter}1`);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'b8b8b8' },
      };
      cell.font = {
        bold: true,
        size: 15,
      };
    });

    // ws.insertRows(2, data);
    // 데이터를 삽입하는 로직 수정: 첫 번째 행에 헤더가 있으므로 데이터는 두 번째 행부터 시작
    ws.addRows(data);
    ws.getRow(1).font = {
      bold: true,
      size: 15,
    };

    // for (let i = 1; i <= columns.length; i++) {
    //   let code = 64 + i;
    //   const char = String.fromCharCode(code);
    //   const sellNumber = `${char}1`;
    //   ws.getCell(sellNumber).fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'b8b8b8' },
    //   };
    // }

    const bf = await wb.xlsx.writeBuffer();
    saveAs(
      new Blob([bf], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      `${name}_${moment().valueOf()}.xlsx`,
    );
  } catch (exception) {
    alert('파일다운로드 실패');
  }
}

// 엑셀 스타일의 열 레이블을 생성하는 함수. Z가 넘어 가는 경우 에러 대비한 함수이다.
function getExcelColumnName(index) {
  let columnName = '';
  let base = 26;
  let dividend = index + 1;

  while (dividend > 0) {
    let remainder = (dividend - 1) % base;
    columnName = String.fromCharCode('A'.charCodeAt(0) + remainder) + columnName;
    dividend = parseInt((dividend - remainder) / base);
  }

  return columnName;
}

export async function getReportFile(path) {
  return http
    .get(config.exelLocation + path, { responseType: 'blob' })
    .then(response => {
      FileDownload(response.data, `제조기록지_${moment.now()}.xlsx`);
    })
    .catch(exception => {
      console.log(exception);
      alert('파일 다운로드 실패');
    });
}

export async function getReportFile2(lotNo, path) {
  return http
    .get(config.exelLocation + path, { responseType: 'blob' })
    .then(response => {
      FileDownload(response.data, `COA_` + lotNo + `_${moment.now()}.xlsx`);
    })
    .catch(exception => {
      console.log(exception);
      alert('파일 다운로드 실패');
    });
}

export async function getCoapdfFile(path) {
  return http
    .get(config.exelLocation + path, { responseType: 'blob' })
    .then(response => {
      let fileName = path.split('/').pop();
      FileDownload(response.data, fileName);
    })
    .catch(exception => {
      console.log(exception);
      alert('파일 다운로드 실패');
    });
}

export async function getMsdspdfFile(path) {
  return http
    .get(config.exelLocation + path, { responseType: 'blob' })
    .then(response => {
      let fileName = path.split('/').pop();
      FileDownload(response.data, fileName);
    })
    .catch(exception => {
      console.log(exception);
      alert('파일 다운로드 실패');
    });
}
