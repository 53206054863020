<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="pa-5 scroll">
    <h2><center>서비스 이용약관</center></h2><br /><br />
    <h3>제1장 총칙</h3><br />
    <h4>제1조 (목적)</h4><br />
    본 약관은 동화 일렉트로라이트(이하 ‘회사’)가 제공하는 연구소 샘플관리 서비스(이하 ‘본 서비스’)를 이용함에 있어 
    ‘회사’와 이용자(이하 ‘고객’) 사이의 권리와 의무 및 기타 필요한 사항을 규정하는데 그 목적이 있습니다.<br /><br /><br />
    <h4>제2조 (약관의 효력 및 변경)</h4><br />
    <ul>
      <li>1.    이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에 가입함으로써 효력을 발생합니다.</li><br />
      <li>2.    회사는 합리적인 사유가 발생할 경우 관계 법령에 위배되지 않는 범위 내에서 본 약관을 변경할 수 있으며, 개정된 약관은 사이트에 공지함으로써 효력이 발생합니다.
      다만, 이용자의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 7일전에 공시합니다.</li><br />
      <li>3.    회원은 변경된 약관 사항에 동의하지 않으면 회원 탈퇴(해지)를 요청할 수 있습니다.</li><br />
    </ul>
    <br /><br />
    <h4>제3조 (용어의 정의)</h4><br />
    본 약관에서 사용하는 용어의 정의는 아래와 같습니다. 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.<br /><br />
    <ul>
      <li>1.    고객 : 사이트에 접속하여 서비스 이용약관에 동의한 후 본 약관에 따라 ‘본 서비스’의 이용을 신청한 자를 말합니다.</li><br />
      <li>2.    ID : 고객 식별과 고객의 서비스 이용을 위하여 고객이 선정하고 회사가 승인하는 이메일 형태로 하나의 이메일 주소로 하나의 ID만 발급 및 이용 가능</li><br />
      <li>3.    관리자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사가 선정한 자</li>
    </ul><br /><br /><br />
    <h3><b>제2장 서비스 이용 계약</b></h3><br />
    <h4>제4조 (서비스 이용 계약의 성립)</h4><br />
    <ul>
      <li>1.    "위의 이용약관에 동의하십니까?" 라는 이용 신청시의 물음에 고객이 "동의" 버튼을 누르면 약관에 동의하는 것으로 간주됩니다.</li><br />
      <li>2.    회원에 가입하여 서비스를 이용하고자 하는 이용자는 회사에서 요청하는 개인 신상정보를 제공해야 합니다.</li>
    </ul><br /><br />
    <h4>제5조 (이용신청)</h4><br />
    이용 신청은 서비스의 이용자 등록 화면에서 고객이 다음 사항을 가입신청 양식에 기록하는 방식으로 행합니다.<br /><br />
    <ul>
      <li>-    이름</li><br />
      <li>-    ID(이메일주소)</li><br />
      <li>-    비밀번호</li><br />
      <li>-    휴대폰번호</li><br />
      <li>-    주소지 정보</li><br />
      <li>-    기타 회사가 필요하다고 인정하는 사항</li>
    </ul><br /><br />
    <h4>제6조 (이용 계약 해지)</h4><br />
    회원이 서비스 이용계약을 해지하고자 할 경우에는 회사에 해지신청을 하여야 합니다.<br /><br /><br /><br />
    <h3><b>제3장 서비스 이용</b></h3><br />
    <h4>제7조 (서비스 이용)</h4><br />
    <ul>
      <li>1.    회사는 이용자에게 제공하는 서비스를 홈페이지에 게시합니다.</li><br />
      <li>2.    회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 e-mail 및 서신우편 등의 방법으로 회원에게 제공할 수 있습니다</li><br />
      <li>3.    고객은 서비스를 통해 본인이 접수한 샘플요청의 상세내역을 열람할 수 있습니다.</li><br />
      <li>4.    요청하는 샘플의 유/무상 여부는 관리자 검수 과정에서 변경될 수 있습니다.</li>
    </ul><br /><br />
    <h4>제8조 (서비스 저작권 권리의 귀속)</h4><br />
    <ul>
      <li>1.    서비스 자체에 대한 지식재산권은 회사에게 귀속됩니다. 고객이 서비스 내에서 등록 및 요청한 샘플데이터의 저작권은 고객에 있지만, 
      고객이 탈퇴하더라도 등록된 이력은 삭제하지 않고 서비스 내에 존속하여 관리자가 확인할 수 있습니다. (단 고객의 개인정보의 경우 탈퇴하는 즉시 삭제 처리됩니다.)</li><br />
      <li>2.    고객은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 
      게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.</li>
    </ul><br /><br />
    <h4>제9조 (서비스의 변경)</h4><br />
    회사는 상당한 이유가 있는 경우에 회사의 정책상, 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경할 수 있으며, 
    이에 대하여 관련법에 특별한 규정이 없는 한 고객에게 별도 보상을 하지 않습니다.<br /><br />
    <h4>제10조 (서비스 이용의 제한 및 정지)</h4><br />
    <ul>
      <li>1.    회사는 고객이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 서비스 이용을 제한하거나 정지할 수 있으며, 
      이에 대해 회사는 별도로 보상하지 않습니다.</li><br />
      <li>2.    회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우, 
      회사는 고객에게 통지하고 서비스를 종료할 수 있습니다.</li>
    </ul><br /><br />
    <h4>제11조 ('아이디' 및 '비밀번호'의 관리에 대한 의무)</h4><br />
    고객의 ‘아이디’와 ‘비밀번호’에 관한 관리책임은 고객에게 있으며, 이를 제 3 자가 이용하도록 하여서는 안 됩니다. 
    만약 고객이 이를 위반하는 경우 민형사상 책임을 지게 될 수 있습니다.<br /><br />
    <h4>제12조 (데이터의 보존 및 파기)</h4><br />
    <ul>
      <li>1.    고객은 서비스 이용 중 데이터를 별도로 상시 백업 ∙ 저장하여야 하며, 회사가 고객의 데이터를 백업하거나 고객에게 제공할 의무는 없습니다. 
      고객이 전단의 의무를 소홀히 하여 발생한 데이터의 유출, 누락이나 손실에 대해서 회사는 책임을 부담하지 않습니다.</li><br />
      <li>2.    서비스 이용기간 만료, 서비스 이용계약의 해제 또는 해지 등의 사유로 회사가 서비스 제공을 종료한 경우 회사는 관련법 및 
      개인정보처리방침에 따라 보존하는 경우를 제외하고는 관리자, 구성원의 개인정보, 이용자 정보, 데이터 등 회사가 보관하는 모든 정보를 6개월 간 보존 후 파기합니다. 
      다만, 이름, 생년월일, 성별, 아이디, 연락처, 주소 등 서비스 이용에 혼란 및 부정사용을 방지하기 위하여 
      필요한 확인정보는 이용기간 만료 이후에도 개인정보 처리방침에 따라 일정기간 보관합니다.</li><br />
      <li>3.    고객의 샘플요청 정보는 이력확인을 위해 고객의 탈퇴 유무와 상관없이 관계법령에 따라 일정기간 동안 별도로 보관할 수 있습니다.</li>
    </ul><br /><br /><br />
    <h3><b>제4장 손해배상</b></h3><br />
    <h4>제13조 (손해배상)</h4><br />
    <ul>
      <li>1.    고객이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 고객은 회사에 발생하는 모든 손해를 배상하여야 합니다.</li><br />
      <li>2.    고객이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 고객 이외의 제3자로부터 손해배상 청구 또는 
      소송을 비롯한 각종 이의제기를 받는 경우 당해 고객은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 
      회사가 면책되지 못한 경우 당해 고객은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</li>
    </ul><br /><br />
    <h4>제14조 (면책조항)</h4><br />
    <ul>
      <li>1.    회사는 고객이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.</li><br />
      <li>2.    회사는 고객의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</li><br />
      <li>3.    회사는 천재지변 또는 이에 준하는 통제할 수 없는 사유로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.</li>
    </ul><br /><br />
    <h4>제15조 (관할법원)</h4><br />
    <ul>
      <li>1.    서비스 이용과 관련하여 회사와 고객 사이에 분쟁이 발생한 경우, 회사와 고객은 분쟁의 해결을 위해 성실히 협의합니다.</li><br />
      <li>2.    전항에도 불구하고 회사와 고객간의 분쟁이 해결되지 않을 경우, 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.</li>
    </ul><br /><br /><br />
    <h4>부칙</h4><br />
    본 약관은 2023 년 3월 1일부터 적용됩니다.
  </div>
</template>
<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      paramProps: {},
      open: false,
      callback: '',
    };
  },
  computed: {
    ...mapState('modal', ['param', 'height', 'dialogTitle', 'dialogText', 'maxWidth', 'closable', 'customApprove', 'approveName']),
  },
  methods: {
    ...mapMutations('modal', ['RESET_MODAL']),
    openModal(cb) {
      this.open = true;
      this.callback = cb;
    },
    closeModal() {
      this.open = false;
      this.RESET_MODAL();
    },
    onApprove() {
      this.closeModal();
      if (_.isFunction(this.callback)) {
        this.callback();
      }
    },
  },
  components: {},
};
</script>
<style>
.approve span {
  color: white;
}

.tableParent {
  border-collapse: collapse;
  display: table;
}

.tableChild {
  display: table-row;
  vertical-align: bottom;
  height: 1px;
}

.scroll {
  max-height: 500px;
  overflow: auto;
}
</style>
