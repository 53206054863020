<template>
  <div>
    <SetPopup ref="confirm" />
    <SetPopup ref="addPopup">
      <div class="wrapper">
        <template v-if="!check">
          <v-card-actions>
            <v-btn depressed @click="cancel">취소</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed color="primary" @click="addExec">생성</v-btn>
          </v-card-actions>
        </template>
      </div>
    </SetPopup>
    <SetDialog ref="add">
      <AddAcount @close="close" @save="save" />
    </SetDialog>
    <div class="service">
      <!-- <h3 class="mb-2">아이디 관리</h3> -->
      <v-form ref="form" lazy-validation>
        <div class="filter-box filter-box--row">
          <ul class="filter-list_free">
            <li style="width: 180px">
              <h4>계정 구분</h4>
              <v-select :items="this.roleType" v-model="input.roles" outlined :id="'account'"></v-select>
            </li>
            <li style="width: 180px">
              <h4>이름</h4>
              <v-text-field outlined dense placeholder="" v-model="input.memberName" :rules="[this.validSet.name]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 300px">
              <h4>이메일 주소</h4>
              <v-text-field outlined dense v-model="input.memberId" placeholder="" :rules="[this.validSet.email]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>기업명</h4>
              <v-text-field outlined dense v-model="input.company" placeholder="" :rules="[this.validSet.company]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>사번</h4>
              <v-text-field outlined dense v-model="input.employeeCode" placeholder="" :rules="[this.validSet.employNumber]" @keyup.enter="onApprove"></v-text-field>
            </li>
            <li style="width: 180px">
              <h4>재직</h4>
              <v-select :items="this.workType" v-model="input.employeeStatus" outlined id="work"></v-select>
            </li>
          </ul>
          <div class="btn-box">
            <v-card-actions class="mr-0">
              <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed class="btn-search" @click="onApprove">검색</v-btn>
            </v-card-actions>
            <!-- <button class="btn-reset" @click="reset">초기화</button>
            <button class="btn-search" @click="onApprove">검색</button> -->
          </div>
          <!-- <v-row class="row pa-3">
            <v-col cols="12" sm="1" class="pl-0">
              <h4>계정 구분</h4>
              <v-select
                :items="this.roleType"
                v-model="input.roles"
                outlined
                :id="'account'"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>이름</h4>
              <v-text-field
                outlined
                dense
                placeholder="이름을 입력해 주세요."
                v-model="input.memberName"
                :rules="[this.validSet.name]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>이메일 주소</h4>
              <v-text-field
                outlined
                dense
                v-model="input.memberId"
                placeholder="이메일 주소를 입력해 주세요."
                :rules="[this.validSet.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>기업명</h4>
              <v-text-field
                outlined
                dense
                v-model="input.company"
                placeholder="기업명을 입력해 주세요."
                :rules="[this.validSet.company]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <h4>사번</h4>
              <v-text-field
                outlined
                dense
                v-model="input.employeeCode"
                placeholder="사번을 입력해 주세요."
                :rules="[this.validSet.employNumber]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" class="pr-0">
              <h4>재직</h4>
              <v-select
                :items="this.workType"
                v-model="input.employeeStatus"
                outlined
                id="work"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="1" class="pr-0">
              <div class="wrapperTop pt-6 pl-6">s
                <v-card-actions class="mr-0">
                  <v-btn depressed outlined @click="reset">초기화</v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn depressed color="primary" @click="onApprove">검색</v-btn>
                </v-card-actions>
              </div>
            </v-col>
          </v-row> -->
        </div>
      </v-form>
      <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">목록</h3> -->
      <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @btnClick="update" />
    </div>
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/account';
import SetDialog from '@/components/SetDialog.vue';
import SetPopup from '@/components/SetPopup.vue';
import AddAcount from '@/views/admin/user/AddAcount.vue';
import RealGrid from '@/components/RealGrid.vue';
import { memberList, memberJoin, userInfoUpdate } from 'api/member/member';
import { mapMutations, mapState } from 'vuex';
import validSet from '@/assets/valid';
import _ from 'lodash';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      input: {
        employeeStatus: '전체',
        roles: '전체',
        memberName: '',
        memberId: '',
        company: '',
        employeeCode: '',
        pageSize: 100000,
      },
      accountType: [],
      check: false,
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        hideCheckBar: true,
        errorMessage: '조회된 내용이 없습니다',
      },
      validSet,
      grid: 'acount',
      saveParam: {},
    };
  },
  computed: {
    ...mapState('select', ['workType', 'roleType']),
  },
  mounted() {
    this.SET_POPUP({
      title: '알림',
      // height: 150,
      width: 300,
      customApprove: true,
    });
    this.input.roles = this.roleType[0];
    this.loadData();
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    ...mapMutations('popup', ['SET_POPUP', 'SET_POPUP_TEXT']),
    reset() {
      this.input = {
        employeeStatus: '전체',
        roles: '전체',
        memberName: '',
        memberId: '',
        company: this.company,
        employeeCode: '',
        pageSize: 15,
      };
    },
    loadData(v) {
      this.onApprove(v);
    },
    valid() {
      return this.$refs.form.validate();
    },
    async onApprove(v) {
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        const param = _.cloneDeep(this.input);
        switch (param.employeeStatus) {
          case '재직중':
            param.employeeStatus = 1;
            break;
          case '퇴사':
            param.employeeStatus = 2;
            break;
          case '전체':
            param.employeeStatus = '';
            break;
        }

        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await memberList({
          ...param,
          currentPage: this.currentPage,
        });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        _.each(items, v => {
          v.save = '저장';
          switch (v.employee_status) {
            case 1:
              v.work = '재직';
              break;
            case 2:
              v.work = '퇴사';
              break;
          }
        });
        this.$refs.grid.loadData(items);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    add() {
      this.SET_MODAL({
        height: 500,
        width: 750,
        closable: true,
        approveName: '저장',
        customApprove: true,
      });
      this.$refs.add.openModal();
    },
    save(param) {
      this.saveParam = param;
      this.openPopup('입력된 정보로 아이디를 생성하시겠습니까?');
    },
    close() {
      this.check = false;
      this.$refs.add.closeModal();
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
        customApprove: true,
      });
      this.$refs.addPopup.openPopup(cb);
    },
    openConfirm(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable,
      });
      this.$refs.confirm.openPopup(cb);
    },
    cancel() {
      this.saveParam = {};
      this.$refs.addPopup.closePopup();
    },
    addExec() {
      this.$refs.addPopup.closePopup();
      const param = _.cloneDeep(this.saveParam);
      switch (param.employeeStatus) {
        case '재직중':
          param.employee_status = 1;
          break;
        case '퇴사':
          param.employee_status = 2;
          break;
        case '전체':
          param.employee_status = '';
          break;
      }
      memberJoin(param)
        .then(() => {
          this.openConfirm('신규 생성되었습니다', false, () => {
            this.$refs.addPopup.closePopup();
          });
        })
        .catch(() => {
          this.openConfirm('서버에러 관리자에게 문의하세요', false, () => {
            this.$refs.addPopup.closePopup();
          });
        });
    },
    update(row) {
      let employee_status = '';
      switch (row.work) {
        case '재직중':
          employee_status = 1;
          break;
        case '퇴사':
          employee_status = 2;
          break;
      }
      userInfoUpdate({ ...row, memberId: row.email, employee_status })
        .then(() => {})
        .catch(() => {
          this.openConfirm('Error : 관리자에게 문의하세요', false, () => {
            this.cancel();
          });
        });
    },
  },

  components: {
    SetDialog,
    SetPopup,
    AddAcount,
    RealGrid,
  },
};
</script>
<style lang="scss">
.filter {
  padding-right: 7px;
}
.filter .row {
  margin: auto;
  height: 67px;
}
.service {
  margin: auto;
  width: 100%;
  .v-input__slot {
    width: 100% !important;
  }
}

.filter .v-select__slot > div.v-select__selections {
  position: absolute;
  top: 0;
}
div.v-input__slot > fieldset {
  height: 46px !important;
}

div.v-select__slot > div.v-input__append-inner > div {
  position: absolute;
  top: 10px;
  right: 0;
}
</style>
