<!-- eslint-disable vue/no-parsing-error -->
<template>
  <v-flex xs12>
    <v-card width="450px" class="login_container">
      <div class="signupPost">
        <SetPopup ref="alertPopup" />
        <div class="pa-4">
          <h1 style="text-align: center" class="mb-2">
            <div class="wrapper" style="width: 50%; margin: auto">
              <template v-if="this.locale === 'ko'">
                <img src="../../assets/dwel_logo_ko.png" alt="" />
              </template>
              <template v-else>
                <img src="../../assets/dwel_logo_en.png" alt="" />
              </template>
            </div>
          </h1>
          <h2 style="padding-top: 10px">비밀번호 변경</h2>
        </div>
        <div class="sign-up-container-1" style="padding-left: 20px; padding-right: 20px; margin: auto">
          <div class="pa-5 border mb-3">
            <v-form ref="passwordrechange" lazy-validation>
              <div class="post-contents">
                <ul class="post__box">
                  <li class="post__list">
                    <SignUpInput placeholder="기존 비밀번호를 입력해 주세요." label="*기존 비번" type="password" append-icon="mdi-eye" v-model="param.olbPassword" :rules="[this.validSet.empty(param.olbPassword, '기존 비밀번호를 입력해주세요'), this.validSet.olbPassword]" />
                  </li>
                  <li class="post__list">
                    <SignUpInput placeholder="신규 비밀번호를 입력해 주세요." label="*신규 비번" type="password" append-icon="mdi-eye" v-model="param.password" :rules="[this.validSet.empty(param.password, '신규 비밀번호를 입력해주세요'), this.validSet.password]" />
                  </li>
                  <li class="post__list">
                    <SignUpInput placeholder="신규 비밀번호를 입력해 주세요." label="*비번 재확인" labelWidth="150" type="password" append-icon="mdi-eye" v-model="param.passwordCode" :rules="[this.validSet.empty(param.passwordCode, '신규 비밀번호를 입력해주세요'), this.validSet.passwordCode(param.password, param.passwordCode)]" />
                  </li>
                </ul>
              </div>
            </v-form>
          </div>
          <div class="wrapper mb-5">
            <div class="btn-box">
              <v-btn depressed color="primary" @click="onPasswordChange">비밀번호 변경 저장</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-flex>
</template>

<script>
import _ from 'lodash';
import { resetPassword } from 'api/member/member';
import SetPopup from '@/components/SetPopup.vue';
import SignUpInput from '@/views/member/SignUpInput';
import { mapMutations, mapState } from 'vuex';
import validSet from '@/assets/valid';
export default {
  name: 'DongwhaPasswordChange',
  data() {
    return {
      checkbox: false,
      showPwd: false,
      propsTab: 0,
      validSet,
      focus: false,
      param: {
        olbPassword: '',
        password: '',
        passwordCode: '',
      },
    };
  },
  components: {
    SignUpInput,
    SetPopup,
  },
  computed: {
    ...mapState('locale', ['message', 'locale']),
    ...mapState('member', ['accessToken']),
    pwdType() {
      if (!this.showPwd) {
        return 'Password';
      } else {
        return 'text';
      }
    },
  },
  mounted() {
    this.showPwd = false;
  },
  methods: {
    ...mapMutations('member', ['SET_TOKEN', 'SET_DEFAULT_ADDRESS', 'SET_USER_INFO']),
    ...mapMutations('modal', ['SET_DIALOG_TITLE', 'SET_DIALOG_TEXT', 'SET_HIGHT', 'SET_MAX_WIDTH', 'SET_MODAL', 'RESET_MODAL']),
    valid() {
      return this.$refs.passwordrechange.validate();
    },
    img() {
      return `../../assets/dwel_logo_${this.locale}.png`;
    },
    onPasswordChange() {
      if (this.valid()) {
        const params = {
          memberId: this.$store.state.member.userInfo.memberId,
          oldPassword: this.param.olbPassword,
          memberPw: this.param.password,
          ...this.param.post,
        };
        resetPassword(params)
          .then(res => {
            const body = res.data;
            if (!_.isEmpty(body.errorCode)) {
              alert(body.errorMessage);
              return;
            } else {
              this.reset();
              this.SET_TOKEN({});
              alert('다시 로그인을 하셔야 합니다.');
              this.$router.push({ name: 'login' });
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
    reset() {
      this.param = {
        olbPassword: '',
        password: '',
        passwordCode: '',
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#header {
  display: none;
}
.tmp {
  position: absolute;
  right: 0;
  width: 200px;
  bottom: 0;
  .v-btn {
    margin: 5px !important;
  }
}
.login .v-text-field__details {
  margin-top: 3px;
}

.v-text-field {
  margin-top: 0;
  padding-top: 0;
}

.underLogin {
  display: flex;
  justify-content: space-between;
  span {
    cursor: pointer;
  }
}

.v-input__slot {
  margin-bottom: 0px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.signupPost {
  .cardWrapperLeft {
    display: flex;
  }
  .cardWrapper {
    display: flex;
    justify-content: center;
  }
  .col {
    flex-direction: column;
  }
  .addDetail {
    width: 400px;
    margin-top: 17px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  .addDetailOther {
    width: 400px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  table {
    border: 1px solid;
    border-collapse: collapse;
    width: 60%;
    margin-top: 15px;
  }
  th,
  td {
    border: 1px solid;
  }
  .v-input--radio-group--row .v-messages.theme--light {
    display: none !important;
  }
  .addressSelect {
    display: flex;
    position: relative;
    align-items: center;
    height: 56px;
  }
}
.post__list .v-subheader {
  width: 100px !important;
}
</style>
