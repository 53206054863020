<template>
  <div>
    <!-- <h3 class="mb-4">용매조성 마스터 관리</h3> -->
    <v-form ref="menstrumm" lazy-validation>
      <div class="filter-box filter-box--row">
        <ul class="filter-list04">
          <li>
            <h4>Solvent</h4>
            <v-text-field v-model="param.solvent" outlined dense placeholder="solvent/solvent/solvent/solvent/solvent/solvent/solvent/solvent/" :rules="[this.validSet.sample(param.solvent, 8, 'key')]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>Solvent vol</h4>
            <v-text-field v-model="param.solventVol" outlined dense placeholder="00.00/00.00/00.00/00.00/00.00/00.00/00.00/00.00" :rules="[this.validSet.sample(param.solventVol, 8, 'value')]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>Salt</h4>
            <v-text-field v-model="param.salt" outlined dense placeholder="salt/salt/salt" :rules="[this.validSet.sample(param.salt, 2, 'key')]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>Salt vol</h4>
            <v-text-field v-model="param.saltVol" outlined dense placeholder="00.00/00.00/00.00" :rules="[this.validSet.sample(param.saltVol, 2, 'value')]" @keyup.enter="search"></v-text-field>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search('', 'SEARCH')">검색</v-btn>
          </v-card-actions>
        </div>
        <!-- <v-col cols="12" sm="1" style="align-self: end">
          <div class="wrapperEnd pr-2">
            <v-card-actions>
              <v-btn depressed outlined @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed color="primary" @click="search">검색</v-btn>
            </v-card-actions>
          </div>
        </v-col> -->
      </div>
      <!-- <v-row class="sch px-2 mb-2"
        ><v-col cols="12" sm="11"
          ><div class="menstrumm wrapperSpace">
            <v-col cols="12" sm="6">
              <h4>Solvent</h4>
              <v-text-field
                v-model="param.solvent"
                outlined
                dense
                placeholder="solvent/solvent/solvent/solvent/solvent/solvent/solvent/solvent/"
                :rules="[this.validSet.sample(param.solvent, 8, 'key')]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <h4>Solvent vol</h4>
              <v-text-field
                v-model="param.solventVol"
                outlined
                dense
                placeholder="00.00/00.00/00.00/00.00/00.00/00.00/00.00/00.00"
                :rules="[this.validSet.sample(param.solventVol, 8, 'value')]"
              ></v-text-field>
            </v-col>
          </div>
          <div class="menstrumm wrapperSpace">
            <v-col cols="12" sm="6">
              <h4>Salt</h4>
              <v-text-field
                v-model="param.salt"
                outlined
                dense
                placeholder="salt/salt/salt"
                :rules="[this.validSet.sample(param.salt, 2, 'key')]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <h4>Salt vol</h4>
              <v-text-field
                v-model="param.saltVol"
                outlined
                dense
                placeholder="00.00/00.00/00.00"
                :rules="[this.validSet.sample(param.saltVol, 2, 'value')]"
              ></v-text-field>
            </v-col></div></v-col
        ><v-col cols="12" sm="1" style="align-self: end">
          <div class="wrapperEnd pr-2">
            <v-card-actions>
              <v-btn depressed outlined @click="reset">초기화</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn depressed color="primary" @click="search">검색</v-btn>
            </v-card-actions>
          </div></v-col
        ></v-row
      > -->
    </v-form>
    <h3 class="mt-2 mb-2 dp--row">
      <!-- 용매조성 목록 -->
      <v-btn style="margin-left: auto" depressed class="btn-add" @click="addSample">등록</v-btn>
    </h3>
    <!-- <h3 class="mt-2 mb-2 pl-1 pr-4">
      <div class="wrapperSpace">
        용매조성 목록
        <v-btn depressed color="primary" @click="addSample">등록</v-btn>
      </div>
    </h3>
    <hr class="mb-4" />
     -->
    <RealGrid :domName="grid" ref="grid" :settings="settings" @changePage="loadData" @dbClick="dbClick" />
  </div>
</template>
<script>
import * as menstrumm from '@/assets/grid/menstrumm';
import RealGrid from '@/components/RealGrid.vue';
import { solventMasterList } from 'api/solvent/solvent';
import _ from 'lodash';
import validSet from '@/assets/valid';
export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      validSet,
      origin: [],
      param: {
        solvent: '',
        solventVol: '',
        salt: '',
        saltVol: '',
        pageSize: 100000,
      },
      grid: 'menstrumm',
      settings: { ...menstrumm, height: 590, hideCheckBar: true, noneNo: true },
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    valid() {
      return this.$refs.menstrumm.validate();
    },
    async search(v, type) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        if (type === 'REFRESH') {
          this.reset();
        }
        const res = await solventMasterList({ ...this.param, currentPage: this.currentPage });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        items.forEach(items => {
          if (items.saltVol1 == 0) items.saltVol1 = null;
          if (items.saltVol2 == 0) items.saltVol2 = null;
          if (items.saltVol3 == 0) items.saltVol3 = null;
          if (items.saltVol4 == 0) items.saltVol4 = null;
          if (items.saltVol5 == 0) items.saltVol5 = null;
          if (items.solventVol1 == 0) items.solventVol1 = null;
          if (items.solventVol2 == 0) items.solventVol2 = null;
          if (items.solventVol3 == 0) items.solventVol3 = null;
          if (items.solventVol4 == 0) items.solventVol4 = null;
          if (items.solventVol5 == 0) items.solventVol5 = null;
          if (items.solventVol6 == 0) items.solventVol6 = null;
          if (items.solventVol7 == 0) items.solventVol7 = null;
          if (items.solventVol8 == 0) items.solventVol8 = null;
          if (items.excelNo == 0) items.excelNo = null;
          if (items.ww == 0) items.ww = 'N';
        });
        this.origin = items;
        this.$refs.grid.loadData(this.origin);
        this.$refs.grid.setPage(page);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
    addSample() {
      this.$emit('menstruumAdd');
    },
    loadData(v) {
      this.search(v, 'REFRESH');
    },
    dbClick(data) {
      this.$emit('dbClick', data);
    },
    reset() {
      this.param = {
        solvent: '',
        solventVol: '',
        salt: '',
        saltVol: '',
      };
    },
  },
  mounted() {
    this.loadData();
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss">
.menstrumm {
  height: 80px;
  padding-left: 10px;
}
</style>
