import { ValueType } from 'realgrid';

export const fields = [
  {
    fieldName: 'pt_cd',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'pt_nm',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'rep_name',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'postcode',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address1',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'address2',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'phone',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'open_yn',
    dataType: ValueType.TEXT,
  },
  {
    fieldName: 'email',
    dataType: ValueType.TEXT,
  },
];

export const columns = [
  {
    name: 'pt_cd',
    fieldName: 'pt_cd',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: '고객사코드',
    editable: false,
  },
  {
    name: 'pt_nm',
    fieldName: 'pt_nm',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '고객사명',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'rep_name',
    fieldName: 'rep_name',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '담당자명',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'postcode',
    fieldName: 'postcode',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '우편번호',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'address1',
    fieldName: 'address1',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '주소',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'address2',
    fieldName: 'address2',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '상세주소',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'phone',
    fieldName: 'phone',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '대표번호',
      showTooltip: false,
    },
    editable: false,
  },
  {
    name: 'open_yn',
    fieldName: 'open_yn',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '운영여부',
      showTooltip: false,
    },
    renderer: {
      type: 'html',
      callback: (grid, cell) => {
        let open_yn = grid.getValue(cell.index.itemIndex, 'open_yn');
        return open_yn == 'Y' ? '운영' : '미운영';
      },
    },
    editable: false,
  },
  {
    name: 'email',
    fieldName: 'email',
    type: 'data',
    styles: {
      textAlignment: 'center',
    },
    header: {
      text: '이메일주소',
      showTooltip: false,
    },
    editable: false,
  },
];
export const rows = [
  {
    no: 1,
    pt_cd: 'SMP00001',
    pt_nm: '샘플고객사명',
    rep_name: '샘플고객명',
    postcode: '12345',
    address1: '인천시 미추홀구 인하로',
    address2: '255',
    open_yn: 'Y',
    phone: '010-1234-5678',
    email: 'test@test.com',
  },
];
