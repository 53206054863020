<template>
  <div class="flexCol" style="height: auto">
    <v-form ref="idFind" lazy-validation>
      <v-layout row style="margin-top: 0; height: 73px; border-bottom: 1px solid #ddd">
        <v-col cols="12" sm="3">
          <v-subheader style="width: 145px">*이름</v-subheader>
        </v-col>
        <v-col cols="12" sm="9" class="pr-4">
          <v-text-field placeholder="이름을 입력 해주세요." v-model="memberName" :rules="[this.validSet.empty(memberName, '이름을 입력 해주세요'), this.validSet.name]" outlined dense></v-text-field>
        </v-col>
      </v-layout>
      <v-layout style="margin-top: 0">
        <v-row style="height: 73px; margin-top: 15px; border-bottom: 1px solid #ddd">
          <v-col cols="12" sm="3" class="pr-4">
            <v-subheader style="width: 145px">*휴대폰 번호</v-subheader>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined dense placeholder="+82" v-model="areacode" :rules="[this.validSet.empty(areacode, '국가번호를 입력해주세요')]" v-mask="'+###'"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" style="padding-left: 0px" class="pr-4">
            <v-text-field placeholder="000-0000-0000" v-model="phone" outlined dense :rules="[this.validSet.empty(phone, '핸드폰 번호를 입력해주세요')]" v-mask="'###-####-####'"></v-text-field>
          </v-col>
        </v-row>
      </v-layout>
    </v-form>
    <div class="wrapper">
      <div class="btn-box mt-5">
        <button class="btn-reset" @click="cancle">취소</button>
        <button class="btn-search" @click="onApprove">확인</button>
      </div>
      <!-- <v-card-actions>
        <v-btn depressed @click="cancle">취소</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn depressed color="primary" @click="onApprove">확인</v-btn>
      </v-card-actions> -->
    </div>
  </div>
</template>
<script>
import validSet from '@/assets/valid';
export default {
  name: 'FindIdForm',
  data() {
    return {
      validSet,
      //id Param
      memberName: '',
      phone: '',
      areacode: '',
      numbers: [1, 2],
    };
  },
  methods: {
    valid() {
      return this.$refs.idFind.validate();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    onApprove() {
      if (this.valid()) {
        const param = {
          memberName: this.memberName,
          phone: this.phone,
          areacode: this.areacode.replace('+', ''),
        };
        this.$emit('onApprove', param, 'id');
      }
    },
    cancle() {
      this.$router.push({ name: 'main' });
    },
    reset() {
      this.memberName = '';
      this.phone = '';
      this.areacode = '';
    },
  },
};
</script>
