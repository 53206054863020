<template>
  <div class="address signupPost1 partner-search-vue">
    <SetPopup ref="postConfirm" />
    <h3 class="mb-3" style="text-align: left; text-align: left; border-bottom: 1px solid #ddd; margin: -20px -20px 20px -20px !important; padding: 15px">고객사 조회</h3>
    <v-form ref="partnerSearch" lazy-validation>
      <div class="post-contents" style="background-color: rgb(247 247 247); display: flex; justify-content: space-around; align-items: center; border: 1px solid #d0d0d0; padding: 5px 10px; margin-bottom: 20px">
        <!-- <ul class="post__box" style="border: 1px solid #d0d0d0; padding: 10px; background-color: #efefef; border-radius: 5px"> -->
        <!-- <li class="post__list" style="padding: 0px; border: unset"> -->
        <SignupInput placeholder="" label="고객사명" type="text" v-model="param.pt_nm" @enter="partnerSearchSelfRequest" style="grid-template-columns: 70px 1fr; width: 40%" />
        <SignupInput placeholder="" label="담당자명" type="text" v-model="param.rep_name" @enter="partnerSearchSelfRequest" style="grid-template-columns: 70px 1fr; width: 40%" />
        <v-btn elevation="0" class="btn-confim" @click="partnerSearchSelfRequest" style="margin-left: 50px">검색</v-btn>
        <!-- </li>
        </ul> -->
      </div>
    </v-form>
    <!-- <h3 style="padding: 10px !important; border: 1px solid #d0d0d0 !important; border-radius: 5px !important; margin-top: 10px !important; margin-bottom: 10px !important">선택한 고객사 : {{ selectPartnerTemp }}</h3> -->
    <RealGrid :domName="grid" ref="grid" @dbClick="selectionRow" @onClickedRow="onClickedRow" :settings="settings" />
    <div class="wrapper">
      <div class="btn-box mt-2" style="display: flex; justify-content: space-between; width: 100%; margin-top: 30px !important; margin-bottom: 0px !important">
        <v-btn class="btn-cancle" elevation="0" @click="closeModal" style="width: 49%; border-radius: 5px !important; font-weight: bold; color: #545454 !important">취소</v-btn>
        <v-btn class="btn-confim" elevation="0" @click="onApprove" style="width: 49%; border-radius: 5px !important; font-weight: bold">확인</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import validSet from '@/assets/valid';
import SignupInput from '@/views/member/SignUpInput.vue';
import SetPopup from '@/components/SetPopup.vue';
import RealGrid from '@/components/RealGrid.vue';
import { mapMutations } from 'vuex';
import { partnerMasterList } from 'api/partner/partner';
import { columns, fields, rows } from '@/assets/grid/partnerSearch';
import _ from 'lodash';

export default {
  props: ['searchRequest'],
  name: 'partnerSearch',
  data() {
    return {
      selectPartnerTemp: '',
      selectPartner: {
        pt_cd: '',
        pt_nm: '',
        pick_name: '',
        phone: '',
        address1: '',
        address2: '',
        postcode: '',
      },
      validSet,
      param: {
        pt_cd: '',
        pt_nm: '',
        phone: '',
        rep_name: '',
        postcode: '',
        address1: '',
        address2: '',
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_LX_SIZE || 100000,
      },
      grid: 'partnerSearchGrid',
      settings: {
        columns: _.map(
          _.filter(_.cloneDeep(columns), v => v.fieldName !== 'lotNo'),
          function (v) {
            return v;
          },
        ),
        fields,
        rows,
        height: 250,
        radio: false, // true -> radio, false -> checkbox
        firstClick: true, // 자동으로 첫 번째 로우 클릭 이벤트를 위한 트리거 발생 여부.
        fitStyle: 'evenFill',
        hideCheckBar: true,
        noneNo: true,
        blankGrid: true,
        oneLineGrid: true,
      },
    };
  },
  computed: {},
  methods: {
    ...mapMutations('popup', ['SET_POPUP']),
    valid() {
      return this.$refs.partnerSearch.validate();
    },
    partnerSearchSelfRequest() {
      this.partnerSearchRequest({
        ...this.param,
        search_yn: 'Y',
        currentPage: 1,
      });
    },
    partnerSearchRequest(searchRequest = this.searchRequest) {
      partnerMasterList({
        ...searchRequest,
        search_yn: 'Y',
        open_yn: 'Y',
        currentPage: 1,
      })
        .then(res => {
          const response = res.data;
          const items = response.data.items;
          const page = response.data.params;
          if (items && items.length == 1) {
            this.selectionRow(items[0]);
            return false;
          }
          this.$refs.grid.loadData(items);
          this.$refs.grid.setPage(page);
        })
        .catch(err => {
          console.log('Error : ', err);
        })
        .finally();
    },
    selectionRow(v) {
      // this.selectPartnerTemp = `${v.pt_cd}, ${v.pt_nm}, ${v.rep_name}`;
      // this.selectPartner.pt_cd = v.pt_cd;
      // this.selectPartner.pt_nm = v.pt_nm;
      // this.selectPartner.pick_name = v.rep_name;
      // this.selectPartner.phone = v.phone;
      // this.selectPartner.address1 = v.address1;
      // this.selectPartner.address2 = v.address2;
      // this.selectPartner.postcode = v.postcode;
      this.updateSelectPartner(v);
      this.$emit('onApprove', {
        ...this.selectPartner,
      });
      this.closeModal();
    },
    onClickedRow(v) {
      this.updateSelectPartner(v);
    },
    updateSelectPartner(v) {
      this.selectPartner.pt_cd = v.pt_cd;
      this.selectPartner.pt_nm = v.pt_nm;
      this.selectPartner.pick_name = v.rep_name;
      this.selectPartner.phone = v.phone;
      this.selectPartner.address1 = v.address1;
      this.selectPartner.address2 = v.address2;
      this.selectPartner.postcode = v.postcode;
    },
    onApprove() {
      if (this.selectPartner.pt_cd != '') {
        // this.openPopup('선택한 고객사를 사용하시겠습니까?', true, () => {
        //   this.$emit('onApprove', {
        //     ...this.selectPartner,
        //   });
        //   this.closeModal();
        // });
        this.$emit('onApprove', {
          ...this.selectPartner,
        });
        this.closeModal();
      } else {
        this.openPopup('선택된 고객사가 없습니다.', false, () => {});
      }
    },
    openPopup(text, closable, cb) {
      this.SET_POPUP({
        title: '알림',
        // height: 150,
        width: 300,
        text,
        closable: closable,
      });
      this.$refs.postConfirm.openPopup(cb);
    },
    reset() {
      this.param = {
        pt_cd: '',
        pt_nm: '',
        rep_name: '',
      };
    },
    closeModal() {
      this.$emit('closeModalPartner');
    },
  },
  components: {
    SignupInput,
    SetPopup,
    RealGrid,
  },
  mounted() {
    this.partnerSearchRequest();
  },
};
</script>
<style lang="scss">
.signupPost {
  .cardWrapperLeft {
    display: flex;
  }
  .cardWrapper {
    display: flex;
    justify-content: center;
  }
  .col {
    flex-direction: column;
  }
  .addDetail {
    width: 400px;
    margin-top: 17px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  .addDetailOther {
    width: 400px;
    margin-left: 130px;
    height: 33px;
    margin-bottom: 26px !important;
  }
  table {
    border: 1px solid;
    border-collapse: collapse;
    width: 60%;
    margin-top: 15px;
  }
  th,
  td {
    border: 1px solid;
  }
  .v-input--radio-group--row .v-messages.theme--light {
    display: none !important;
  }
  .addressSelect {
    display: flex;
    position: relative;
    align-items: center;
    height: 56px;
  }
  .select-company-h {
    padding: 10px !important;
    border: 1px solid #d0d0d0 !important;
    border-radius: 5px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.partner-search-vue {
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset,
  .v-text-field--outlined.v-input--is-focused fieldset,
  .v-text-field--outlined.v-input--has-state fieldset {
    background-color: #fff !important;
    border-radius: 0px !important;
    border: 1px solid #ccc !important;
  }
}
</style>
