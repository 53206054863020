import http from '../http';
import { version } from '../version';
const prefix = `${version}/member`;

export async function login(param) {
  return http.post(prefix + '/login', param);
}
export async function refreshToken(param) {
  return http.post(prefix + '/refreshToken', param);
}

export async function searchUserId(param) {
  return http.post(prefix + '/searchUserId', param);
}

export async function memberList(param) {
  return http.post(prefix + '/memberlist', param);
}

export async function sendAuthNum(param) {
  return http.post(prefix + '/sendAuthNum', param);
}

export async function authNumCheck(param) {
  return http.post(prefix + '/authNumCheck', param);
}

export async function memberJoin(param) {
  return http.post(prefix + '/memberJoin', param);
}

export async function sendTempPass(param) {
  return http.post(prefix + '/sendTempPass', param);
}

export async function getRoleList() {
  return http.post(prefix + '/getRoleList');
}
export async function resetPass(param) {
  return http.post(prefix + '/resetPass?memberId=' + param);
}

export async function resetPassword(param) {
  return http.post(prefix + '/resetPassword', param);
}

export async function updateRole(param) {
  return http.post(prefix + `/updateRole?roleName=${param.roleName}&roles=${param.roles}`);
}

export async function newPass(param) {
  return http.post(prefix + `/newPass`, param);
}

export async function loginLog(param) {
  return http.post(prefix + `/loginLog`, param);
}

export async function insertRole(param) {
  return http.post(prefix + `/insertRole?roleName=${param.roleName}&roles=${param.roles}`);
}

export async function deleteRole(roles) {
  return http.post(prefix + `/deleteRole?roles=${roles}`);
}

export async function userInfoUpdate(param) {
  return http.post(prefix + `/userInfoUpdate`, param);
}

export async function userInfo() {
  return http.post(prefix + `/userInfo`);
}

export async function requestSampleAddressUpdate(param) {
  return http.post(prefix + `/requestSampleAddressUpdate`, param);
}

export async function allChangePassword() {
  return http.post('/api/v1/batch/allChangePassword');
}
