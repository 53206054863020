<template>
  <div>
    <RealGrid :domName="grid" ref="grid" :settings="settings" />
    <div class="wrapper">
      <div class="btn-box">
        <v-btn depressed class="btn-reset" @click="reset">초기화</v-btn>
        <v-btn depressed class="btn-save" @click="search">저장</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { columns, fields, rows, height } from '@/assets/grid/sampleRequest';
import RealGrid from '@/components/RealGrid.vue';

export default {
  data() {
    return {
      grid: 'qulityTestInput',
      settings: {
        columns,
        fields,
        rows,
        height,
      },
    };
  },
  methods: {
    newSample() {
      this.$emit('newSample');
    },
    reset() {},
    search() {},
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss"></style>
