<template>
  <div>
    <!-- <h3 class="mb-2">결과 입력</h3> -->
    <v-form ref="form" lazy-validation>
      <div class="filter-box filter-box--row">
        <ul class="filter-list">
          <li>
            <h4>Lot No</h4>
            <v-text-field outlined dense placeholder="" v-model="param.lotNo" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>Code Grade</h4>
            <v-text-field outlined dense placeholder="" v-model="param.code" :rules="[this.validSet.commonCodeHipen]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청업체명</h4>
            <v-text-field outlined dense placeholder="" v-model="param.request_company" :rules="[this.validSet.company]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청자</h4>
            <v-text-field outlined dense placeholder="" v-model="param.request_name" :rules="[this.validSet.name]" @keyup.enter="search"></v-text-field>
          </li>
          <li>
            <h4>요청일</h4>
            <div class="dp--row">
              <v-menu v-model="picker1" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="param.from_created_at" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="menu = false" v-model="param.from_created_at"></v-date-picker>
              </v-menu>
              <i class="ml-1 mr-1">~</i>
              <v-menu v-model="picker2" :close-on-content-click="true" :nudge-right="0" :nudge-top="20" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="param.to_created_at" placeholder="" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title @input="menu = false" v-model="param.to_created_at"></v-date-picker>
              </v-menu>
            </div>
          </li>
          <li>
            <h4>결과입력</h4>
            <!-- <v-select
              :items="code.P"
              v-model="param.price_type"
              placeholder="선택"
              outlined
              id="work"
              style="width: 100%"
            ></v-select> -->
            <v-select v-model="param.resultYn" :items="['전체', 'Y', 'N']" outlined id="work" style="width: 100%"></v-select>
          </li>
        </ul>
        <div class="btn-box">
          <v-card-actions>
            <v-btn depressed outlined class="btn-reset" @click="reset">초기화</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn depressed class="btn-search" @click="search">검색</v-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- <h3 class="mt-4 mb-2 pl-1 pr-1">목록</h3> -->
      <!-- <div class="wrapperSpace mt-4 sch">
      <div style="width: 100%" class="px-4 pt-6 pb-2">
        <v-row class="pl-2">
          <v-col cols="12" sm="3">
            <h4>Code Grade</h4>
            <v-text-field
              outlined
              dense
              placeholder="Code Grade를 입력해주세요"
              v-model="param.code"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <h4>Lot No</h4>
            <v-text-field
              outlined
              dense
              placeholder="Lot No를 입력해주세요"
              v-model="param.lotNo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="wrapperSpace">
            <div style="width: 50%">
              <h4>요청자</h4>
              <v-text-field
                outlined
                dense
                placeholder="요청자를 입력해주세요"
                v-model="param.request_name"
              ></v-text-field>
            </div>
            <div class="wrapper">
              <v-card-actions>
                <v-btn depressed outlined @click="reset">초기화</v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn depressed color="primary" @click="search">검색</v-btn>
              </v-card-actions>
            </div>
          </v-col>
        </v-row>
      </div>
    </div> -->

      <!-- <div class="wrapperSpace">
      <div>
        <h4 class="mt-4 mb-2">목록</h4>
      </div>
    </div> -->
      <RealGrid :domName="grid" ref="grid" :settings="settings" @dbClick="dbClick" @changePage="search" />
    </v-form>
  </div>
</template>
<script>
import { columns, fields, rows } from '@/assets/grid/resultInput';
import RealGrid from '@/components/RealGrid.vue';
import _ from 'lodash';
import { searchproduce } from 'api/sample/sample';
import validSet from '@/assets/valid';

export default {
  data() {
    return {
      isFetching: false,
      isDataLoaded: false,
      grid: 'resultInput',
      picker1: '',
      picker2: '',
      validSet,
      settings: {
        columns,
        fields,
        rows,
        height: 650,
        hideCheckBar: true,
      },
      param: {
        pageSize: 100000,
        code: '',
        lotNo: '',
        request_company: '',
        request_name: '',
        resultYn: '전체',
        reg_dt: '',
        from_created_at: '',
        to_created_at: '',
      },
      items: [],
    };
  },
  watch: {
    param: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    dbClick(data) {
      this.$emit('dbClick', _.filter(this.items, v => v.lotNo === data.lotNo)[0]);
    },
    reset() {
      this.param = {
        pageSize: 100000,
        code: '',
        lotNo: '',
        request_company: '',
        request_name: '',
        resultYn: '전체',
        created_at: '',
        from_created_at: '',
        to_created_at: '',
      };
    },
    loadData(v) {
      this.search(v);
    },
    // search(v) {
    //   if (_.isNumber(v)) {
    //     this.currentPage = v;
    //   }
    //   searchproduce({
    //     currentPage: this.currentPage,
    //     ...this.param,
    //   }).then((res) => {
    //     const response = res.data;
    //     const items = response.data.items;
    //     const page = response.data.params;
    //     let filteredItem = items;
    //     if (this.param.from_created_at) {
    //       const from_date = new Date(this.param.from_created_at);
    //       filteredItem = filteredItem.filter(
    //         (item) => new Date(item.created_at) >= from_date
    //       );
    //     }
    //     if (this.param.to_created_at) {
    //       const to_date = new Date(this.param.to_created_at);
    //       filteredItem = filteredItem.filter(
    //         (item) => new Date(item.created_at) >= to_date
    //       );
    //     }
    //     // if (this.param.resultYn) {
    //     //   filterdItem = filterdItem.filter(
    //     //     (item) => item.resultYn
    //     //   );
    //     // }

    //     this.$refs.grid.loadData(filteredItem, ["created_at"]);
    //     this.$refs.grid.setPage(page);
    //     if (items.length === 0) {
    //       this.settings.errorMessage = "진행중인 사항이 없습니다";
    //     }
    //   });
    // },

    valid() {
      return this.$refs.form.validate();
    },

    async search(v) {
      if (!this.valid()) return;
      if (this.isFetching) return;
      this.isFetching = true;

      try {
        if (_.isNumber(v)) {
          this.currentPage = v;
        }
        const res = await searchproduce({
          currentPage: this.currentPage,
          viewName: 'resultInput',
          ...this.param,
        });
        this.isDataLoaded = true;

        const response = res.data;
        const items = response.data.items;
        const page = response.data.params;
        this.items = items;
        let filterditems = items;
        if (this.param.from_created_at) {
          const from_date = new Date(this.param.from_created_at);
          filterditems = filterditems.filter(item => new Date(item.reg_dt) >= from_date);
        }
        if (this.param.to_created_at) {
          const to_date = new Date(this.param.to_created_at);
          filterditems = filterditems.filter(item => new Date(item.reg_dt) <= to_date);
        }
        if (this.param.resultYn === 'Y') {
          filterditems = filterditems.filter(item => item.resultYn === 'Y');
        }
        if (this.param.resultYn === 'N') {
          filterditems = filterditems.filter(item => item.resultYn === 'N');
        }
        this.$refs.grid.loadData(filterditems, ['reg_dt']);
        this.$refs.grid.setPage(page);
        if (items.length === 0) {
          this.settings.errorMessage = '진행중인 사항이 없습니다';
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isFetching = false;
      }
    },
  },
  mounted() {
    this.loadData();
  },
  components: {
    RealGrid,
  },
};
</script>
<style lang="scss"></style>
