<template>
  <div class="signupInput">
    <v-subheader class="" v-if="this.label">{{ this.label }}</v-subheader>
    <div class="wrapper">
      <v-text-field :placeholder="placeholder" :value="value" @input="input" outlined dense :class="sideBtn ? 'signInputWithBtn' : 'signInput'" :type="inputType" :append-icon="appendIcon" @click:append="togglePwdShow" autocomplete="off" :disabled="disabled" :filled="disabled" :required="required" :rules="rules" :readonly="!focus" @focus="focus = true" @blur="focus = false" :maxlength="maxlength ? maxlength : 999" @keyup.enter="onEnter"></v-text-field>
      <v-btn style="margin-left: 3px; width: 117px; height: 36px" elevation="0" v-if="sideBtn" dense depressedd class="btn-save" @click="btnClick">{{ btnText }}</v-btn>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['value', 'placeholder', 'label', 'type', 'appendIcon', 'width', 'height', 'sideBtn', 'btnText', 'click', 'disabled', 'required', 'rules', 'maxlength', 'labelWidth'],
  name: 'SignUpInput',
  data() {
    return {
      focus: false,
      showPwd: false,
      inputType: this.type,
      // inputWidth: this.width ? this.width : 500,
      btnClick: _.isFunction(this.click) ? this.click : () => {},
    };
  },
  watch: {
    showPwd: function (v) {
      if (v) {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    },
  },
  methods: {
    togglePwdShow() {
      this.showPwd = !this.showPwd;
    },
    input(v) {
      this.$emit('input', v);
    },
    onEnter() {
      this.$emit('enter');
    },
  },
};
</script>
<style>
.signInput .v-text-field__details {
  position: relative;
  top: 65px;
}
.signInputWithBtn .v-text-field__details {
  position: relative;
  top: 18px;
}
</style>
