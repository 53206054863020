import axios from 'axios';
import router from '@/router';
import config from './config';
import store from '@/store/index';
import { refreshToken } from 'api/member/member';
import _ from 'lodash';
const instance = axios.create(config);
instance.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
};

// 액션이 있을때마다 토큰 다시 생성
let isRefreshing = false; // 토큰 새로고침 기준

instance.interceptors.request.use(async function (config) {
  // 요청 전달되기 전 작업 처리
  if (!store.state.member.accessToken) {
    return config;
  }

  const key = _.keys(config.data);
  _.each(key, v => {
    if (config.data[v] === '전체' || config.data[v] === 'the entire') {
      delete config.data[v];
    }
  });
  config.headers.Authorization = store.state.member.accessToken;

  if (config.url.endsWith('/refreshToken') || config.url.endsWith('/addressbookDefault') || config.url.endsWith('/userInfo')) {
    return config;
  }

  const refreshTokenParam = store.state.member.refreshToken;
  if (!isRefreshing) {
    isRefreshing = true;

    if (refreshTokenParam != undefined) {
      const param = { memberId: store.state.member.userInfo.memberId };
      refreshToken(param, refreshTokenParam)
        .then(res => {
          const resData = res.data.data;
          isRefreshing = false;
          // resData.refreshToken = refreshTokenParam;
          store.commit('member/SET_TOKEN', resData);
          config.headers.Authorization = store.state.member.accessToken;
        })
        .catch(() => {
          isRefreshing = false;
        });
    } else {
      isRefreshing = false;
    }
  }

  // 토큰 만료체크
  const expiresIn = store.state.member.tokenExpireTime;
  const today = new Date();

  const year = today.getFullYear();
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  const day = ('0' + today.getDate()).slice(-2);

  const dateString = year + '-' + month + '-' + day;

  const hours = ('0' + today.getHours()).slice(-2);
  const minutes = ('0' + today.getMinutes()).slice(-2);
  const seconds = ('0' + today.getSeconds()).slice(-2);

  const timeString = hours + ':' + minutes + ':' + seconds;
  const DateStrng = dateString + ' ' + timeString;
  if (DateStrng > expiresIn && expiresIn !== undefined) {
    store.commit('member/SET_TOKEN', {});
    router.push({ name: 'login' });
  }

  // store.state.loading.loading = true;
  store.commit('loadingbar/START_LOADING');
  return config;
});
instance.interceptors.response.use(
  function (response) {
    // store.state.loading.loading = false;
    store.commit('loadingbar/FINISH_LOADING'); // 요청 완료 시 카운터 감소
    return response;
  },
  function (err) {
    // store.state.loading.loading = false;
    store.commit('loadingbar/FINISH_LOADING'); // 에러 시 카운터 감소
    return Promise.reject(err);
  },
);
export default instance;
